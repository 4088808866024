import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Feedback, { FeedbackType } from "../../components/core/feedback";
import Panel from "../../components/core/panel";
import { fetchCapacityGrid, fetchCapacityGridList, saveCapacityGrid } from "../../services/capacity-grid-service";
import { CapacityGridSearchResultType, CapacityGridType, createEmptyCapacityGrid } from "../../interfaces/system/capacityGridType";
import CapacityGridList from "../../components/admin/capacity-grids/capacity-grid-builder/capacity-grid-list";
import CapacityGridEdit from "../../components/admin/capacity-grids/capacity-grid-builder/capacity-grid-editor";
import useForm from "../../hooks/useForm";
import { Grid } from "@mui/material";
import React from "react";
import { AppToastContext } from "../../extensions/context/appHook";
import { updatePageTitle } from "../../services/utils";
import { clearDomainEntities } from "../../services/domainEntitiesService";

interface CapacityGridBuilderProps {
    mode?: 'list' | 'capacity-grid-builder'
}
export default function CapacityGridBuilderContainer(props: CapacityGridBuilderProps) {

    // component vars
    const itemsPerPage: number = 25;
    const defaultTitle: string = 'Capacity Grid List';
    const toastHandler = React.useContext(AppToastContext)
    let { id } = useParams();
    // state vars
    const [mode, setMode] = useState(props.mode)
    const [title, setTitle] = useState(defaultTitle)
    const [resultList, setResultList] = useState<CapacityGridSearchResultType[]>()
    const [selectedRecordId, setSelectedRecordId] = useState<number>(parseInt(id ?? '0'))
    const [selectedRecord, setSelectedRecord] = useState<CapacityGridType>()
    const { feedbackStatus, setFeedbackStatus } = useForm({ initialObjectValues: {} })
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const navigate = useNavigate();

    // hooks
    useEffect(() => {
        //console.clear();
        init()
    }, [mode])

    useEffect(() => {
        //console.clear();
        updatePageTitle(title)
    }, [title])

    useEffect(() => {
        if (selectedRecordId && selectedRecordId > 0) {
            presentEditForm()
        }

    }, [selectedRecordId])

    // presentation handlers
    const init = async () => {
        if (mode !== 'list') return;
        const userIsBasic = false
        if (userIsBasic) {
            setFeedbackStatus(FeedbackType.NoAccess)
        }
        presentList();
    }

    const presentList = async () => {

        setFeedbackStatus(FeedbackType.Loading)
        setTitle(defaultTitle)
        const response = await fetchCapacityGridList();

        setFeedbackStatus(FeedbackType.hide)
        if (response && response.success) {
            setResultList(response.result)
            return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
    }
    const presentEditForm = async () => {
        
        setFeedbackStatus(FeedbackType.Loading)
        setMode('capacity-grid-builder')

        if (selectedRecordId <= 0) {
            setTitle('Create New Capacity Grid')
            setSelectedRecord(createEmptyCapacityGrid())
            setFeedbackStatus(FeedbackType.hide)
            return
        }

        const response = await fetchCapacityGrid(selectedRecordId, false);
        if (response && response.success && response.result) {
            setTitle(`Edit Capacity Grid - ${response.result.name}`)
            setSelectedRecord(response.result)
            setFeedbackStatus(FeedbackType.hide)
            return
        }

        toastHandler.errorMessage(response.error)
        setMode('list')
    }

    // event handlers
    const handleNewRecord = () => {
        setSelectedRecordId(0);
        presentEditForm();
    }

    const handleRecordSelected = async (id: number) => {
        setSelectedRecordId(id)
        navigate(`/admin/capacity-grid/${id}`);
    }

    const handleEditFormSave = async (grid: CapacityGridType, valid: boolean) => {
        setSelectedRecord({ ...grid })
        setErrorMessage('')
        if (!valid) {
            setFeedbackStatus(FeedbackType.MandatoryFields)
            return
        }

        setFeedbackStatus(FeedbackType.Loading)

        const saveResponse = await saveCapacityGrid(grid)
        if (saveResponse.success) {
            setFeedbackStatus(FeedbackType.hide)
            toastHandler.successMessage(`Successfuly saved form - '${grid.name}'`);
            // reset form so updated values are restored
            clearDomainEntities();
            handleCancelEditRecord();
            return
        }

        setErrorMessage(saveResponse.error || 'Unspecified Error')
        setFeedbackStatus(FeedbackType.Error)
    }

    const handleCancelEditRecord = async () => {
        setSelectedRecordId(0);
        setSelectedRecord(undefined);
        setMode('list');
        navigate(`/admin/capacity-grid`);
    }

    // dynamic components
    const CapacityGridListElem = () => {
        if (mode !== 'list') return <></>
        return <CapacityGridList onNew={handleNewRecord} onSelection={handleRecordSelected} itemsPerPage={itemsPerPage} resultList={resultList}></CapacityGridList>
    }
    const CapacityGridEditElem = () => {
        if (mode !== 'capacity-grid-builder') return <></>
        //return <h1>Need to load form here!!</h1>
        return <CapacityGridEdit gridToEdit={selectedRecord} onCancel={handleCancelEditRecord} onSave={handleEditFormSave}></CapacityGridEdit>
    }


    return <>
        <p>&nbsp;</p>
        <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} lg={9}>
                <Panel title={title}>
                    <Feedback type={feedbackStatus} message={errorMessage}></Feedback>
                    <CapacityGridListElem></CapacityGridListElem>
                    <CapacityGridEditElem></CapacityGridEditElem>
                </Panel>
            </Grid>
        </Grid>
    </>
}