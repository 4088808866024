import { createNewDomainEntity } from "../interfaces/domain/iDomainEntity";
import { SystemMessageDeliveryTypeEnum, SystemMessageType } from "../interfaces/system/systemMessageType";
import { HttpGet, HttpPost, HttpResponse } from "./http";
import { getLocalSessionItem, setSessionStorageItem } from "./storageService";

export const createEmptySystemMessage = (): SystemMessageType => {
    var delType = createNewDomainEntity();
    delType.id = SystemMessageDeliveryTypeEnum.Banner
    return {
        id: 0,
        deliveryTypeId: SystemMessageDeliveryTypeEnum.Banner,
        createdByUserId: 0,
        deleted: false,
        subject: "",
        messageContent: "",
        dateProcessed: undefined,
        subscribers: [],
        deliveryType: delType
    };
}

export const fetchSystemMessageList = async (useCache: boolean = true): Promise<HttpResponse<SystemMessageType[]>> => {

    const cacheKey = `systemmessage-list`

    if (useCache) {
        const cachedValue = getLocalSessionItem(cacheKey) as SystemMessageType[]
        if (cachedValue) return { success: true, result: cachedValue }
    }

    const response = await HttpGet<SystemMessageType[]>(
        `systemmessage/list`,
    )

    if (response.success) {
        setSessionStorageItem(cacheKey, response.result)
    }

    return response
}
export const fetchSystemMessage = async (id: number): Promise<HttpResponse<SystemMessageType>> => {
    const response = await HttpGet<SystemMessageType>(
        `systemmessage/${id}`
    )

    return response
}
//SaveSystemMessage
export const saveSystemMessage = async (data: SystemMessageType): Promise<HttpResponse<SystemMessageType>> => {
    let postData = { ...data }
    //if ((postData.messageContent?.length ?? 0) > 1024) {
    //    postData.messageContent = postData.messageContent.substring(0, 1024);
    //}

    const response = await HttpPost<SystemMessageType>(
        `systemmessage`, postData
    )

    return response
}