import moment from "moment";


export const formatDateTime = (date?: Date) => {
    if (!date) return ''

    return moment.utc(date).local().format('DD/MM/YY HH:mm');
}
export const formatTime = (date?: Date) => {
    if(!date) return ''
    return date?.toString().substring(11,16);
}
export const formatDateTimeWithDay =   (date?: Date)  => {
    if(!date) return ''
    
    return moment.utc(date).local().format('ddd DD/MM/YY HH:mm');
}

export const formatStringToDateTime = (date?: string) => {
    if (!date) return new Date()

    return moment.utc(date).local()
}
export const formatStringToDateTimeLocal = (date?: string) => {
    return moment.utc(date).local()
}
export const formatDateTime__OLD = (date?: Date) => {
    if (!date) return ''
    const dateString = date.toLocaleString()
    return `${dateString.substring(8, 10)}/${dateString.substring(5, 7)}/${dateString.substring(2, 4)} ${dateString.substring(11, 16)}`;
    //return new Intl.DateTimeFormat('en-GB', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(d);
}
export const formatDate = (date: Date | null | undefined) => {
    if (!date && date !== undefined) {
        return ''
    }
    let convertedDate = Date.parse(date && date !== undefined ? date.toString() : '')
    //sort out BST
    const dateOffset = new Date(convertedDate)
    dateOffset.setTime(dateOffset.getTime() + Math.abs(dateOffset.getTimezoneOffset()) * 60 * 1000)
    convertedDate = dateOffset.getTime()


    if (isNaN(convertedDate)) {
        return date ? date.toString() || '' : ''
    }

    if (convertedDate === -62135596725000)
        return ''

    const dateString = new Date(convertedDate).toISOString()


    return `${dateString.substring(8, 10)}/${dateString.substring(5, 7)}/${dateString.substring(0, 4)}`;
}
export const formatDateForApiUrl = (date: Date):string => {
    return moment.utc(date).local().format('YYYY-MM-DD+HH:mm');
}

export const resetDateToStartOfDay = (date: Date): string => {
    return moment.utc(date).local().format('YYYY-MM-DD+00:00');
    
}

export const resetDateToEndOfDay = (date: Date): string => {
    return moment.utc(date).local().format('YYYY-MM-DD+23:59:59');
}
export const isDate = (date?: string) => {
    if (!date) return false

    return date.split('/').length>1 
}
// export const today = () : Date => {
//     return new Date().toISOString().slice(0, 19).replace('T', ' ');
// }
export const formatCurrency = (amount: number) => {
    if (isNaN(amount)) {
        return '£0.00'
    }
    if (typeof (amount) === 'string') {
        return `£${parseFloat(amount).toFixed(2)}`.toString()
    }
    return `£${amount.toFixed(2)}`.toString()
}
export const formatDateFromJson = (dateString: any) => {
    if (!dateString)
        return ''
    let convertedDate = Date.parse(dateString ? dateString : '');

    if (isNaN(convertedDate)) {
        return ''
    }

    const dateOffset = new Date(convertedDate)
    dateOffset.setTime(dateOffset.getTime() + Math.abs(dateOffset.getTimezoneOffset()) * 60 * 1000)
    convertedDate = dateOffset.getTime()


    dateString = new Date(convertedDate).toISOString()

    //return '1980-08-01'
    return `${dateString.substring(0, 4)}-${dateString.substring(5, 7)}-${dateString.substring(8, 10)}`;
}
export const formatDateFromJsonToDate = (dateString: any) => {
    if (!dateString)
        return null
    let convertedDate = Date.parse(dateString ? dateString : '');

    if (isNaN(convertedDate)) {
        return null
    }

    const dateOffset = new Date(convertedDate)
    dateOffset.setTime(dateOffset.getTime() + Math.abs(dateOffset.getTimezoneOffset()) * 60 * 1000)
    convertedDate = dateOffset.getTime()


    //return '1980-08-01'
    return new Date(convertedDate)
}
export const formatDateTimeFromJson = (dateString: any) => {
    if (!dateString)
        return ''
    let convertedDate = Date.parse(dateString ? dateString : '');

    if (isNaN(convertedDate)) {
        return ''
    }

    const dateOffset = new Date(convertedDate)
    dateOffset.setTime(dateOffset.getTime() + Math.abs(dateOffset.getTimezoneOffset()) * 60 * 1000)
    convertedDate = dateOffset.getTime()


    dateString = new Date(convertedDate).toISOString()

    //return '1980-08-01'
    return `${dateString.substring(0, 4)}-${dateString.substring(5, 7)}-${dateString.substring(8, 10)} ${dateString.substring(11, 13)}:${dateString.substring(14, 16)}`;
}
export const formatMinutesToHHMM = (mins: number) => {
    var mins_num = mins; // don't forget the second param
    var hours = Math.floor(mins_num / 60);
    var minutes = Math.floor((mins_num - ((hours * 3600)) / 60));
    // var seconds = Math.floor((mins_num * 60) - (hours * 3600) - (minutes * 60));

    let hoursString = hours.toString()
    let minsString = minutes.toString()
    // Appends 0 when unit is less than 10
    // if (hours   < 10) {hoursString   = "0"+hoursString;}
    if (minutes < 10) { minsString = "0" + minsString; }
    // if (seconds < 10) {seconds = "0"+seconds;}
    return `${hoursString}h ${minsString}m`
}