import { ServiceType } from "./serviceType"

export interface AreaHierarchyType  {
   areas: AreaHierarchyChild[]
   totalAreas?:number
   totalServices?:number
   children?:AreaHierarchyType[]
   id?:number
    parentId?:number
    name?:string
    deleted?:boolean
 }
 export interface AreaHierarchyChild  {
    children: AreaHierarchyChild[]
    services: ServiceType[]
    id:number
    parentId:number
    name:string
    deleted?:boolean
 }
 export const createNewAreaHierarchyType = () : AreaHierarchyType => {
   return {areas:[]}
 }

// export interface AreaHierarchyType  {
//     area:AreaType
//     children?:AreaType[]
//  }
export interface AreaType  {
    id:number
    parentId?:number
    name:string
    sortOrder?:number
    deleted?:boolean
 }
export interface ServiceCategoryType {
   id:number
    description?:string
    name:string
    sortOrder?:number
    deleted?:boolean
    isNew:boolean
}
 export const createNewAreaType = (): AreaType =>{
    return {id:0, name:''}
 }
 export const createNewServiceCategoryType = (): ServiceCategoryType =>{
   return {id:0, name:'',isNew:true}
}