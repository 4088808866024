import FormFieldValidator from "../../validators/form-field-validator"
import FormValidator from "../../validators/form-validator"
import { createNewDomainEntity, DomainEntityType } from "../domain/iDomainEntity"
import { UserType } from "../user/iUser"
export interface FormSubmissionType  {
   id:number
   serviceId?:number
   formId:number
   fields:FormSubmissionFieldType[]
   score:number
   opelScore?:number
   appendExistingOpelFields?:boolean
   formResultOptionId?:number
   footerFields: FormFooterSubmissionFieldType[],
   createdOn?:Date
   createdBy?:UserType
}
export interface FormSubmissionFieldType  {
   field?:FormFieldType
   fieldOptionId?:number
   value?:any
   score?:number
   opelScore?:number
   fieldId:number
   numerator?:number
   denominator?:number
   calculationHelpResult?:number
    isValid?: boolean,
    formField?: FormFieldType
    formFieldOption?: FormFieldOptionType
}
export interface FormFooterSubmissionFieldType  {
   fieldType?:DomainEntityType
   fieldTypeId?:number
   fieldId:number
   value:any
   isValid?:boolean
}
export interface FormType  {
    id:number
    name:string
    sortOrder:number
    formType:DomainEntityType
    result:FormResultType
    opelResult:FormResultType
    deleted:boolean
    validator?:FormValidator
    fields:FormFieldType[]
    actionType:DomainEntityType
    footer:FormFooterType
    formActionNumeric?:number
    formActionText?:string
}

export interface FormDataCaptureReportType {
    service: DomainEntityType,
    submissions: FormSubmissionType[]
}
export interface SitRepReportPdfType {
    fileContents: File
}
export interface SitRepReportType {
    grouping: DomainEntityType,
    results: SitRepReportResultType[]
}
export interface SitRepReportResultType {
    service: DomainEntityType,
    escalationLevel: string,
    comments: string,
    dateSubmitted: Date,
    submittedBy: DomainEntityType
}

 export enum FormFieldTypeEnum {
   MultipleChoice= 10,
   Checkbox = 20,Text = 30,TextBlock  = 40,Dropdown=50,Email= 60, Numeric=70, Date=80
 }
 export enum FormFooterFieldTypeEnum {
   Comments= 10,
   ContactName = 20,ContactNumber = 30,AEAdmissions  = 40,MEDAdmissions=50,AllAdmissions= 60, Discharges=70
 }
 export enum FormActionTypeEnum {
   RedirectToForm = 10, DisplayMessage=20, RedirectToHomePage=30,RedirectToUrl=40
 }
 export enum FormFieldActionTypeEnum {
   ShowDynamicField = 10, ShowAllDynamicFields=20, HideDynamicField=30,HideAllDynamicFields=40
 }
 export enum FormTypeEnum {
   Weighted = 10, DataInput=20
 }
 export enum FormFieldDisplayTypeEnum {
   Visible = 10, Hidden=20, Dynamic=30
 }

 export interface FormFieldType  {
   id:number
   fieldNumber:number
   name:string
   sortOrder:number
   helpText:string
   numeratorLabel?:string
   negativeResultLabel?:string
   denominatorLabel?:string
   opelParameterId?:number
   resultLabel?:string
   formula?:string
   deleted:boolean
   hasCalculationHelp:boolean
   mandatory:boolean
   validator?:FormFieldValidator
   fieldType:DomainEntityType
   displayType:DomainEntityType
   options:FormFieldOptionType[]
   automationDatasetTypeId?:number
   automationDatasetMetricTypeId?:number
}
export interface FormFieldOptionType  {
   id:number
   name:string
   sortOrder:number
   deleted:boolean
   weighting:number
   opelWeighting?:number
   calculationHelpRangeFrom:number
   calculationHelpRangeTo:number
   reportingLabel:string
   formFieldActionFieldId?:number
   formFieldActionType?:DomainEntityType
   automationMetricRangeTo?:number
   automationMetricRangeFrom?:number
}

export interface FormSearchResultType   {
   id:number
   name:string
   deleted:boolean
   sortOrder:number
   numberOfFields:number
   formType: DomainEntityType
}

export enum FormResultTypeEnum {
   Standard = 10, Opel=20
 }

export interface FormResultType  {
   id:number
   name:string
   description:string
   sortOrder:number
   deleted:boolean
   formResultTypeId:number
   formResultType?:DomainEntityType
   options:FormResultOptionType[]
}

export interface FormResultOptionType  {
   id:number
   formId:number
   formResultOptionId:number
   formResultId:number
   formResultTypeId:number
   name:string
   shortName:string
   weightingFrom:number
   weightingTo:number
   sortOrder:number
   deleted:boolean
   style?:FormResultOptionStyleType
}
export interface FormResultOptionStyleType extends StyleType {
   id:number
}
export interface StyleType  {
   className?:string
   background?:string
   fontName?:string
   fontWeight?:string
   fontColour?:string
   fontSize?:string
}

export interface FormFooterType  {
   id:number
   name:string
   sortOrder:number
   deleted:boolean
   fields:FormFooterFieldType[]
}
export interface FormFooterFieldType  {
   id:number
   name:string
   fieldType:DomainEntityType
   mandatory:boolean
   sortOrder:number
   deleted:boolean
}

export const createNewForm = () : FormType => {
   const formtype = createNewDomainEntity()
   formtype.id = FormTypeEnum.Weighted
   return {id:0, name:'',sortOrder:0,deleted:false,validator : new FormValidator(),fields:[],actionType:createNewDomainEntity(),
      formType:formtype,result:createNewFormResult(),opelResult:createNewFormResult(),
      footer:createNewFormFooter()}
}
export const createNewFormResult = () : FormResultType => {
   return {id:-1, name:'', description:'',sortOrder:0,deleted:false,options:[],formResultTypeId:-1}
}

export const createNewFormField = (fieldTypeId:FormFieldTypeEnum) : FormFieldType => {
   const fieldType = createNewDomainEntity();fieldType.id = fieldTypeId;
   const displayType = createNewDomainEntity();displayType.id = FormFieldDisplayTypeEnum.Visible;
   return {id:-Date.now(), name:'new field',sortOrder:0,deleted:false,validator : new FormFieldValidator(),
   fieldType:fieldType,fieldNumber:0,options:[createNewFormFieldOption()],helpText:'',displayType:displayType,mandatory:true,hasCalculationHelp:false}
}

export const createNewFormFieldOption = () : FormFieldOptionType => {
   return {id:-Date.now(), name:'',sortOrder:0,deleted:false, weighting:0, reportingLabel:'',calculationHelpRangeFrom:0, calculationHelpRangeTo:0}
}

export const createNewFormSubmissionFieldFromField = (field:FormFieldType) : FormSubmissionFieldType => {
   return {field:field, fieldOptionId:undefined,score:0, fieldId:field.id}
}
export const createNewFormSubmissionFieldNoFormField = (field:FormFieldType) : FormSubmissionFieldType => {
   return {field:undefined, fieldOptionId:undefined,score:0, fieldId:field.id}
}

export const createNewFormFooter = () : FormFooterType => {
   return {deleted:false, fields:[], id:0, name:'',sortOrder:0}
}
export const createNewBlankFormSubmission = () : FormSubmissionType => {
   return {fields:[], id:0,footerFields:[],score:0,formId:0}
}
export const createNewFormSubmission = (form?:FormType) : FormSubmissionType => {
   const ff = form?.footer?.fields.map((f) => createNewFormFooterSubmissionField(f))
   const f : FormSubmissionFieldType[] = []
      form?.fields.forEach((field)=>{
       const newField = createNewFormSubmissionFieldFromField(field)
       f.push(newField)
     })
   return {fields:f, id:0,footerFields:ff||[],score:0,formId:form?.id||0}
}
export const primeFormForSubmission = (form?:FormSubmissionType) : FormSubmissionType => {
   const ff:FormFooterSubmissionFieldType[] =[]
   form?.footerFields?.forEach((f) =>ff.push({fieldType:f.fieldType,fieldId:f.fieldId,value: f.value,fieldTypeId:f?.fieldType?.id }))
   const f : FormSubmissionFieldType[] = []
      form?.fields.forEach((field)=>{
       const newField = {fieldOptionId:field.fieldOptionId,
         value:field.value,
         fieldId:field.fieldId,
         numerator:field.numerator,
         denominator:field.denominator,
         calculationHelpResult:field.calculationHelpResult}
       f.push(newField)
     })
   return {fields:f, id:0,footerFields:ff||[],score:0,formId:form?.id||0, formResultOptionId:form?.formResultOptionId}
}
export const createNewFormFooterSubmissionField = (field?:FormFooterFieldType) : FormFooterSubmissionFieldType => {
   const fieldType = createNewDomainEntity()
   fieldType.id = field?.fieldType?.id||0
   return {fieldType:fieldType, value:undefined,fieldId:field?.id||0}
}
export const getIconNameFromFieldType = (fieldTypeId:FormFieldTypeEnum) => {
  
   let icon = 'pi pi-list'
 
   switch(fieldTypeId){
     case FormFieldTypeEnum.MultipleChoice:
       icon = 'pi pi-list';break;
       case FormFieldTypeEnum.Checkbox:
       icon = 'pi pi-check-square';break;
       case FormFieldTypeEnum.Date:
       icon = 'pi pi-calendar';break;
       case FormFieldTypeEnum.Dropdown:
       icon = 'pi pi-sort-down';break;
       case FormFieldTypeEnum.Email:
       icon = 'pi pi-envelope';break;
       case FormFieldTypeEnum.Numeric:
       icon = 'pi pi-calculator';break;
       case FormFieldTypeEnum.Text:
       icon = 'pi pi-stop';break;
       case FormFieldTypeEnum.TextBlock:
       icon = 'pi pi-tablet';break;
   }
   return icon
}