import React, { useState } from "react";
import { isCombinedService, ServiceCategoryEnum, ServiceStatusType } from "../../interfaces/system/serviceType";
import Panel from "../core/panel";
import ActionButton from "../core/actionButton";
import { useNavigate } from "react-router-dom";
import FormSubmissionSummary from "./form-submission-summary";
import { Grid } from "@mui/material";
import { CapacityGridSummary } from "./capacity-grid-summary";
import { CapacityGridKeyFieldSummaryByServiceId } from "./capacity-grid-key-field-summary-by-service";
import Dropdown, { IDropdownOptions } from "../core/dropdown";
import {  getCachedOptions, getFormResultOptionsFromFormId, getFormResultOptionsForResultId } from "../../services/domainEntitiesService";

interface ServiceOverviewProps {
    service?: ServiceStatusType
    onMoreInformationClicked?: () => void
    hidePanel?: boolean
    supressGauge?: boolean
    onCapacityStatusDivisionSelected?: (serviceId: number) => void
    onCombinedUpdated?: (serviceId: number, formResultOptionId:number, auto: boolean) => void
}


const ServiceOverview: React.FC<ServiceOverviewProps> = (props) => {
    const [service, setService] = useState(props.service);
    const [isCombined, setIsCombined] = useState(false);
    const [combinedFormResultOptions, setCombinedFormResultOptions] = useState<IDropdownOptions[]>();
    const [combinedFormResultSelectedOptionId, setCombinedFormResultSelectedOptionId] = useState<number>();

    const navigate = useNavigate();
    
    React.useEffect(() => {
        const s = props.service
        
        
        const isCombined = isCombinedService(props?.service?.category.id || 0)
        setIsCombined(isCombined)


        if(isCombined && s){
            populateResultOptions(s)
        }
        setService(s)
        
    }, [props.service])

    const populateResultOptions =async (s:ServiceStatusType) =>{
        
        let resultOptions =await  getFormResultOptionsFromFormId(s.formId)
        
        if((!resultOptions || resultOptions.length<1) && s.formResultId)   { 
            const options = getCachedOptions()
            resultOptions =await  getFormResultOptionsForResultId(options.formResultOptions, s.formResultId)
        }
        const o =  resultOptions.map(m=> ( {id:m.formResultOptionId,code:'',name:m.shortName}));
        setCombinedFormResultOptions(o)
    }
    const handleUpdateEscalationLevel = () => {
        navigate(`/form-submission/${service?.id}`)
    }
    const handleDemandAndCapacity = () => {
        navigate(`/demandandcapacity/${service?.code}`)
    }
    const handleBedCapacity = () => {
        const serviceId = (service?.parentServiceId ?? 0) ? (service?.parentServiceId ?? 0) : service?.id
            navigate(`/capacity-grid-submission/${serviceId}`)
        //}
    }
    // const handleBedCapacityForArea = () => {
    //     navigate(`/capacity-grid-submission/?areaId=${service?.area.id}`)
    // }
    const handleBedCapacityReportForArea = () => {
        navigate(`/reporting/capacity-grid/?areaId=${service?.area.id}`)
    }
    const handleCapacitySummaryDivionSelected = (serviceId: number) => {
        if (props.onCapacityStatusDivisionSelected) {
            props.onCapacityStatusDivisionSelected(serviceId);
        }
    }
    const handleUpdateCombined = (auto:boolean) =>{
        if (service && props.onCombinedUpdated 
            && (combinedFormResultSelectedOptionId || auto)) {
          
            props.onCombinedUpdated(service.id,combinedFormResultSelectedOptionId??0,auto);
        }
    }
    

    let celElem = <></>
    if(service?.category.id === ServiceCategoryEnum.Combined &&
        props.supressGauge){
        celElem = <Panel title="Update Combined Escalation Level">
                    <Grid container spacing={1} >
                        <Grid item xs={9}>
                            <Grid container spacing={1} >
                                <Grid item xs={6}>
                                    <Dropdown label="Manual Escalation Level" filter={false} includeBlankOption={true} value={combinedFormResultSelectedOptionId}
                                    options={combinedFormResultOptions} onChange={(e) => setCombinedFormResultSelectedOptionId(e.target.value as number)}></Dropdown>
                                </Grid>
                                <Grid item xs={5}>
                                    {combinedFormResultSelectedOptionId && <ActionButton label="Apply" onClick={()=>handleUpdateCombined(false)}></ActionButton>}
                                </Grid>
                                <Grid item xs={5}>
                                    <ActionButton label="Auto Update" onClick={()=>handleUpdateCombined(true)}></ActionButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={9}>
                            <ActionButton label="Auto Calculate" ></ActionButton>
                        </Grid> */}
                    </Grid>
        </Panel>
    }


    return <>
        <Grid container spacing={1} justifyContent="center">
            <Grid item xs={11}>
                <Panel title={service?.name} hidePanel={props.hidePanel} >
                    <Grid container spacing={1} style={{ borderBottom:'1px solid #dee2e6', marginBottom:'1rem', paddingBottom:'1rem'} }>
                        <Grid item xs={6}>
                            <FormSubmissionSummary supressGauge={props.supressGauge} displayType='stacked' showFooter={true} serviceStatus={service} padding={10} showLastUpdated={true} className={props.supressGauge ? undefined : '_label-text-value-center'} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormSubmissionSummary supressGauge={props.supressGauge} displayType='stacked' showFooter={false} serviceStatus={service} opelScore={service?.opelScore} padding={10} showLastUpdated={true} className={props.supressGauge ? undefined : '_label-text-value-center'} />
                        </Grid>
                        <Grid item xs={6}>
                            {celElem}
                        </Grid>
                    </Grid>

                    <CapacityGridSummary serviceStatus={service}></CapacityGridSummary>
                    <Grid container spacing={1}>
                        {(!isCombined && (service?.formId ?? 0) > 0) &&
                            <Grid item xs={6}>
                                <ActionButton icon="pi pi-check-circle" label="Update Escalation Level" severity="success" onClick={handleUpdateEscalationLevel} />
                            </Grid>
                        }

                        <Grid item xs={6}>
                            {props.onMoreInformationClicked &&
                                <ActionButton icon="pi pi-info-circle" label="More Information" severity="secondary" onClick={props.onMoreInformationClicked} />
                            }
                        </Grid>
                        {((service?.capacityGridId ?? 0) >0||service?.hasDivisionsWithCapacityGridResults)  && <>
                            <Grid item xs={6}>
                                    <ActionButton icon="pi pi-info-circle" label={`Update Bed Capacity for service`} severity="help" onClick={handleBedCapacity} />
                                </Grid>
                            {/* {(service?.area?.id ?? 0) > 0 && <Grid item xs={6}>
                                <ActionButton icon="pi pi-info-circle" label="Update Bed Capacity for area" severity="help" onClick={handleBedCapacityForArea} />
                            </Grid>
                            } */}
                            <Grid item xs={6}>
                                <ActionButton icon="pi pi-info-circle" label="View Bed Capacity report" severity="help" onClick={handleBedCapacityReportForArea} />
                            </Grid>
                        </>
                        }
                        <Grid item xs={6}>
                            {service?.demandAndCapacityProviderCode && <ActionButton icon="pi pi-info-circle" label="Demand and Capacity" severity="help" onClick={handleDemandAndCapacity} />}
                        </Grid>

                        {!props.onMoreInformationClicked && service?.hasDivisionsWithCapacityGridResults &&
                            <Grid item xs={12}>
                                <CapacityGridKeyFieldSummaryByServiceId serviceId={service.id} onServiceSelected={handleCapacitySummaryDivionSelected} />
                            </Grid>
                        }
                        <p>&nbsp;</p>
                    </Grid>
                </Panel>
            </Grid>
        </Grid>
    </>;
}


export default ServiceOverview;

