import { formatDate, formatDateTime } from "../../extensions/formatter"
import { FormComplianceReportType, FormSubmissionByFieldReportType, FormSubmissionLevelByDateReportType, OpelFormSubmissionIdsType, FormSubmissionType, ServiceStatusReportType, OpelFormSubmissionType, FormSubmissionResultByMonthYearSubmission } from "../../interfaces/reporting/form-submission"
import { FormDataCaptureReportType, FormResultTypeEnum, SitRepReportPdfType, SitRepReportType } from "../../interfaces/system/formType"
import { getFormResultOptionsFromFormId } from "../domainEntitiesService"
import { fetchFormFromCache } from "../form-service"
import { HttpGet, HttpResponse,HttpPost, DownloadFile } from "../http"

export const fetchFormSubmission  = async (id:number) : Promise<HttpResponse<FormSubmissionType>> => {
    const response = await HttpGet<FormSubmissionType>(
      `reporting/form/form-submission/${id}`
    )
    
    return response
  }

  export const fetchFormSubmissionForServiceByDateRangeLite  = async (ids:number[],fromDate:Date, toDate:Date) : Promise<HttpResponse<OpelFormSubmissionIdsType>> => {
    const response = await HttpPost<OpelFormSubmissionIdsType>(
      `reporting/form/submissions-by-daterange-lite`,
      {serviceIds:ids,fromDate:fromDate, toDate:toDate}
    )
    
    return response
  }
  export const fetchOpelFormSubmission  = async (serviceId:number,id:number) : Promise<HttpResponse<OpelFormSubmissionType>> => {
    const response = await HttpPost<OpelFormSubmissionType>(
      `reporting/form/opel-form-submission`,{formSubmissionId:id,serviceId:serviceId}
    )
    
    return response
  }

  export const fetchFormCompliance  = async (serviceIds:number[], mode:number) : Promise<HttpResponse<FormComplianceReportType[]>> => {
    const response = await HttpPost<FormComplianceReportType[]>(
      `reporting/form/form-compliance`,{serviceIds:serviceIds, mode:mode}
    )
    
    return response
  }

  export const fetchServiceStatus  = async (serviceIds:number[], serviceCategoryIds?:number[] ) : Promise<HttpResponse<ServiceStatusReportType>> => {
    const response = await HttpPost<ServiceStatusReportType>(
      `reporting/form/service-status`,{serviceIds:serviceIds, serviceCategoryIds:serviceCategoryIds,}
    )
    
    return response
  }

  export const fetchFormSubmissionByField  = async (serviceIds:number[], serviceCategoryIds?:number[],
  fromDate?:Date, toDate?:Date,reportingTypeId?:number) : Promise<HttpResponse<FormSubmissionByFieldReportType>> => {

    let to = toDate

    if(to){
      to = new Date(to)
      to.setHours(23)
      to.setMinutes(59)
      to.setSeconds(59)
    }
    const response = await HttpPost<FormSubmissionByFieldReportType>(
      `reporting/form/form-submission-by-field`,{serviceIds:serviceIds, serviceCategoryIds:serviceCategoryIds,
      fromDate:fromDate, toDate:to,reportingTypeId:reportingTypeId}
    )
    
    return response
  }
  export const fetchFormSubmissionResultByMonthYear  = async (serviceIds:number[], reportingDate?:Date) : Promise<HttpResponse<FormSubmissionResultByMonthYearSubmission>> => {
      const response = await HttpPost<FormSubmissionResultByMonthYearSubmission>(
        `reporting/form/submissions-by-month-year`,{serviceIds:serviceIds, 
          reportingDate: reportingDate}
      )
      
      return response
    }

  export const fetchFormSubmissionForServiceDateRange  = async (serviceId:number, from:Date,to:Date) : Promise<HttpResponse<FormSubmissionLevelByDateReportType>> => {
      const response = await HttpPost<FormSubmissionLevelByDateReportType>(
        `reporting/form/submissions-by-daterange`,{serviceId:serviceId,fromDate:from,toDate:to}
      )
      if(response.success && response.result &&
        response.result?.submissions.length>0){
          const form = await fetchFormFromCache(response.result?.submissions[0].formId)
          if(form.success)
            response.result.form = form.result
      }
      return response
}

export const fetchFormDataCaptureReportData = async (serviceIds: number[], from: Date, to: Date): Promise<HttpResponse<FormDataCaptureReportType[]>> => {
    const response = await HttpPost<FormDataCaptureReportType[]>(
          `reporting/form/data-capture-report`, { serviceIds: serviceIds,fromDate:from,toDate:to}
      )
      
      return response
    }

export const fetchSitRepReportData = async (serviceIds: number[], from: Date, to: Date, categoryIds?: number[], opel?:boolean, opelId?:number): Promise<HttpResponse<SitRepReportType[]>> => {
    const response = await HttpPost<SitRepReportType[]>(`reporting/form/sitrep-report`, { serviceIds: serviceIds, serviceCategoryIds: categoryIds, fromDate: from, toDate: to, opel: opel, OPELId: opelId })
    return response
}
export const fetchSitRepReportPdf = async (serviceIds: number[], from: Date, to: Date, categoryIds?: number[], opel?: boolean, opelId?: number): Promise<boolean> => {
    const result = await DownloadFile(`reporting/form/sitrep-report-pdf`, { serviceIds: serviceIds, serviceCategoryIds: categoryIds, fromDate: from, toDate: to, opel: opel, OPELId: opelId }, `EMS Situation Report ${formatDate(new Date())}`)
    return result;
}

  export const calculateFormSubmissionByFieldAggregate = (formSubmissionData?:FormSubmissionByFieldReportType) =>{
    if(!formSubmissionData || formSubmissionData.serviceSubmissions.length<1) return formSubmissionData

    formSubmissionData.serviceSubmissions.forEach((service) =>{

      service.aggregate = {fields:[]}
      service.form.fields.forEach(f=>{
        service.aggregate.fields.push({fieldId:f.id, name:f.name, selections:[{count:0, reportingLabel:'1'}, {count:0, reportingLabel:'2'},{count:0, reportingLabel:'3'},{count:0, reportingLabel:'4'}]})
      })

      service.submissions.forEach((submission) =>{
          submission.fields.forEach((f) => {
            const fieldIndex = service.aggregate.fields.findIndex(ff=> ff.fieldId === f.id)
            if(fieldIndex<0) return

            const reportingLabelIndex = service.aggregate.fields[fieldIndex].selections.findIndex(i=>i.reportingLabel=== f.reportingLabel)
            if(reportingLabelIndex<0) return
            

            service.aggregate.fields[fieldIndex].selections[reportingLabelIndex].count++
          })
      })
    } )

    return formSubmissionData
  }

  export const setStylesForFieldsFromSubmission = async (formSubmission: FormSubmissionType,
    formResultType:FormResultTypeEnum) =>{
    if(!formSubmission || !formSubmission.fields || formSubmission?.fields.length<1) return formSubmission
  
    formSubmission={...formSubmission}
    let fields = [...formSubmission.fields]
    if(formResultType.toString() === FormResultTypeEnum.Opel.valueOf().toString()){
      fields  = [...formSubmission.fields.filter(f=> f.formField.opelParameterId??0>0)]
    }
      
  const formResultOptions = await getFormResultOptionsFromFormId((formResultType=== FormResultTypeEnum.Opel) ? undefined: formSubmission.formId)
 

  let fieldNo = 1;
    fields.forEach(f => {
      
      const style = formResultOptions.find(ff=> ff.shortName === f.formFieldOption.reportingLabel
          && ff.formResultTypeId.toString() ===formResultType.valueOf().toString())
       f.style = style?.style
       f.formField.name = `${fieldNo}. ${f.formField.name}`
       fieldNo++

    });

    
    return {...formSubmission,fields:[...fields]}
  
  }
 

