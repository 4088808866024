import ServiceValidator from "../validators/serviceValidator";
import { createNewDomainEntity, DomainEntityType } from "../interfaces/domain/iDomainEntity";
import { AreaHierarchyChild, ServiceCategoryType } from "../interfaces/system/areaTypes";
import { AreaHierarchyType } from "../interfaces/system/areaTypes";
import { ServiceOverviewType, ServiceSearchResultType, ServiceStatusContainerType, ServiceType } from "../interfaces/system/serviceType";
import { HttpResponse, HttpGet, HttpPost } from "./http";
import { getAllAreaHierarchyWithServicesFromSessionStorage, setAllAreaHierarchyWithServicesFromSessionStorage } from "./storageService";
import { AreaType } from "../interfaces/system/areaTypes";
import { CapacityGridDailyReportFilterOptions } from "../interfaces/system/capacityGridType";
import { AutomatedReportType } from "../interfaces/system/systemTypes";

export const fetchAllAreaHierarchyWithServices = async (): Promise<HttpResponse<AreaHierarchyType>> => {


    var cachedValue = getAllAreaHierarchyWithServicesFromSessionStorage();
    if (cachedValue)
        return { success: true, result: cachedValue }

    const response = await HttpGet<AreaHierarchyType>(
        `service/areas-including-services`
    )

    if (response.success)
        setAllAreaHierarchyWithServicesFromSessionStorage(response.result as AreaHierarchyType)
    return response
}
export const getAreaListFromHierarchy = async () => {
    const response = await fetchAllAreaHierarchyWithServices();
    if (!response.success)
        return []

    const areaList: DomainEntityType[] = []
    response.result?.areas.forEach(f => {
        areaList.push({ id: f.id, name: f.name, displayOrder: 0, active: true, children: [] })
        getChildAreasFromHierarchy(areaList, f)
    })

    return areaList
}


export const getAreaHierarchyForAreaId = async (areaId:number) => {
    const response = await fetchAllAreaHierarchyWithServices();
    if (!response.success || !response.result?.areas)
        return []

    const areaList: number[] = [areaId]

    let topLevelArea = getAreaFromHierarchyFromId(response.result?.areas,areaId,undefined)
         if(topLevelArea)
             getChildAreasFromHierarchyForId(areaList, topLevelArea,topLevelArea?.id )

    return areaList
}
const getAreaFromHierarchyFromId = (areaList: AreaHierarchyChild[], areaId:number,topLevelArea?:AreaHierarchyChild) => {
    areaList.forEach(f => {
        if(f.id === areaId){
            topLevelArea = f
            return f}
        getAreaFromHierarchyFromId(f.children, areaId, topLevelArea)
        if(topLevelArea)
            return topLevelArea
    })
    return topLevelArea
}
const getChildAreasFromHierarchyForId = (areaList: number[], area: AreaHierarchyChild,areaId:number) => {
    

    area.children.forEach(f => {
        if(f.parentId === areaId)
            areaList.push(f.id)
        
        getChildAreasFromHierarchyForId(areaList, f,f.id)
    })

    return areaList
}

export const getChildAreasFromHierarchy = (areaList: DomainEntityType[], area: AreaHierarchyChild) => {
    area.children.forEach(f => {
        areaList.push({ id: f.id, name: f.name, displayOrder: 0, active: true, children: [] })
        getChildAreasFromHierarchy(areaList, f)
    })

    return areaList
}

export const fetchServiceStatus = async (): Promise<HttpResponse<ServiceStatusContainerType>> => {
    // var cachedValue = getAllAreaHierarchyWithServicesFromSessionStorage();
    // if(cachedValue)
    //     return {success:true, result:cachedValue}

    const response = await HttpGet<ServiceStatusContainerType>(
        `service/service-status`
    )

    return response
}
export const fetchServiceOverview = async (id: number): Promise<HttpResponse<ServiceOverviewType>> => {
    const response = await HttpGet<ServiceOverviewType>(
        `service/overview/${id}`
    )

    return response
}

export const filterByCapacityGridOptions = async (options?: CapacityGridDailyReportFilterOptions): Promise<HttpResponse<ServiceType[]>> => {
    let _deleted = options?.deleted ?? false;
    const response = await HttpPost<ServiceType[]>('service/capacity-grid-filter',
        {
            "areaId": options?.areaId,
            "serviceId": options?.serviceId,
            "categoryId": options?.categoryId,
            "capacityGridId": options?.capacityGridId,
            "bedTypeId": options?.bedTypeId,
            "deleted": _deleted
        });

    return response
}

export const fetchAreaList = async (): Promise<HttpResponse<AreaType[]>> => {
    const response = await HttpGet<AreaType[]>(
        `service/area-list`
    )
    return response
}
export const fetchAutomatedReportList = async (): Promise<HttpResponse<AutomatedReportType[]>> => {
    const response = await HttpGet<AutomatedReportType[]>(
        `system/automated-report-list`
    )
    return response
}
export const fetchAutomatedReport = async (id:string): Promise<HttpResponse<AutomatedReportType>> => {
    const response = await HttpGet<AutomatedReportType>(
        `system/automated-report/${id}`
    )
    return response
} 

export const fetchServiceCategoryList = async (): Promise<HttpResponse<ServiceCategoryType[]>> => {
    const response = await HttpGet<ServiceCategoryType[]>(
        `service/category-list`
    )
    return response
}

export const fetchServiceList = async (): Promise<HttpResponse<ServiceSearchResultType[]>> => {

    const response = await HttpGet<ServiceSearchResultType[]>(
        `service/service-list`
    )

    return response
}
export const fetchServiceFeaturesList = async (serviceId: number): Promise<HttpResponse<DomainEntityType[]>> => {

    const response = await HttpGet<DomainEntityType[]>(
        `service/features/${serviceId}`
    )

    return response
}
export const fetchService = async (id: number): Promise<HttpResponse<ServiceType>> => {
    const response = await HttpGet<ServiceType>(
        `service/${id}`
    )

    if (response.result) {
        
        response.result.validator = new ServiceValidator()
        if (response.result.divisions) {
            response.result.divisions.forEach(f => f.validator = new ServiceValidator())
        }
    }

    
    return response
}

export const fetchServiceCategory = async (id: number): Promise<HttpResponse<ServiceCategoryType>> => {

    const response = await HttpGet<ServiceCategoryType>(
        `service/category/${id}`
    )

     
    return response
}

export const saveService = async (service: ServiceType): Promise<HttpResponse<ServiceType>> => {
    const s = { ...service }
    s.validator = undefined;

    if(s.id<0)s.id =0
    if (s.divisions) s.divisions.forEach(f => { if (f.id < 0) f.id = 0; if(f?.address && f.address.id<0) f.address.id = 0 })
    if (s.distributionLists) s.distributionLists.forEach(f => { if (f.serviceId < 0) f.serviceId = 0 })

    // if (s.cutOffAM && s.cutOffAM !== '')
    //     s.cutOffAM = "1900-01-01T" + s.cutOffAM
    // if (s.cutOffPM && s.cutOffPM !== '')
    //     s.cutOffPM = "1900-01-01T" + s.cutOffPM

    const response = await HttpPost<ServiceType>(
        `service`, s
    )

    return response
}

export const saveServiceCategory = async (s: ServiceCategoryType): Promise<HttpResponse<ServiceCategoryType>> => {
    
    const response = await HttpPost<ServiceCategoryType>(
        `service/category`, s
    )

    return response
}

export const saveAutomatedReport = async (a: AutomatedReportType): Promise<HttpResponse<AutomatedReportType>> => {
    a.reportType = createNewDomainEntity()
    a.reportType.id = a.reportTypeId??0
    const response = await HttpPost<AutomatedReportType>(
        `system/automated-report`, a
    )

    return response
}

export const saveAreaHierarchy = async (areas: AreaHierarchyType[]): Promise<HttpResponse<AreaHierarchyType[]>> => {

    const response = await HttpPost<AreaHierarchyType[]>(
        `service/area`, { areas: areas }
    )

    return response
}
