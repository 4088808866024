import React, { useState, useEffect } from "react";
import Map, { MapMarkerType } from './map'
import Loader from '../core/loading';
import { ServiceCategoryEnum, ServiceStatusType } from "../../interfaces/system/serviceType";
import { Dialog } from 'primereact/dialog';
import { Grid } from '@mui/material'
import ActionButton from "../core/actionButton";
import ServiceOverview from "./service-overview";
import { TabMenu } from 'primereact/tabmenu';
import MultiSelect, { SelectOptionType } from "../core/multiSelect";
import ServiceListview from "./listview";
import TickBox from "../core/tickBox";
import { dateDiffFromNowInHours } from "../../services/utils";
import { getServiceFilterFromSession, getUser, saveServiceFilterToSession } from "../../services/storageService";
import ServiceFullDetails from "./service-full-details";
import { FormSubmissionType } from "../../interfaces/reporting/form-submission";
import { DomainEntityType } from "../../interfaces/domain/iDomainEntity";
import { AddressType } from "../../interfaces/system/iAddress";
import Dropdown from "../core/dropdown";
import TextBox from "../core/textBox";
import { DocumentUploadType } from "../../interfaces/system/documentUploadTypes";
import { ActionCardHomepageOverviewType } from "../../interfaces/system/actionCardType";
import { getAreaHierarchyForAreaId } from "../../services/systemService";

interface IProps {
    loading?: boolean
    serviceStatusList?: ServiceStatusType[]
    onSaveServiceFilter?: (filter: string) => void
    formSubmission?: FormSubmissionType
    serviceFeatures?: DomainEntityType[]
    serviceAddress?: AddressType
    serviceUploads?: DocumentUploadType[]
    serviceActionCard?: ActionCardHomepageOverviewType
    onRequestFormSubmission?: (serviceId: number) => void
    onRequestServiceAddress?: (serviceId: number) => void
    onRequestServiceFeatures?: (serviceId: number) => void
    onRequestServiceUploads?: (serviceId: number) => void
    onRequestServiceActionCard?: (serviceId: number) => void
    onCombinedUpdated?: (serviceId: number, formResultOptionId:number, auto:boolean) => void
}

interface ServiceFilterType {
    serviceCategory: SelectOptionType[]
    service: SelectOptionType[]
    area: SelectOptionType[]
    updatedInLast24Hours?: boolean
    servicesWithAvailabilityOnly?: boolean
    bedType: SelectOptionType[]
    maxWeeklyCost?: number
    viewMode?:number
}



const Home: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState(props.loading || false);
    const [mapMarkers, setMapMarkers] = useState<MapMarkerType[]>([]);
    const [selectedService, setSelectedService] = useState<ServiceStatusType>();
    const [serviceList, setServiceList] = useState(props.serviceStatusList);
    const [activeIndex, setActiveIndex] = useState(0);
    const [filter, setFilter] = useState<ServiceFilterType>({ serviceCategory: [], service: [], area: [], bedType: [], servicesWithAvailabilityOnly: false });
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [showServiceOverviewDialog, setShowServiceOverviewDialog] = useState(false);
    const [showServiceOverviewInpane, setShowServiceOverviewInpane] = useState(false);

    const [formSubmission, setFormSubmission] = useState(props.formSubmission);
    const [serviceFeatures, setServiceFeatures] = useState(props.serviceFeatures);
    const [serviceAddress, setServiceAddress] = useState(props.serviceAddress);
    const [serviceUploads, setServiceUploads] = useState(props.serviceUploads);
    const [serviceActionCard, setServiceActionCard] = useState(props.serviceActionCard);

    useEffect(() => {
        setLoading(props.loading || false)
    }, [props.loading])


    useEffect(() => {
        let sessionFilter = getServiceFilterFromSession()
        let f = filter

        if (sessionFilter.length === 0) {
            const user = getUser()
            sessionFilter = user.defaultServiceFilter || ''
            if (sessionFilter.length > 0)
                f = JSON.parse(JSON.parse(sessionFilter))
        }
        else
            f = JSON.parse(sessionFilter)
        setFilter(f)
    }, [props.serviceStatusList])

    useEffect(() => {
        if(props.serviceStatusList)
            presentServiceList()
        
    }, [filter,props.serviceStatusList])

    useEffect(() => {
        setServiceFeatures(props.serviceFeatures)
    }, [props.serviceFeatures])
    useEffect(() => {
        setFormSubmission(props.formSubmission)
    }, [props.formSubmission])
    useEffect(() => {
        setServiceAddress(props.serviceAddress)
    }, [props.serviceAddress])

    useEffect(() => {
        setServiceUploads(props.serviceUploads)
    }, [props.serviceUploads])

    useEffect(() => {
        setServiceActionCard(props.serviceActionCard)
    }, [props.serviceActionCard])


    const presentServiceList = async () => {
        
        if (!props.serviceStatusList) return;

        const markers: MapMarkerType[] = []
        const services: ServiceStatusType[] = []

        let areaIds: number[] =[]
        if (filter.area.length > 0) {
            for(var i=0;i<filter.area.length;i++){
                const ids= await getAreaHierarchyForAreaId(parseInt(filter.area[i].code))
                areaIds = areaIds.concat(ids)
            }
        }

        props.serviceStatusList?.forEach(f => {

            if (filter.serviceCategory.length > 0) {
                if (filter.serviceCategory.findIndex(sc => f.category.id.toString() === sc.code ||
                    f.category.id === ServiceCategoryEnum.IntegratedCareSystem) < 0)
                    return
            }

            if (filter.service.length > 0) {
                if (filter.service.findIndex(sc => f.id.toString() === sc.code || f.parentServiceId?.toString() === sc.code) < 0)
                    return
            }

            if (filter.area.length > 0 && areaIds.length>0) {
                        
                if (areaIds.findIndex(ff=>ff=== f.area.id) <0)
                    return
            }

            if ((filter.bedType?.length ?? 0) > 0) {
                if (filter.bedType.findIndex(sc => f.bedTypeId && f.bedTypeId.toString() === sc.code) < 0)
                    return
            }

            if (filter.maxWeeklyCost && (filter.maxWeeklyCost ?? 0) > 0) {
                if (!f.costPerWeek || (f.costPerWeek ?? 0) > filter.maxWeeklyCost)
                    return
            }

            if (filter.updatedInLast24Hours !== undefined && filter.updatedInLast24Hours) {
                const hoursSinceUpdate = dateDiffFromNowInHours(f?.lastUpdatedOn)
                if (hoursSinceUpdate === 0 || hoursSinceUpdate > 24)
                    return
            }

            if (filter.servicesWithAvailabilityOnly !== undefined && filter.servicesWithAvailabilityOnly) {
                if ((f.capacityGridId ?? 0) <= 0) 
                    return
            }

            if (filter.viewMode !== undefined && filter.viewMode) {
                if(filter.viewMode === 1 && (f.formId ?? 0)<=0)
                    return
                if (filter.viewMode === 2 &&  (f.capacityGridId ?? 0) <= 0) 
                    return
                if (filter.viewMode === 3 &&  (f.category.id !== 50) )
                    return
                if (filter.viewMode === 4 &&  (f.category.id !== 30) )
                    return
                if (filter.viewMode === 5 &&  (!f.demandAndCapacityProviderCode) )
                    return
            }

            services.push(f)
            markers.push({ service: f })
        })

        setServiceList(services)
        setMapMarkers(markers)


        saveServiceFilterToSession(JSON.stringify(filter))

        setLoading(false)

    }

    const renderFooter = () => {
        return (
            <div>
                <ActionButton label="Close" icon="pi pi-times" severity="info" onClick={handleHideDialog} />
            </div>
        );
    }
    const handleHideDialog = () => {
        setSelectedService(undefined)
        setShowServiceOverviewDialog(false)
    }
    const handleServiceSelected = (service: ServiceStatusType) => {

        setShowServiceOverviewInpane(false)

        setShowServiceOverviewDialog(true)
        setSelectedService(service)
        setFormSubmission(undefined)
        setServiceFeatures(undefined)
        setServiceAddress(undefined)
        setServiceUploads(undefined)
        setServiceActionCard(undefined)

    }
    const handleToggleView = (index: number) => {
        setActiveIndex(index)
    }
    const filterServiceCategory = (ids: SelectOptionType[]) => {
        setFilter({ ...filter, serviceCategory: ids })
    }
    const filterService = (ids: SelectOptionType[]) => {
        setFilter({ ...filter, service: ids })
    }
    const filterBedType = (ids: SelectOptionType[]) => {
        setFilter({ ...filter, bedType: ids })
    
    }
    const filterViewMode = (e:any) => {
        setFilter({ ...filter, viewMode: e.value })
    }
    const filterUpdatedInLast24Hours = () => {
        filter.updatedInLast24Hours = !filter.updatedInLast24Hours
        setFilter({ ...filter })
    }
    const handleAvailabilityOnlyChange = (event: any) => {
        setFilter({ ...filter, servicesWithAvailabilityOnly: event.target.checked })
    };
    const filterArea = (ids: SelectOptionType[]) => {
        setFilter({ ...filter, area: ids })
    }
    const toggleSearch = () => {
        setSearchExpanded(!searchExpanded)
    }
    const handleSaveFilter = async () => {
        if (props.onSaveServiceFilter)
            props.onSaveServiceFilter(JSON.stringify(filter))
    }
    const handleClearFilter = async () => {
        setLoading(true);
        setFilter({ serviceCategory: [], service: [], area: [], bedType: [], servicesWithAvailabilityOnly: false })
        window.location.reload();
    }
    const handleShowServiceDetails = () => {
        setShowServiceOverviewDialog(false)
        setShowServiceOverviewInpane(true)

        scrollToEnd()


    }
    const scrollToEnd = (delay?: number) => {
        const textElement = document.getElementById("overview")
        if (textElement)
            setTimeout(() => {
                textElement.scrollIntoView({ behavior: "smooth" })
            }, delay || 150);
    }
    const handleUpdateCombined = (serviceId: number, formResultOptionId:number, auto:boolean) =>{
        if (props.onCombinedUpdated) {
            props.onCombinedUpdated(serviceId,formResultOptionId,auto);
        }
    }
    const handleHideOverviewInPane = () => {
        setShowServiceOverviewInpane(false)
        handleHideDialog()
    }
    const handleRequestFormSubmission = (serviceId: number) => {
        if (props.onRequestFormSubmission)
            props.onRequestFormSubmission(serviceId)

        scrollToEnd(750)
    }
    const handleRequestFeatures = (serviceId: number) => {
        if (props.onRequestServiceFeatures)
            props.onRequestServiceFeatures(serviceId)

        scrollToEnd(750)
    }
    const handleRequestAddress = (serviceId: number) => {
        if (props.onRequestServiceAddress)
            props.onRequestServiceAddress(serviceId)

        scrollToEnd(750)
    }

    const handleRequestUploads = (serviceId: number) => {
        if (props.onRequestServiceUploads)
            props.onRequestServiceUploads(serviceId)

        scrollToEnd(750)
    }

    const handleRequestActionCard = (serviceId: number) => {
        if (props.onRequestServiceActionCard)
            props.onRequestServiceActionCard(serviceId)

        scrollToEnd(750)
    }

    const handleCapacitySummaryDivisonSelected = (serviceId: number) => {
        if (serviceList) {
            const svc = serviceList.find(s => s.id === serviceId);
            if (svc) {
                handleServiceSelected(svc);
            } else {
                console.error(`Selected division ID was not found in result list. This could mean search criteria has changed in between views`);
            }
        }
    }

    if (loading)
        return <Loader loading={loading} useBackdrop={false}></Loader>

    const items = [
        { label: 'Map', icon: 'pi pi-map-marker' },
        { label: 'List', icon: 'pi pi-fw pi-list' }
    ];

    return <>
        <Grid container justifyContent={"center"} style={{ marginTop: 17, marginBottom: 3 }}>
            <Grid item xs={10}>
                <Grid container rowSpacing={2}>
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <MultiSelect label="Service Category" selectedIds={filter.serviceCategory} optionName="serviceCategories" includeBlankOption={true} onChange={filterServiceCategory} scrollHeight="350px" />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <MultiSelect label="Service" selectedIds={filter.service} optionName="permitted-service-list" includeBlankOption={true} onChange={filterService} scrollHeight="350px" />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <MultiSelect label="Area" optionName="areas" selectedIds={filter.area} includeBlankOption={true} onChange={filterArea} scrollHeight="350px" />

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <div style={{ padding: '4px 1rem' }}>
                            <ActionButton variant="outlined" icon={`pi pi-angle-${searchExpanded ? 'up' : 'down'}`} label={searchExpanded ? 'Less' : 'More'} onClick={toggleSearch} />
                            <i className="" style={{ fontSize: '1.5rem' }} />
                        </div>
                    </Grid>

                    {searchExpanded && <>
                        <Grid item xs={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <MultiSelect label="Bed Type" selectedIds={filter.bedType} optionName="bed-type-list" includeBlankOption={true} onChange={filterBedType} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextBox label="Max Weekly Cost" type="number" value={filter.maxWeeklyCost} onChange={(e) => { setFilter({ ...filter, maxWeeklyCost: e.target.value ? parseInt(e.target.value ?? '0') : undefined }) }} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Dropdown label="View Mode" includeBlankOption={true} filter={false} options=
                                        {[{ code: '', name: '', id: 0 },
                                        { code: 'esc', name: 'Escalation', id: 1 },
                                          {code: 'bed', name: 'Bed Availability', id: 2 },
                                          {code: 'com', name: 'Combined', id: 3 },
                                          { code: 'opel', name: 'OPEL', id: 4 },
                                          { code: 'dnc', name: 'Demand and Capacity', id: 5 }]} 
                                          onChange={filterViewMode}
                                          value={filter.viewMode}/>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TickBox label="Services updated within the last 24 hours" onChange={filterUpdatedInLast24Hours} />

                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TickBox label="Services with availability only" onChange={handleAvailabilityOnlyChange} />

                                </Grid>
                               
                            </Grid>

                        </Grid>
                        <Grid item xs={3}>
                            <div style={{ padding: '0 1rem' }}>
                                <ActionButton label="Save Filter" severity="success" icon="pi pi-star" variant="outlined" onClick={handleSaveFilter} />
                                <div className="home-clear-btn">
                                    <ActionButton label="Clear Filter" severity="secondary" icon="pi pi-filter-slash" variant="outlined" onClick={handleClearFilter} />
                                </div>
                            </div>
                        </Grid>
                    </>
                    }

                    <Grid item xs={12} className={`home-tab-container ${!searchExpanded ? 'collapsed' : undefined}`}>
                        <Grid container justifyContent="flex-end" style={{ marginBottom: 0, marginTop: '-15px' }}>
                            <TabMenu model={items} onTabChange={(e) => handleToggleView(e.index)} activeIndex={activeIndex} />
                        </Grid>
                    </Grid>

                </Grid>


                <Grid container>
                    <Grid item xs={12}>
                        <div>
                            {activeIndex === 0 &&
                                <Map markers={mapMarkers} polygonLayer={[{ name: 'Shropshire', geoCodes: overlayCoOrdinatesShropshire }, { name: 'Liverpool', geoCodes: overlayCoOrdinatesMersey }
                                    , { name: 'Birmingham', geoCodes: overlayCoOrdinatesBham }
                                    , { name: 'Warwickshire', geoCodes: overlayCoOrdinatesBham }
                                    , { name: 'Black Country', geoCodes: overlayCoOrdinatesBlack }]} onServiceSelected={handleServiceSelected} id="map2"></Map>
                            }
                        </div>
                    </Grid>

                    {activeIndex === 1 &&
                        <Grid item xs={12}>
                            <ServiceListview serviceStatusList={serviceList} onServiceSelected={handleServiceSelected}></ServiceListview>
                        </Grid>
                    }
                    {/* <Grid item xs={12}><br></br></Grid> */}
                    <Grid item xs={12}>
                        {showServiceOverviewInpane &&
                            <ServiceFullDetails service={selectedService} onDismiss={handleHideOverviewInPane} dismissable={true}
                                onRequestFormSubmission={handleRequestFormSubmission}
                                onRequestServiceFeatures={handleRequestFeatures}
                                onRequestServiceAddress={handleRequestAddress}
                                onRequestServiceActionCard={handleRequestActionCard}
                                formSubmission={formSubmission}
                                serviceFeatures={serviceFeatures}
                                address={serviceAddress}
                                serviceUploads={serviceUploads}
                                onCapacityStatusDivisionSelected={handleCapacitySummaryDivisonSelected}
                                onRequestServiceUploads={handleRequestUploads}
                                serviceActionCard={serviceActionCard}
                                onCombinedUpdated={handleUpdateCombined}
                            />
                        }

                    </Grid>
                    <a id="overview"></a>
                </Grid>
            </Grid>
        </Grid>

        {/* <Splitter style={{height: '500px'}} className="mb-5">
                    <SplitterPanel className="flex align-items-center justify-content-center">
                        <Map markers={mapMarkers} polygonLayer={overlayCoOrdinatesMersey} id="map1"></Map>
                    </SplitterPanel>
                    <SplitterPanel className="flex align-items-center justify-content-center">
                        <Map markers={mapMarkers} polygonLayer={overlayCoOrdinatesShropshire}  id="map2"></Map>  
                    </SplitterPanel>
                </Splitter> */}

        <br />




        <Dialog headerStyle={{ height: 40 }} visible={selectedService !== undefined && showServiceOverviewDialog} onHide={handleHideDialog} style={{ width: '40vw' }} footer={renderFooter()} dismissableMask={true}>
            <>
                <ServiceOverview service={selectedService} onMoreInformationClicked={handleShowServiceDetails}  />
            </>
        </Dialog>


    </>;
}
const overlayCoOrdinatesWarick: any = [[52.3602963, -1.1717606],
[52.4388508, -1.2400818],
[52.4886342, -1.3018799],
[52.5126690, -1.3605881],
[52.5680036, -1.5212631],
[52.5702729, -1.5231514],
[52.5714727, -1.5221214],
[52.5735332, -1.5241385],
[52.5737158, -1.5255976],
[52.5765064, -1.5271854],
[52.5769498, -1.5259838],
[52.5783580, -1.5263271],
[52.5793751, -1.5264559],
[52.5809137, -1.5261555],
[52.5822697, -1.5279579],
[52.5828433, -1.5310478],
[52.5843557, -1.5325928],
[52.5847208, -1.5318632],
[52.5854508, -1.5320778],
[52.5887882, -1.5376997],
[52.5892315, -1.5389442],
[52.5890229, -1.5417337],
[52.5894400, -1.5449095],
[52.5925164, -1.5490294],
[52.5924122, -1.5501881],
[52.5927250, -1.5505314],
[52.5929596, -1.5514755],
[52.5934550, -1.5531063],
[52.5933767, -1.5539646],
[52.5939503, -1.5566254],
[52.5948366, -1.5571404],
[52.5954883, -1.5573978],
[52.5958794, -1.5586424],
[52.5960097, -1.5611744],
[52.5964007, -1.5609169],
[52.5984600, -1.5591574],
[52.6014315, -1.5574837],
[52.6089376, -1.5575266],
[52.6131071, -1.5547800],
[52.6144881, -1.5549088],
[52.6167290, -1.5609598],
[52.6189436, -1.5616465],
[52.6195429, -1.5616035],
[52.6198555, -1.5627623],
[52.6196211, -1.5636635],
[52.6200119, -1.5660667],
[52.6208977, -1.5676117],
[52.6222524, -1.5677404],
[52.6228777, -1.5680838],
[52.6292079, -1.5667963],
[52.6310833, -1.5649939],
[52.6334536, -1.5707874],
[52.6344694, -1.5715599],
[52.6359800, -1.5713882],
[52.6362404, -1.5705729],
[52.6371259, -1.5608740],
[52.6563939, -1.5470123],
[52.6722186, -1.5676117],
[52.6838753, -1.5895844],
[52.6663892, -1.6149902],
[52.6580600, -1.6383362],
[52.6438967, -1.6355896],
[52.6412927, -1.6352463],
[52.6315001, -1.6409111],
[52.6248316, -1.6376495],
[52.6209758, -1.6436577],
[52.6204548, -1.6366196],
[52.6095109, -1.6383362],
[52.6031518, -1.6429710],
[52.5990856, -1.6477776],
[52.5962704, -1.6527557],
[52.5950191, -1.6556740],
[52.5951234, -1.6644287],
[52.5920993, -1.6657162],
[52.5923079, -1.6696644],
[52.5930378, -1.6773033],
[52.5929336, -1.6803932],
[52.5934028, -1.6822815],
[52.5928293, -1.6852856],
[52.5891272, -1.6853714],
[52.5900658, -1.6957569],
[52.5906915, -1.6980743],
[52.5902743, -1.7003059],
[52.5904438, -1.7024946],
[52.5899615, -1.7026019],
[52.5894531, -1.7032027],
[52.5891663, -1.7037821],
[52.5887622, -1.7030740],
[52.5883450, -1.7033315],
[52.5880191, -1.7036319],
[52.5876150, -1.7037177],
[52.5874064, -1.7048550],
[52.5874325, -1.7057347],
[52.5867806, -1.7317200],
[52.5884493, -1.7396164],
[52.5924122, -1.7389297],
[52.5899093, -1.7677689],
[52.5909522, -1.7749786],
[52.5880321, -1.7828751],
[52.5874846, -1.7873383],
[52.5840167, -1.7872524],
[52.5806268, -1.7824459],
[52.5750981, -1.7772102],
[52.5748373, -1.7730474],
[52.5752807, -1.7710733],
[52.5748112, -1.7694855],
[52.5744722, -1.7681122],
[52.5736115, -1.7674255],
[52.5729073, -1.7675972],
[52.5713945, -1.7662668],
[52.5710293, -1.7639065],
[52.5700903, -1.7630053],
[52.5680819, -1.7665672],
[52.5669080, -1.7711163],
[52.5649255, -1.7733049],
[52.5617950, -1.7666960],
[52.5588730, -1.7671680],
[52.5567335, -1.7649364],
[52.5556638, -1.7596149],
[52.5558464, -1.7559671],
[52.5455128, -1.7532635],
[52.5416240, -1.7460537],
[52.5410758, -1.7482853],
[52.5365080, -1.7481136],
[52.5359337, -1.7505169],
[52.5314958, -1.7484140],
[52.5327228, -1.7429638],
[52.5320702, -1.7396593],
[52.5289112, -1.7388010],
[52.5276319, -1.7313766],
[52.5243943, -1.7285442],
[52.5232193, -1.7300463],
[52.5229059, -1.7322350],
[52.5232976, -1.7342949],
[52.5220704, -1.7369556],
[52.5205558, -1.7411613],
[52.5203992, -1.7431355],
[52.5201903, -1.7447662],
[52.5200858, -1.7463970],
[52.5202425, -1.7478561],
[52.5204514, -1.7504740],
[52.5209736, -1.7536068],
[52.5183623, -1.7543793],
[52.5180750, -1.7564821],
[52.5174744, -1.7562246],
[52.5158552, -1.7546368],
[52.5159336, -1.7538214],
[52.5138965, -1.7529631],
[52.5132175, -1.7528772],
[52.5114415, -1.7494440],
[52.5079676, -1.7432213],
[52.4992686, -1.7375565],
[52.4900453, -1.7307329],
[52.4834859, -1.7228365],
[52.4784677, -1.7143393],
[52.4751742, -1.7112064],
[52.4740501, -1.7108202],
[52.4736319, -1.7103052],
[52.4665471, -1.7092323],
[52.4655536, -1.7081594],
[52.4390601, -1.6829681],
[52.4352927, -1.6668320],
[52.4434550, -1.6623688],
[52.4413622, -1.6496658],
[52.4593566, -1.6242599],
[52.4629128, -1.6242599],
[52.4633312, -1.6143036],
[52.4541265, -1.6002274],
[52.4564278, -1.5957642],
[52.4618669, -1.5923309],
[52.4378043, -1.5950775],
[52.4319436, -1.6070938],
[52.4267102, -1.6105270],
[52.4193823, -1.6067505],
[52.3955060, -1.6040039],
[52.3883825, -1.6002274],
[52.3804195, -1.6077805],
[52.3722456, -1.6101837],
[52.3720360, -1.6146469],
[52.3615542, -1.6287231],
[52.3571512, -1.6489792],
[52.3634411, -1.6630554],
[52.3586189, -1.6788483],
[52.3540059, -1.6922379],
[52.3563125, -1.7121506],
[52.3621832, -1.7200470],
[52.3716167, -1.7203903],
[52.3552640, -1.7461395],
[52.3636508, -1.7660522],
[52.3644894, -1.7790985],
[52.3565221, -1.7832184],
[52.3565221, -1.7911148],
[52.3665858, -1.8137741],
[52.3663761, -1.8587494],
[52.3657472, -1.8663025],
[52.3602963, -1.8886185],
[52.3378564, -1.8728256],
[52.3177137, -1.8666458],
[52.3105777, -1.8796921],
[52.2973519, -1.8798637],
[52.2825470, -1.8952274],
[52.2833347, -1.8998623],
[52.2803941, -1.9030380],
[52.2751952, -1.9145393],
[52.2757729, -1.9260406],
[52.2768758, -1.9346237],
[52.2686301, -1.9379711],
[52.2585967, -1.9346237],
[52.2423073, -1.9261265],
[52.2250129, -1.9177151],
[52.2102891, -1.9197750],
[52.2084482, -1.9230366],
[52.2007159, -1.9359970],
[52.1916669, -1.9349670],
[52.1742475, -1.9446659],
[52.1737738, -1.9586563],
[52.1704575, -1.9618320],
[52.1676674, -1.9472408],
[52.1705628, -1.9431210],
[52.1693520, -1.9355679],
[52.1650351, -1.9343662],
[52.1553469, -1.9442368],
[52.1527666, -1.9215775],
[52.1466573, -1.9167709],
[52.1419695, -1.9044113],
[52.1333827, -1.9034672],
[52.1379134, -1.8929100],
[52.1442871, -1.8874168],
[52.1469207, -1.8853569],
[52.1482374, -1.8862152],
[52.1482374, -1.8924809],
[52.1502387, -1.8951416],
[52.1557682, -1.8877602],
[52.1552943, -1.8820095],
[52.1550310, -1.8780613],
[52.1545044, -1.8755722],
[52.1533985, -1.8705082],
[52.1528192, -1.8671608],
[52.1504494, -1.8660450],
[52.1490800, -1.8662167],
[52.1463413, -1.8631268],
[52.1443925, -1.8665600],
[52.1410213, -1.8606377],
[52.1396518, -1.8575478],
[52.1387035, -1.8517971],
[52.1428650, -1.8455315],
[52.1470260, -1.8469906],
[52.1463413, -1.8511963],
[52.1478160, -1.8519688],
[52.1484480, -1.8469906],
[52.1497647, -1.8480206],
[52.1522399, -1.8393517],
[52.1478687, -1.8313694],
[52.1457619, -1.8233013],
[52.1410740, -1.8273354],
[52.1361749, -1.8230438],
[52.1395464, -1.8093109],
[52.1318548, -1.7856216],
[52.1241091, -1.7951488],
[52.1196825, -1.7888832],
[52.1198406, -1.7805576],
[52.1176798, -1.7748928],
[52.1152553, -1.7688847],
[52.1154134, -1.7645931],
[52.0942732, -1.7495728],
[52.0702213, -1.6987610],
[52.0402437, -1.6822815],
[52.0343302, -1.6630554],
[52.0330630, -1.6218567],
[52.0284160, -1.6225433],
[51.9929142, -1.6651154],
[51.9556916, -1.6101837],
[51.9658463, -1.5937042],
[51.9789594, -1.5490723],
[51.9916458, -1.5415192],
[51.9933370, -1.5222931],
[52.0482678, -1.4996338],
[52.0723316, -1.5003204],
[52.0934295, -1.4804077],
[52.0946951, -1.4474487],
[52.1098789, -1.4508820],
[52.1149391, -1.4165497],
[52.0930076, -1.3829041],
[52.0968042, -1.3609314],
[52.1162040, -1.3815308],
[52.1246361, -1.3863373],
[52.1385455, -1.3410187],
[52.1608758, -1.3327789],
[52.1895095, -1.3039398],
[52.1979277, -1.2558746],
[52.2177042, -1.2572479],
[52.2353693, -1.2771606],
[52.2374719, -1.2654877],
[52.2635349, -1.2174225],
[52.2887426, -1.2297821],
[52.3139360, -1.2112427],
[52.3273666, -1.2613678],
[52.3395345, -1.2572479],
[52.3435199, -1.2352753],
[52.3512797, -1.1971664],
[52.3540059, -1.1793137],
[-1.1717606, 52.3605059]]
const overlayCoOrdinatesBlack: any = [[52.6209237, -2.1624184],
[52.6206111, -2.1622467],
[52.6191521, -2.1626759],
[52.6199858, -2.1671391],
[52.6193084, -2.1677399],
[52.6159733, -2.1639633],
[52.6111787, -2.1691132],
[52.6088855, -2.1701431],
[52.6065921, -2.1766663],
[52.6057581, -2.1821594],
[52.6076345, -2.1914291],
[52.6063836, -2.1965790],
[52.6032560, -2.1938324],
[52.5972088, -2.1869659],
[52.5919950, -2.1869659],
[52.5888665, -2.1907425],
[52.5892836, -2.1982956],
[52.5888665, -2.2013855],
[52.5871978, -2.2048187],
[52.5861549, -2.2068787],
[52.5838603, -2.2010422],
[52.5826086, -2.2024155],
[52.5780190, -2.2037888],
[52.5769758, -2.1948624],
[52.5757240, -2.1838760],
[52.5742635, -2.1804428],
[52.5709250, -2.1770096],
[52.5675862, -2.1770096],
[52.5661255, -2.1807861],
[52.5638298, -2.1763229],
[52.5640385, -2.1749496],
[52.5596557, -2.1746063],
[52.5554811, -2.1759796],
[52.5550636, -2.1718597],
[52.5525587, -2.1687698],
[52.5550636, -2.1660233],
[52.5540199, -2.1584702],
[52.5550636, -2.1461105],
[52.5581946, -2.1395874],
[52.5527674, -2.1330643],
[52.5473396, -2.1358109],
[52.5435815, -2.1337509],
[52.5356465, -2.1354675],
[52.5320963, -2.1395874],
[52.5256215, -2.1498871],
[52.5224882, -2.1450806],
[52.5155941, -2.1457672],
[52.5139226, -2.1536636],
[52.5132958, -2.1646500],
[52.5112064, -2.1759796],
[52.5076541, -2.1849060],
[52.5049375, -2.1876526],
[52.5032656, -2.1917725],
[52.4986677, -2.1838760],
[52.4949054, -2.1876526],
[52.4940693, -2.1831894],
[52.4900975, -2.1794128],
[52.4827802, -2.1773529],
[52.4748343, -2.1722031],
[52.4706517, -2.1667099],
[52.4639587, -2.1718597],
[52.4522434, -2.1749496],
[52.4292223, -2.1646500],
[52.4266578, -2.1475160],
[52.4260625, -2.1440613],
[52.4261737, -2.1432030],
[52.4261933, -2.1406925],
[52.4261671, -2.1386862],
[52.4261214, -2.1380746],
[52.4263503, -2.1361434],
[52.4280644, -2.1354461],
[52.4280447, -2.1350169],
[52.4276784, -2.1331930],
[52.4286204, -2.1329999],
[52.4299222, -2.1322918],
[52.4298699, -2.1308005],
[52.4298765, -2.1292341],
[52.4297587, -2.1297276],
[52.4298765, -2.1292663],
[52.4301185, -2.1293950],
[52.4306876, -2.1296740],
[52.4308315, -2.1290731],
[52.4311390, -2.1291268],
[52.4314334, -2.1293199],
[52.4319894, -2.1295989],
[52.4322314, -2.1289444],
[52.4322314, -2.1282578],
[52.4325977, -2.1276140],
[52.4328332, -2.1264982],
[52.4330949, -2.1253824],
[52.4333303, -2.1243954],
[52.4333042, -2.1234083],
[52.4335658, -2.1225500],
[52.4347694, -2.1198034],
[52.4347432, -2.1189451],
[52.4343508, -2.1180010],
[52.4345601, -2.1171856],
[52.4349264, -2.1161985],
[52.4354758, -2.1153831],
[52.4360252, -2.1146536],
[52.4371241, -2.1133232],
[52.4375950, -2.1128082],
[52.4376212, -2.1118641],
[52.4404466, -2.1098042],
[52.4412314, -2.1090746],
[52.4420424, -2.1078730],
[52.4432457, -2.1061563],
[52.4441874, -2.1052551],
[52.4440828, -2.1042252],
[52.4443705, -2.1011782],
[52.4453122, -2.0982170],
[52.4449721, -2.0954275],
[52.4453645, -2.0933676],
[52.4451029, -2.0924664],
[52.4438473, -2.0937967],
[52.4426179, -2.0897198],
[52.4413884, -2.0899773],
[52.4395310, -2.0881748],
[52.4373857, -2.0871019],
[52.4367840, -2.0869303],
[52.4360776, -2.0871878],
[52.4355020, -2.0872736],
[52.4349787, -2.0870590],
[52.4346647, -2.0865870],
[52.4343246, -2.0864153],
[52.4339060, -2.0861578],
[52.4334350, -2.0852566],
[52.4330164, -2.0855570],
[52.4324669, -2.0847416],
[52.4322837, -2.0836258],
[52.4325716, -2.0826817],
[52.4319436, -2.0819950],
[52.4324407, -2.0797205],
[52.4339321, -2.0779610],
[52.4339060, -2.0745707],
[52.4328594, -2.0744419],
[52.4339845, -2.0733261],
[52.4339845, -2.0726395],
[52.4339845, -2.0718241],
[52.4340368, -2.0705795],
[52.4342461, -2.0699787],
[52.4344816, -2.0697641],
[52.4346909, -2.0692921],
[52.4346124, -2.0687771],
[52.4347955, -2.0686913],
[52.4351618, -2.0684767],
[52.4353973, -2.0679188],
[52.4351880, -2.0673180],
[52.4345862, -2.0673180],
[52.4341938, -2.0674896],
[52.4336182, -2.0675325],
[52.4328594, -2.0674467],
[52.4326239, -2.0664597],
[52.4326762, -2.0619535],
[52.4322052, -2.0621252],
[52.4317081, -2.0623398],
[52.4310539, -2.0630693],
[52.4303736, -2.0637989],
[52.4300073, -2.0651722],
[52.4295101, -2.0661163],
[52.4291699, -2.0658159],
[52.4281756, -2.0650434],
[52.4275475, -2.0611811],
[52.4285419, -2.0601082],
[52.4304521, -2.0594215],
[52.4323361, -2.0595074],
[52.4335397, -2.0591640],
[52.4342461, -2.0592499],
[52.4351880, -2.0595503],
[52.4358683, -2.0598507],
[52.4364700, -2.0603657],
[52.4389293, -2.0598078],
[52.4404989, -2.0590353],
[52.4414407, -2.0557308],
[52.4411268, -2.0541430],
[52.4408652, -2.0522118],
[52.4399495, -2.0492506],
[52.4406559, -2.0469761],
[52.4403158, -2.0458603],
[52.4405251, -2.0442295],
[52.4412052, -2.0403671],
[52.4408652, -2.0398092],
[52.4414407, -2.0398092],
[52.4417546, -2.0377922],
[52.4414668, -2.0374918],
[52.4411529, -2.0371056],
[52.4408390, -2.0366335],
[52.4401065, -2.0368052],
[52.4392170, -2.0370626],
[52.4388769, -2.0380926],
[52.4383014, -2.0380068],
[52.4378305, -2.0391226],
[52.4373595, -2.0391655],
[52.4361299, -2.0388651],
[52.4350834, -2.0383501],
[52.4342461, -2.0380497],
[52.4334088, -2.0376205],
[52.4326762, -2.0373201],
[52.4318128, -2.0372343],
[52.4312894, -2.0365906],
[52.4305830, -2.0366335],
[52.4299026, -2.0373631],
[52.4295625, -2.0370626],
[52.4289868, -2.0375776],
[52.4283849, -2.0373631],
[52.4276522, -2.0366335],
[52.4273905, -2.0300245],
[52.4290914, -2.0290804],
[52.4302428, -2.0263338],
[52.4319959, -2.0262909],
[52.4325192, -2.0243597],
[52.4330425, -2.0239735],
[52.4337228, -2.0250893],
[52.4350310, -2.0248318],
[52.4346124, -2.0229435],
[52.4332715, -2.0185876],
[52.4328201, -2.0171070],
[52.4360612, -2.0172465],
[52.4381248, -2.0172787],
[52.4391287, -2.0169461],
[52.4393609, -2.0172358],
[52.4405872, -2.0170373],
[52.4411889, -2.0173001],
[52.4431280, -2.0174557],
[52.4464402, -2.0164526],
[52.4465187, -2.0165437],
[52.4468032, -2.0164847],
[52.4476827, -2.0151973],
[52.4483137, -2.0132285],
[52.4484870, -2.0135236],
[52.4490493, -2.0133305],
[52.4491997, -2.0134431],
[52.4493632, -2.0132768],
[52.4496934, -2.0135075],
[52.4531882, -2.0117909],
[52.4555550, -2.0114583],
[52.4575392, -2.0118713],
[52.4601411, -2.0128047],
[52.4602392, -2.0130408],
[52.4608079, -2.0131642],
[52.4621578, -2.0132875],
[52.4620238, -2.0071614],
[52.4626121, -2.0045435],
[52.4633312, -2.0027947],
[52.4633965, -2.0019042],
[52.4631089, -1.9976342],
[52.4641613, -1.9938254],
[52.4648542, -1.9900167],
[52.4653771, -1.9881499],
[52.4657758, -1.9856393],
[52.4662987, -1.9820452],
[52.4668151, -1.9792771],
[52.4693446, -1.9740093],
[52.4719065, -1.9693530],
[52.4732659, -1.9658768],
[52.4735665, -1.9658983],
[52.4737952, -1.9668210],
[52.4741678, -1.9672179],
[52.4752330, -1.9655764],
[52.4762067, -1.9675934],
[52.4767491, -1.9691169],
[52.4776770, -1.9685805],
[52.4778273, -1.9687736],
[52.4788859, -1.9671857],
[52.4807220, -1.9656622],
[52.4819569, -1.9641066],
[52.4827280, -1.9618642],
[52.4828848, -1.9603300],
[52.4828194, -1.9583988],
[52.4823686, -1.9562316],
[52.4833748, -1.9524658],
[52.4829240, -1.9514251],
[52.4830612, -1.9513822],
[52.4832833, -1.9508350],
[52.4851519, -1.9496548],
[52.4854264, -1.9508028],
[52.4871381, -1.9497192],
[52.4870728, -1.9484961],
[52.4876346, -1.9480133],
[52.4875889, -1.9471872],
[52.4902935, -1.9459105],
[52.4899930, -1.9440329],
[52.4907835, -1.9435930],
[52.4910186, -1.9432819],
[52.4911885, -1.9435072],
[52.4915543, -1.9430566],
[52.4930960, -1.9457603],
[52.4932397, -1.9458139],
[52.4936316, -1.9452667],
[52.4943306, -1.9440222],
[52.4950556, -1.9442904],
[52.4957546, -1.9443226],
[52.4974594, -1.9417155],
[52.4980015, -1.9403207],
[52.4983019, -1.9383037],
[52.4993535, -1.9426060],
[52.4995364, -1.9426060],
[52.4993731, -1.9427669],
[52.4992751, -1.9430351],
[52.4994253, -1.9434965],
[52.4998434, -1.9455349],
[52.5011104, -1.9504917],
[52.5013194, -1.9507921],
[52.5015545, -1.9525194],
[52.5014566, -1.9555879],
[52.5025472, -1.9556201],
[52.5048330, -1.9631624],
[52.5065570, -1.9613814],
[52.5075496, -1.9621646],
[52.5081047, -1.9615746],
[52.5082157, -1.9623148],
[52.5098482, -1.9621432],
[52.5097437, -1.9601154],
[52.5140532, -1.9630337],
[52.5158291, -1.9627762],
[52.5191718, -1.9589567],
[52.5226448, -1.9598579],
[52.5281541, -1.9625187],
[52.5313653, -1.9605875],
[52.5313131, -1.9580126],
[52.5298250, -1.9578838],
[52.5297466, -1.9514465],
[52.5326445, -1.9479597],
[52.5329773, -1.9474232],
[52.5332710, -1.9476163],
[52.5334342, -1.9475305],
[52.5338127, -1.9464898],
[52.5337148, -1.9453418],
[52.5338388, -1.9427347],
[52.5331470, -1.9395268],
[52.5327815, -1.9393873],
[52.5320245, -1.9371450],
[52.5327750, -1.9336689],
[52.5325401, -1.9327033],
[52.5326510, -1.9323814],
[52.5314828, -1.9296777],
[52.5330491, -1.9303966],
[52.5359859, -1.9301391],
[52.5428637, -1.9294095],
[52.5457346, -1.9329286],
[52.5457607, -1.9288731],
[52.5459956, -1.9207621],
[52.5470786, -1.9181657],
[52.5504189, -1.9136810],
[52.5531328, -1.9082308],
[52.5553115, -1.8992400],
[52.5582207, -1.8969870],
[52.5624602, -1.8914938],
[52.5655124, -1.8858075],
[52.5695035, -1.8786836],
[52.5752155, -1.8773317],
[52.5805486, -1.8758297],
[52.5842775, -1.8730187],
[52.5848251, -1.8735123],
[52.5874716, -1.8782759],
[52.5941458, -1.8844128],
[52.5979257, -1.8909359],
[52.5983688, -1.8906140],
[52.5989423, -1.8922663],
[52.5995418, -1.8925238],
[52.6025132, -1.8904209],
[52.6038555, -1.8896914],
[52.6060839, -1.8881249],
[52.6104882, -1.8854427],
[52.6136543, -1.8858290],
[52.6179015, -1.8896270],
[52.6249618, -1.8989611],
[52.6275799, -1.9047117],
[52.6352377, -1.9161487],
[52.6363446, -1.9144106],
[52.6360581, -1.9133806],
[52.6366831, -1.9136810],
[52.6378291, -1.9125652],
[52.6400687, -1.9084024],
[52.6430374, -1.9055271],
[52.6440529, -1.9084024],
[52.6438446, -1.9098186],
[52.6498464, -1.9182086],
[52.6513044, -1.9162130],
[52.6522937, -1.9168353],
[52.6542072, -1.9092178],
[52.6579558, -1.9131017],
[52.6553136, -1.9237661],
[52.6558082, -1.9260406],
[52.6585546, -1.9290447],
[52.6606760, -1.9330788],
[52.6602986, -1.9337654],
[52.6587368, -1.9359112],
[52.6565501, -1.9384861],
[52.6557171, -1.9401169],
[52.6555609, -1.9448376],
[52.6587368, -1.9497299],
[52.6616001, -1.9542789],
[52.6614439, -1.9583130],
[52.6529055, -1.9548798],
[52.6525410, -1.9598579],
[52.6491043, -1.9588280],
[52.6443654, -1.9587421],
[52.6423343, -1.9599438],
[52.6427510, -1.9621754],
[52.6404073, -1.9635487],
[52.6402510, -1.9660378],
[52.6406677, -1.9690418],
[52.6395218, -1.9743633],
[52.6391572, -1.9801998],
[52.6399906, -1.9827747],
[52.6393656, -1.9866371],
[52.6360320, -1.9905853],
[52.6371259, -1.9941902],
[52.6339484, -2.0091248],
[52.6340526, -2.0097685],
[52.6328285, -2.0140171],
[52.6295205, -2.0163345],
[52.6283743, -2.0183516],
[52.6283743, -2.0192957],
[52.6249097, -2.0240164],
[52.6247795, -2.0257330],
[52.6249618, -2.0282221],
[52.6248837, -2.0293808],
[52.6237114, -2.0302391],
[52.6217053, -2.0347452],
[52.6196732, -2.0315695],
[52.6185268, -2.0311403],
[52.6167290, -2.0295095],
[52.6164423, -2.0313549],
[52.6208195, -2.0469761],
[52.6198816, -2.0504951],
[52.6216532, -2.0523834],
[52.6202463, -2.0623398],
[52.6176930, -2.0680046],
[52.6116478, -2.0676613],
[52.6113351, -2.0800209],
[52.6123774, -2.0835400],
[52.6193084, -2.0883465],
[52.6196211, -2.0926380],
[52.6210801, -2.0956421],
[52.6267072, -2.0984745],
[52.6291037, -2.0988178],
[52.6324117, -2.0991611],
[52.6347298, -2.1016073],
[52.6348079, -2.1025515],
[52.6348340, -2.1055555],
[52.6343131, -2.1067572],
[52.6343847, -2.1092677],
[52.6351986, -2.1102977],
[52.6349056, -2.1113920],
[52.6377705, -2.1138918],
[52.6376923, -2.1159625],
[52.6373342, -2.1170461],
[52.6375816, -2.1182048],
[52.6373473, -2.1225500],
[52.6372236, -2.1252966],
[52.6371454, -2.1261120],
[52.6374189, -2.1270990],
[52.6375100, -2.1314657],
[52.6361688, -2.1312940],
[52.6339159, -2.1329463],
[52.6314936, -2.1355855],
[52.6295465, -2.1372485],
[52.6297289, -2.1414971],
[52.6296442, -2.1528590],
[52.6272086, -2.1523011],
[52.6244734, -2.1514213],
[52.6239133, -2.1545756],
[52.6227474, -2.1574402],
[52.6209237, -2.1624184]]

const overlayCoOrdinatesMersey: any = [[53.4398095, -3.0483627],
[53.3880380, -3.1987381],
[53.3708351, -3.1966782],
[53.3329232, -3.1362534],
[53.2736313, -3.0617523],
[53.2522742, -3.0459595],
[53.2372768, -3.0075073],
[53.1973952, -2.9340363],
[53.1710613, -2.9257965],
[53.1537708, -2.9903412],
[53.1405925, -2.9690552],
[53.1142236, -2.9209900],
[53.1183448, -2.8818512],
[53.0886634, -2.9024506],
[53.0432772, -2.8612518],
[52.9916439, -2.8186798],
[52.9937104, -2.7843475],
[52.9825500, -2.7225494],
[52.9912306, -2.7060699],
[52.9986696, -2.6930237],
[52.9916439, -2.6689911],
[52.9974299, -2.6360321],
[52.9904039, -2.6133728],
[52.9746947, -2.5865936],
[52.9614615, -2.5845337],
[52.9664244, -2.5687408],
[52.9643566, -2.5515747],
[52.9668380, -2.5687408],
[52.9564979, -2.5405884],
[52.9606342, -2.5323486],
[52.9759351, -2.5261688],
[52.9775889, -2.5103760],
[52.9697327, -2.5007629],
[52.9693192, -2.4829102],
[52.9647702, -2.4657440],
[52.9722138, -2.4410248],
[52.9846170, -2.4472046],
[52.9904039, -2.4341583],
[52.9912306, -2.4176788],
[53.0007358, -2.4046326],
[53.0019755, -2.3895264],
[53.0114784, -2.3881531],
[53.0213922, -2.3847198],
[53.0280002, -2.3936462],
[53.0399745, -2.3922729],
[53.0552473, -2.3867798],
[53.0564854, -2.3661804],
[53.0606121, -2.3545074],
[53.0672141, -2.3462677],
[53.0783525, -2.3311615],
[53.0837145, -2.3133087],
[53.0828896, -2.2892761],
[53.0828896, -2.2813797],
[53.0894882, -2.2793198],
[53.0880448, -2.2717667],
[53.0899005, -2.2638702],
[53.0936117, -2.2556305],
[53.0956734, -2.2491074],
[53.1051556, -2.2329712],
[53.1191690, -2.2120285],
[53.1370913, -2.1945190],
[53.1523296, -2.1687698],
[53.1603585, -2.1581268],
[53.1615936, -2.1457672],
[53.1716787, -2.1485138],
[53.1784693, -2.1481705],
[53.1852589, -2.1443939],
[53.1858761, -2.1306610],
[53.1801154, -2.1258545],
[53.1760001, -2.1207047],
[53.1762059, -2.1138382],
[53.1710613, -2.1083450],
[53.1747655, -2.0952988],
[53.1718845, -2.0836258],
[53.1751770, -2.0764160],
[53.1768232, -2.0671463],
[53.1832016, -2.0585632],
[53.1906076, -2.0523834],
[53.1953385, -2.0458603],
[53.1943101, -2.0338440],
[53.1920475, -2.0177078],
[53.1938987, -2.0050049],
[53.2004802, -1.9981384],
[53.2111730, -1.9923019],
[53.2239186, -1.9950485],
[53.2278237, -1.9844055],
[53.2294679, -1.9785690],
[53.2364549, -1.9840622],
[53.2420026, -2.0012283],
[53.2459061, -2.0036316],
[53.2541228, -2.0050049],
[53.2598736, -2.0132446],
[53.2711675, -2.0129013],
[53.2851268, -2.0125580],
[53.2947724, -2.0115280],
[53.3087240, -2.0111847],
[53.3183643, -2.0067215],
[53.3247216, -2.0153046],
[53.3394834, -2.0180511],
[53.3448127, -2.0074081],
[53.3540351, -2.0159912],
[53.3638700, -2.0365906],
[53.3552646, -2.0647430],
[53.3548547, -2.0843124],
[53.3624359, -2.0963287],
[53.3587479, -2.1169281],
[53.3626408, -2.1351242],
[53.3523957, -2.1351242],
[53.3470673, -2.1464539],
[53.3407133, -2.1378708],
[53.3251318, -2.1505737],
[53.3257469, -2.1691132],
[53.3349734, -2.1958923],
[53.3409183, -2.1972656],
[53.3437879, -2.1897125],
[53.3505513, -2.1952057],
[53.3526006, -2.2034454],
[53.3528055, -2.2147751],
[53.3550597, -2.2243881],
[53.3554695, -2.2298813],
[53.3571087, -2.2443008],
[53.3569038, -2.2525406],
[53.3517809, -2.2587204],
[53.3493217, -2.2669601],
[53.3446078, -2.2738266],
[53.3380484, -2.2841263],
[53.3392784, -2.2909927],
[53.3380484, -2.2995758],
[53.3390734, -2.3040390],
[53.3437879, -2.3019791],
[53.3466574, -2.3019791],
[53.3507563, -2.3074722],
[53.3532154, -2.3153687],
[53.3573136, -2.3181152],
[53.3564940, -2.3253250],
[53.3595675, -2.3345947],
[53.3632554, -2.3345947],
[53.3640749, -2.3421478],
[53.3636652, -2.3510742],
[53.3642798, -2.3555374],
[53.3610017, -2.3606873],
[53.3603871, -2.3709869],
[53.3644846, -2.3833466],
[53.3671479, -2.3919296],
[53.3728834, -2.4015427],
[53.3777990, -2.4101257],
[53.3827140, -2.4193954],
[53.3857856, -2.4372482],
[53.3892665, -2.4612808],
[53.3902902, -2.4753571],
[53.3955109, -2.4813652],
[53.3979675, -2.4751854],
[53.4042106, -2.4647141],
[53.4129085, -2.4587059],
[53.4158756, -2.4526978],
[53.4203770, -2.4516678],
[53.4240596, -2.4532127],
[53.4298898, -2.4583626],
[53.4357192, -2.4643707],
[53.4360260, -2.4688339],
[53.4403208, -2.4784470],
[53.4434905, -2.4815369],
[53.4467622, -2.4799919],
[53.4553491, -2.4877167],
[53.4625036, -2.4952698],
[53.4698613, -2.4942398],
[53.4757873, -2.4901199],
[53.4778306, -2.5024796],
[53.4661826, -2.5498581],
[53.4600508, -2.5471115],
[53.4561669, -2.5848770],
[53.4549403, -2.5972366],
[53.4590287, -2.6030731],
[53.4608684, -2.6126862],
[53.4657738, -2.6164627],
[53.4706787, -2.6154327],
[53.4749700, -2.6116562],
[53.4800781, -2.6154327],
[53.4792609, -2.6319122],
[53.4788522, -2.6470184],
[53.4837556, -2.6549149],
[53.4890669, -2.6590347],
[53.4935606, -2.6669312],
[53.4968284, -2.6731110],
[53.4980538, -2.6902771],
[53.4996875, -2.6995468],
[53.5031591, -2.7139664],
[53.5111222, -2.7112198],
[53.5092847, -2.7228928],
[53.5137762, -2.7287292],
[53.5168384, -2.7328491],
[53.5145928, -2.7438354],
[53.5117347, -2.7589417],
[53.5115305, -2.7685547],
[53.5160218, -2.7723312],
[53.5284725, -2.7816010],
[53.5211250, -2.7894974],
[53.5192879, -2.7963638],
[53.5143887, -2.8076935],
[53.5115305, -2.8128433],
[53.5070387, -2.8166199],
[53.5021381, -2.8183365],
[53.4964199, -2.8183365],
[53.4913138, -2.8183365],
[53.4837556, -2.8238297],
[53.4802824, -2.8286362],
[53.4815083, -2.8458023],
[53.4878413, -2.8475189],
[53.4900882, -2.8536987],
[53.4882498, -2.8598785],
[53.4929478, -2.8701782],
[53.5005044, -2.8811646],
[53.5066304, -2.8921509],
[53.5115305, -2.8935242],
[53.5186755, -2.8852844],
[53.5205126, -2.8924942],
[53.5196962, -2.8983307],
[53.5196962, -2.9062271],
[53.5219414, -2.9182434],
[53.5247989, -2.9240799],
[53.5339822, -2.9354095],
[53.5388792, -2.9415894],
[53.5419395, -2.9494858],
[53.5439796, -2.9542923],
[53.5447956, -2.9628754],
[53.5468356, -2.9673386],
[53.5462236, -2.9728317],
[53.5425516, -2.9738617],
[53.5372470, -2.9707718],
[53.5241866, -2.9615021],
[53.5174508, -2.9615021],
[53.5162260, -2.9683685],
[53.5145928, -2.9714584],
[53.5194920, -2.9851913],
[53.5215332, -2.9937744],
[53.5270439, -3.0037308],
[53.5307173, -3.0140305],
[53.5333701, -3.0198669],
[53.5358187, -3.0287933],
[53.5356146, -3.0353165],
[53.5376550, -3.0418396],
[53.5411235, -3.0466461],
[53.5443876, -3.0473328],
[53.5511192, -3.0421829],
[53.5584615, -3.0425262],
[53.5637634, -3.0432129],
[53.5672297, -3.0377197],
[53.5696764, -3.0308533],
[53.5706958, -3.0257034],
[53.5814998, -3.0222702],
[53.5841494, -3.0363464],
[53.5884292, -3.0370331],
[53.5973950, -3.0329132],
[53.6035070, -3.0284500],
[53.6100254, -3.0181503],
[53.6145063, -3.0119705],
[53.6195976, -3.0068207],
[53.6261135, -3.0002975],
[53.6285568, -2.9961777],
[53.6283532, -2.9899979],
[53.6234666, -2.9831314],
[53.6450449, -2.9580688],
[53.6558299, -2.9494858],
[53.6570506, -2.9443359],
[53.6623402, -2.9457092],
[53.6680360, -2.9498291],
[53.6761715, -2.9484558],
[53.6798319, -2.9532623],
[53.6867452, -2.9532623],
[53.6979261, -2.9625320],
[53.7003651, -2.9687119],
[53.6489115, -3.0270767],
[53.6004511, -3.0754852],
[53.5498953, -3.1056976],
[53.5427556, -3.1015778],
[53.5223496, -3.0696487],
[53.5043843, -3.0658722],
[53.4708831, -3.0421829],
[53.4631168, -3.0438995],
[53.4396050, -3.0095673],
[53.4095319, -3.0013275],
[53.3786182, -2.9769516],
[53.3583382, -2.9309464],
[53.3308730, -2.8746414],
[53.3265671, -2.8348160],
[53.3300529, -2.8207397],
[53.3224659, -2.7984238],
[53.3216456, -2.7816010],
[53.3314881, -2.7761078],
[53.3450177, -2.7644348],
[53.3487069, -2.7531052],
[53.3487069, -2.7400589],
[53.3431730, -2.7311325],
[53.3427631, -2.7489853],
[53.3366134, -2.7599716],
[53.3288226, -2.7634048],
[53.3195948, -2.7630615],
[53.3089291, -2.7613449],
[53.2980556, -2.7833176],
[53.2894368, -2.8423691],
[53.2888211, -2.8701782],
[53.3005178, -2.9069138],
[53.3113907, -2.9275131],
[53.3675576, -2.9875946],
[53.3921329, -3.0020142],
[53.4342875, -3.0277634],
[53.4398095, -3.0480194]];

const overlayCoOrdinatesShropshire: any = [[52.9846170, -2.6806641],
[52.9924705, -2.6968002],
[52.9821366, -2.7249527],
[52.9674583, -2.7328491],
[52.9564979, -2.7222061],
[52.9269119, -2.7252960],
[52.9223584, -2.7513885],
[52.8946131, -2.8011703],
[52.9378797, -2.8403091],
[52.9486379, -2.8876877],
[52.9403626, -2.9230499],
[52.9583593, -2.9711151],
[52.9674583, -2.9724884],
[52.9567048, -3.0027008],
[52.9281536, -3.0507660],
[52.9258770, -3.0699921],
[52.9287745, -3.0967712],
[52.8962700, -3.1153107],
[52.8904705, -3.1451797],
[52.8846701, -3.1352234],
[52.8778330, -3.1523895],
[52.8660210, -3.1252670],
[52.8465344, -3.1616592],
[52.8417651, -3.1496429],
[52.8185331, -3.1671524],
[52.8035916, -3.1637192],
[52.8067049, -3.1558228],
[52.8017236, -3.1520462],
[52.7977797, -3.1602859],
[52.7907213, -3.1517029],
[52.7857382, -3.1214905],
[52.7900984, -3.1091309],
[52.7936278, -3.0940247],
[52.7959114, -3.0878448],
[52.7930050, -3.0820084],
[52.7867764, -3.0857849],
[52.7815852, -3.0861282],
[52.7666311, -3.0167770],
[52.7579055, -3.0092239],
[52.7516718, -3.0085373],
[52.7460608, -2.9975510],
[52.7414883, -2.9851913],
[52.7362917, -2.9899979],
[52.7354601, -2.9786682],
[52.7369153, -2.9704285],
[52.7315102, -2.9611588],
[52.7252728, -2.9594421],
[52.7152911, -2.9577255],
[52.7119633, -2.9635620],
[52.7148751, -2.9824448],
[52.7223617, -2.9790115],
[52.7171628, -3.0064774],
[52.7167469, -3.0212402],
[52.7059311, -3.0212402],
[52.6961530, -3.0284500],
[52.6897024, -3.0414963],
[52.6705532, -3.0394363],
[52.6524369, -3.0456161],
[52.6451466, -3.0514526],
[52.6401468, -3.0778885],
[52.6299373, -3.0576324],
[52.6163902, -3.0796051],
[52.6003368, -3.0888748],
[52.5940806, -3.0943680],
[52.5834431, -3.1335068],
[52.5834431, -3.1335068],
[52.5408670, -3.1122208],
[52.5320963, -3.1348801],
[52.5260392, -3.1293869],
[52.5356465, -3.0823517],
[52.5442078, -3.0933380],
[52.5508887, -3.0820084],
[52.5600731, -3.0500793],
[52.5748894, -3.0143738],
[52.5684210, -3.0020142],
[52.5571510, -2.9965210],
[52.5333493, -2.9996109],
[52.5189368, -3.0030441],
[52.5224882, -3.0305099],
[52.5015937, -3.0318832],
[52.4967866, -3.1118774],
[52.4729522, -3.1795120],
[52.4744161, -3.1935883],
[52.4484772, -3.2347870],
[52.4388508, -3.2310104],
[52.4185447, -3.2148743],
[52.3952965, -3.1602859],
[52.3659569, -3.0960846],
[52.3487632, -3.0603790],
[52.3542156, -2.9697418],
[52.3477146, -2.9549789],
[52.3510700, -2.9419327],
[52.3544253, -2.9347229],
[52.3613446, -2.9405594],
[52.3674243, -2.9285431],
[52.3697302, -2.9158401],
[52.3764375, -2.9254532],
[52.3867062, -2.9175568],
[52.3812578, -2.8948975],
[52.3854489, -2.8911209],
[52.3929919, -2.8615952],
[52.3942490, -2.8372192],
[52.3879634, -2.8039169],
[52.3818865, -2.8042603],
[52.3760184, -2.8035736],
[52.3753896, -2.7960205],
[52.3751800, -2.7877808],
[52.3586189, -2.7908707],
[52.3590383, -2.7400589],
[52.3542156, -2.7294159],
[52.3470854, -2.7328491],
[52.3347097, -2.7414322],
[52.3321922, -2.7565384],
[52.3225404, -2.7325058],
[52.3170841, -2.7180862],
[52.3141459, -2.7040100],
[52.3187631, -2.6909637],
[52.3382759, -2.6765442],
[52.3439394, -2.6662445],
[52.3384857, -2.6535416],
[52.3082687, -2.6157761],
[52.3187631, -2.5701141],
[52.3244290, -2.5677109],
[52.3336608, -2.5653076],
[52.3468757, -2.5374985],
[52.3380662, -2.5059128],
[52.3311432, -2.5041962],
[52.3370173, -2.4853134],
[52.3542156, -2.4918365],
[52.3626025, -2.4811935],
[52.3697302, -2.4746704],
[52.3674243, -2.4657440],
[52.3676339, -2.4283218],
[52.3688917, -2.4152756],
[52.3862871, -2.4128723],
[52.3848203, -2.4046326],
[52.3839821, -2.3915863],
[52.3879634, -2.3637772],
[52.3854489, -2.3407745],
[52.3890111, -2.3390579],
[52.4001147, -2.3737335],
[52.4072363, -2.3706436],
[52.4241979, -2.3699570],
[52.4396879, -2.3661804],
[52.4411529, -2.3527908],
[52.4369671, -2.3342514],
[52.4390601, -2.3136520],
[52.4534988, -2.2927094],
[52.4865436, -2.3174286],
[52.5011757, -2.3060989],
[52.5249948, -2.2669601],
[52.5327228, -2.2748566],
[52.5414935, -2.2607803],
[52.5504711, -2.2851563],
[52.5629950, -2.2655869],
[52.5813570, -2.2824097],
[52.5851119, -2.2995758],
[52.5892836, -2.3050690],
[52.5892836, -2.3153687],
[52.5919950, -2.3273849],
[52.6153480, -2.3266983],
[52.6161818, -2.3160553],
[52.6128465, -2.3081589],
[52.6090939, -2.2985458],
[52.6084685, -2.2817230],
[52.6115957, -2.2583771],
[52.6445217, -2.2357178],
[52.6553526, -2.2497940],
[52.6657645, -2.2432709],
[52.6786718, -2.2470474],
[52.6782555, -2.2899628],
[52.6828346, -2.3081589],
[52.6924076, -2.3133087],
[52.6919915, -2.3222351],
[52.7036428, -2.3301315],
[52.7200743, -2.3139954],
[52.7265204, -2.3191452],
[52.7406568, -2.3181152],
[52.7483468, -2.3239517],
[52.7535420, -2.3328781],
[52.7572821, -2.3325348],
[52.7643460, -2.3469543],
[52.7766011, -2.3689270],
[52.7826235, -2.3792267],
[52.7900984, -2.3871231],
[52.7942506, -2.3860931],
[52.7965342, -2.3761368],
[52.8044219, -2.3806000],
[52.8114780, -2.3871231],
[52.8230975, -2.4166489],
[52.8291135, -2.4187088],
[52.8378248, -2.4049759],
[52.8378248, -2.3994827],
[52.8610465, -2.3922729],
[52.8707876, -2.3857498],
[52.8892276, -2.3788834],
[52.8850845, -2.3898697],
[52.8838415, -2.4063492],
[52.8803194, -2.4269485],
[52.8741033, -2.4406815],
[52.8712021, -2.4502945],
[52.8778330, -2.4619675],
[52.8834271, -2.4629974],
[52.9049679, -2.4753571],
[52.9101443, -2.4650574],
[52.9159413, -2.4605942],
[52.9248422, -2.4609375],
[52.9356036, -2.4489212],
[52.9490516, -2.4293518],
[52.9517407, -2.4176788],
[52.9490516, -2.4104691],
[52.9606342, -2.4176788],
[52.9647702, -2.4101257],
[52.9593934, -2.3936462],
[52.9542228, -2.3915863],
[52.9672515, -2.3833466],
[52.9753149, -2.3833466],
[52.9769688, -2.3764801],
[52.9926772, -2.3826599],
[52.9914372, -2.3926163],
[52.9891639, -2.4032593],
[52.9850304, -2.4145889],
[52.9827567, -2.4320984],
[52.9668380, -2.4279785],
[52.9602206, -2.4654007],
[52.9558775, -2.4877167],
[52.9655973, -2.4969864],
[52.9629090, -2.5079727],
[52.9678718, -2.5182724],
[52.9548433, -2.5203323],
[52.9438798, -2.5292587],
[52.9457417, -2.5402451],
[52.9525681, -2.5484848],
[52.9602206, -2.5618744],
[52.9521544, -2.5828171],
[52.9540160, -2.5944901],
[52.9596002, -2.5989532],
[52.9662176, -2.5999832],
[52.9730408, -2.6016998],
[52.9765553, -2.6027298],
[52.9819299, -2.6095963],
[52.9856504, -2.6126862],
[52.9862705, -2.6315689],
[52.9912306, -2.6336288],
[52.9881306, -2.6453018],
[52.9852371, -2.6659012],
[52.9839969, -2.6799774]];

const overlayCoOrdinatesBham: any = [[52.5578033, -1.9745779],
[52.5587947, -1.9743204],
[52.5593426, -1.9671535],
[52.5606732, -1.9667673],
[52.5623428, -1.9665527],
[52.5629950, -1.9639778],
[52.5615341, -1.9555664],
[52.5617167, -1.9536352],
[52.5614558, -1.9525194],
[52.5569684, -1.9524765],
[52.5565770, -1.9491720],
[52.5598905, -1.9427776],
[52.5604123, -1.9407606],
[52.5591861, -1.9327354],
[52.5573336, -1.9344091],
[52.5539156, -1.9325209],
[52.5532893, -1.9288731],
[52.5471830, -1.9181013],
[52.5456172, -1.9329929],
[52.5422243, -1.9294739],
[52.5367429, -1.9299030],
[52.5334799, -1.9302464],
[52.5311564, -1.9292593],
[52.5326575, -1.9336796],
[52.5319266, -1.9368982],
[52.5327032, -1.9394839],
[52.5330100, -1.9396663],
[52.5333493, -1.9409001],
[52.5336757, -1.9422626],
[52.5337213, -1.9430780],
[52.5335647, -1.9459426],
[52.5333298, -1.9474018],
[52.5328990, -1.9471657],
[52.5325727, -1.9478095],
[52.5317373, -1.9484425],
[52.5309541, -1.9493437],
[52.5297728, -1.9509315],
[52.5297336, -1.9529057],
[52.5296683, -1.9547296],
[52.5296292, -1.9573259],
[52.5296944, -1.9577658],
[52.5298380, -1.9580126],
[52.5299033, -1.9580233],
[52.5312804, -1.9581521],
[52.5312478, -1.9601476],
[52.5303145, -1.9601583],
[52.5288525, -1.9613814],
[52.5283760, -1.9617677],
[52.5272598, -1.9614887],
[52.5262742, -1.9608128],
[52.5249491, -1.9599438],
[52.5236958, -1.9594395],
[52.5228341, -1.9592035],
[52.5225012, -1.9594181],
[52.5216526, -1.9588280],
[52.5204187, -1.9579697],
[52.5193546, -1.9580770],
[52.5183819, -1.9584954],
[52.5181468, -1.9589031],
[52.5176768, -1.9597185],
[52.5172198, -1.9597614],
[52.5159271, -1.9618106],
[52.5152480, -1.9621110],
[52.5141969, -1.9622934],
[52.5137594, -1.9624543],
[52.5125449, -1.9620144],
[52.5112521, -1.9609737],
[52.5103314, -1.9601798],
[52.5100180, -1.9597507],
[52.5095739, -1.9594395],
[52.5097045, -1.9618857],
[52.5083855, -1.9620359],
[52.5082745, -1.9610488],
[52.5076411, -1.9614995],
[52.5074778, -1.9617033],
[52.5065570, -1.9610167],
[52.5048787, -1.9628620],
[52.5045587, -1.9613063],
[52.5034420, -1.9574654],
[52.5026517, -1.9554484],
[52.5016786, -1.9554162],
[52.5017962, -1.9530129],
[52.5016917, -1.9519722],
[52.5014435, -1.9503522],
[52.5012149, -1.9502664],
[52.5008165, -1.9483352],
[52.4994972, -1.9430029],
[52.4999021, -1.9426596],
[52.4996670, -1.9420373],
[52.4993862, -1.9423270],
[52.4983542, -1.9378102],
[52.4980929, -1.9379175],
[52.4977598, -1.9401491],
[52.4969564, -1.9420266],
[52.4956435, -1.9441402],
[52.4945200, -1.9438183],
[52.4943240, -1.9435823],
[52.4938603, -1.9444621],
[52.4932201, -1.9454491],
[52.4915935, -1.9426703],
[52.4912016, -1.9431531],
[52.4909729, -1.9429708],
[52.4898101, -1.9438398],
[52.4900845, -1.9458139],
[52.4873276, -1.9470799],
[52.4874256, -1.9479167],
[52.4868637, -1.9483888],
[52.4870205, -1.9496119],
[52.4854982, -1.9505560],
[52.4852304, -1.9494188],
[52.4831984, -1.9505990],
[52.4827998, -1.9513178],
[52.4826887, -1.9516182],
[52.4832245, -1.9524872],
[52.4828129, -1.9536245],
[52.4822314, -1.9561672],
[52.4826169, -1.9582915],
[52.4825907, -1.9614351],
[52.4818916, -1.9637740],
[52.4799902, -1.9658232],
[52.4778403, -1.9684196],
[52.4776966, -1.9682586],
[52.4767948, -1.9687521],
[52.4753049, -1.9652224],
[52.4741481, -1.9668961],
[52.4737038, -1.9655764],
[52.4730960, -1.9655550],
[52.4718935, -1.9689131],
[52.4709262, -1.9707048],
[52.4682138, -1.9753933],
[52.4668086, -1.9784296],
[52.4659981, -1.9825172],
[52.4655667, -1.9848025],
[52.4651745, -1.9881177],
[52.4646875, -1.9905397],
[52.4644489, -1.9918755],
[52.4641074, -1.9934580],
[52.4636335, -1.9954589],
[52.4630697, -1.9976556],
[52.4630681, -1.9984898],
[52.4633214, -2.0008743],
[52.4633312, -2.0022234],
[52.4629422, -2.0036235],
[52.4626873, -2.0040339],
[52.4624961, -2.0045516],
[52.4623784, -2.0052677],
[52.4621300, -2.0063487],
[52.4618734, -2.0078292],
[52.4619552, -2.0096773],
[52.4620597, -2.0110452],
[52.4621006, -2.0124641],
[52.4608341, -2.0130730],
[52.4589905, -2.0123112],
[52.4571470, -2.0115495],
[52.4554275, -2.0110881],
[52.4517857, -2.0120001],
[52.4496411, -2.0133519],
[52.4492226, -2.0130086],
[52.4483529, -2.0130730],
[52.4475617, -2.0143926],
[52.4466593, -2.0162165],
[52.4442855, -2.0169568],
[52.4431607, -2.0173216],
[52.4409698, -2.0169890],
[52.4397664, -2.0168817],
[52.4394067, -2.0170426],
[52.4389293, -2.0167315],
[52.4364700, -2.0168710],
[52.4327809, -2.0169032],
[52.4328659, -2.0159590],
[52.4325389, -2.0151865],
[52.4317735, -2.0152509],
[52.4289475, -2.0152724],
[52.4299288, -2.0131588],
[52.4295886, -2.0122147],
[52.4287513, -2.0128584],
[52.4268933, -2.0096827],
[52.4282279, -2.0075369],
[52.4284373, -2.0047045],
[52.4265008, -2.0036316],
[52.4229417, -2.0012283],
[52.4224706, -2.0020008],
[52.4219995, -2.0005417],
[52.4210050, -2.0010567],
[52.4186233, -1.9980526],
[52.4169220, -1.9943619],
[52.4158750, -1.9913578],
[52.4172099, -1.9879675],
[52.4172099, -1.9866371],
[52.4166864, -1.9857359],
[52.4139903, -1.9882250],
[52.4126815, -1.9936323],
[52.4096710, -1.9973230],
[52.4091213, -2.0029449],
[52.4075505, -2.0091248],
[52.4084929, -2.0111847],
[52.4049324, -2.0142746],
[52.4043040, -2.0187378],
[52.4054560, -2.0209694],
[52.4061891, -2.0268059],
[52.4025236, -2.0312691],
[52.4010573, -2.0285225],
[52.4007431, -2.0237160],
[52.3993815, -2.0195961],
[52.3964487, -2.0177078],
[52.3945632, -2.0158195],
[52.3932014, -2.0123863],
[52.3863919, -2.0104980],
[52.3863919, -2.0056915],
[52.3841916, -2.0038033],
[52.3817817, -1.9991684],
[52.3813626, -1.9953918],
[52.3826200, -1.9900703],
[52.3845060, -1.9890404],
[52.3878586, -1.9842339],
[52.3877539, -1.9782257],
[52.3876491, -1.9741058],
[52.3899539, -1.9715309],
[52.3895349, -1.9675827],
[52.3921539, -1.9655228],
[52.3890111, -1.9584846],
[52.3937252, -1.9535065],
[52.3881729, -1.9342804],
[52.3917349, -1.9313622],
[52.3965535, -1.9243240],
[52.3981246, -1.9191742],
[52.4070269, -1.9160843],
[52.4072363, -1.9123077],
[52.4051418, -1.9075012],
[52.4034662, -1.9026947],
[52.4041993, -1.8920517],
[52.4045135, -1.8843269],
[52.3998005, -1.8815804],
[52.4006384, -1.8795204],
[52.4027331, -1.8790054],
[52.4034662, -1.8733406],
[52.4057702, -1.8731689],
[52.4046182, -1.8671608],
[52.4032567, -1.8585777],
[52.4009657, -1.8518400],
[52.4019345, -1.8508744],
[52.4004158, -1.8452740],
[52.3974700, -1.8461108],
[52.3953227, -1.8474197],
[52.3886444, -1.8549728],
[52.3870205, -1.8577194],
[52.3861823, -1.8566895],
[52.3833010, -1.8606377],
[52.3791097, -1.8610668],
[52.3727172, -1.8655300],
[52.3676339, -1.8707657],
[52.3675291, -1.8646717],
[52.3663237, -1.8614960],
[52.3668478, -1.8591785],
[52.3665334, -1.8482780],
[52.3655376, -1.8435574],
[52.3653279, -1.8414974],
[52.3668478, -1.8382359],
[52.3672671, -1.8360901],
[52.3674243, -1.8343735],
[52.3664810, -1.8328285],
[52.3671099, -1.8315411],
[52.3663237, -1.8306828],
[52.3673719, -1.8295670],
[52.3665858, -1.8268204],
[52.3665334, -1.8242455],
[52.3662713, -1.8237305],
[52.3664810, -1.8199539],
[52.3660617, -1.8136883],
[52.3667954, -1.8130875],
[52.3666906, -1.8075943],
[52.3634935, -1.8049335],
[52.3594576, -1.7997837],
[52.3545825, -1.7861366],
[52.3509652, -1.7830467],
[52.3487108, -1.7792702],
[52.3479767, -1.7765236],
[52.3499691, -1.7767811],
[52.3512797, -1.7730904],
[52.3541631, -1.7756653],
[52.3553689, -1.7730904],
[52.3556310, -1.7741203],
[52.3548971, -1.7771244],
[52.3565221, -1.7790985],
[52.3578851, -1.7802143],
[52.3604011, -1.7800426],
[52.3642273, -1.7807293],
[52.3659045, -1.7760944],
[52.3657996, -1.7730904],
[52.3650659, -1.7700863],
[52.3647514, -1.7638206],
[52.3643321, -1.7618465],
[52.3626025, -1.7591858],
[52.3586713, -1.7591000],
[52.3559979, -1.7522335],
[52.3551592, -1.7459679],
[52.3556834, -1.7436504],
[52.3573609, -1.7422771],
[52.3597197, -1.7417622],
[52.3617115, -1.7403889],
[52.3626025, -1.7389297],
[52.3651183, -1.7391014],
[52.3691013, -1.7251968],
[52.3714071, -1.7183304],
[52.3561028, -1.7176437],
[52.3552640, -1.7090607],
[52.3540059, -1.6877747],
[52.3619736, -1.6850281],
[52.3646990, -1.6712952],
[52.3644894, -1.6606522],
[52.3598769, -1.6530991],
[52.3561028, -1.6493225],
[52.3619736, -1.6455460],
[52.3653279, -1.6314697],
[52.3672147, -1.6242599],
[52.3697302, -1.6263199],
[52.3737128, -1.6208267],
[52.3768567, -1.6167068],
[52.3779046, -1.6081238],
[52.3825152, -1.6108704],
[52.3885920, -1.6036606],
[52.3929919, -1.5988541],
[52.4009526, -1.6036606],
[52.4051418, -1.6064072],
[52.4076552, -1.6046906],
[52.4139380, -1.6043472],
[52.4168696, -1.6043472],
[52.4214761, -1.6108704],
[52.4246166, -1.6070938],
[52.4271288, -1.6149902],
[52.4308969, -1.6112137],
[52.4308969, -1.6057205],
[52.4336182, -1.6098404],
[52.4367578, -1.6108704],
[52.4407344, -1.6040039],
[52.4396879, -1.5978241],
[52.4453384, -1.5950775],
[52.4499419, -1.5964508],
[52.4514065, -1.5961075],
[52.4549633, -1.5957642],
[52.4547541, -1.5998840],
[52.4564278, -1.6064072],
[52.4572646, -1.6115570],
[52.4601934, -1.6156769],
[52.4618669, -1.6167068],
[52.4610302, -1.6239166],
[52.4549633, -1.6228867],
[52.4424086, -1.6379929],
[52.4386415, -1.6548157],
[52.4411529, -1.6616821],
[52.4340368, -1.6654587],
[52.4372811, -1.6745567],
[52.4405774, -1.6823673],
[52.4462800, -1.6856289],
[52.4484902, -1.6895342],
[52.4515765, -1.6933751],
[52.4571208, -1.6991258],
[52.4622199, -1.7058635],
[52.4647692, -1.7085028],
[52.4663118, -1.7091680],
[52.4738279, -1.7106915],
[52.4783370, -1.7151976],
[52.4787682, -1.7153907],
[52.4825973, -1.7224288],
[52.4945004, -1.7350245],
[52.5053032, -1.7421913],
[52.5099135, -1.7471266],
[52.5119377, -1.7530060],
[52.5137920, -1.7539501],
[52.5141707, -1.7532635],
[52.5159205, -1.7542291],
[52.5155419, -1.7549157],
[52.5175005, -1.7572546],
[52.5181403, -1.7572546],
[52.5184537, -1.7571902],
[52.5186104, -1.7544866],
[52.5211173, -1.7540359],
[52.5201903, -1.7475128],
[52.5220704, -1.7372131],
[52.5235326, -1.7320633],
[52.5266658, -1.7361832],
[52.5283368, -1.7409897],
[52.5314697, -1.7420197],
[52.5302166, -1.7464828],
[52.5318874, -1.7533493],
[52.5364819, -1.7512894],
[52.5408670, -1.7495728],
[52.5446254, -1.7554092],
[52.5540199, -1.7581558],
[52.5571510, -1.7684555],
[52.5604905, -1.7684555],
[52.5632037, -1.7746353],
[52.5661255, -1.7766953],
[52.5692557, -1.7684555],
[52.5728029, -1.7698288],
[52.5723856, -1.7773819],
[52.5761413, -1.7811584],
[52.5803139, -1.7883682],
[52.5903265, -1.7921448],
[52.5963747, -1.7979813],
[52.6017964, -1.8120575],
[52.6059666, -1.8292236],
[52.6015879, -1.8477631],
[52.6045853, -1.8476772],
[52.6016401, -1.8536639],
[52.5907436, -1.8640709],
[52.5882146, -1.8676329],
[52.5875237, -1.8691778],
[52.5848251, -1.8723536],
[52.5717205, -1.8779325],
[52.5690861, -1.8789411],
[52.5654603, -1.8858290],
[52.5620037, -1.8917084],
[52.5583642, -1.8965793],
[52.5554942, -1.8987894],
[52.5539938, -1.9026303],
[52.5530023, -1.9079518],
[52.5514627, -1.9118357],
[52.5472352, -1.9182301]]

export default Home;

