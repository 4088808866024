import { getCssVariableValue } from "../../services/utils"

export const chartRed = getCssVariableValue('rag-color-red')
export const chartAmberLight = getCssVariableValue('rag-color-amber-light')
export const chartAmber = getCssVariableValue('rag-color-amber')
export const chartGreen = getCssVariableValue('rag-color-green')
export const chartBlack = getCssVariableValue('rag-color-black')

export const exportGreenArgb = "FF2f8a0e"
export const exportAmberArgb = "FFe09e3d"
export const exportOrangeArgb = "FFe09a3d"
export const exportRedArgb = 'FFFF0000'
export const exportBlackArgb = 'FF000000'

export const getArgbColourCodeFromReportingName = (name:string)=>{
    let hexColour = getCssVariableValue('rag-color-red')
    switch(name.toString()) {case '1': hexColour =  exportGreenArgb; break;
    case '2': hexColour =  getCssVariableValue('rag-color-amber-light'); break;
    case '3': hexColour =  getCssVariableValue('rag-color-red'); break;
    case '4': hexColour =  getCssVariableValue('rag-color-black'); break;
    
    };
    return hexColour.replace('#','FF')
}

export const getOpelArgbColourCodeFromReportingName = (name:string)=>{
    let hexColour = exportBlackArgb //getCssVariableValue('rag-color-black')
    switch(name.toString()) {case '1': hexColour =  exportGreenArgb; break;
    case '2': hexColour =  getCssVariableValue('rag-color-amber'); break;
    case '3': hexColour =  getCssVariableValue('rag-color-red'); break;
    case '4': hexColour =  getCssVariableValue('rag-color-black'); break;
    
    };
    return hexColour.replace('#','FF')
}
