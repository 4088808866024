import { HttpResponse, HttpGet } from "./http";
import { IDomainEntities, ApplicationDomainType, DomainEntityType, createNewDomainEntity, OptionsType, createNewOptionsType } from '../interfaces/domain/iDomainEntity'
import { setDomainEntities, getDomainEntities, resetDomainEntities, setOptions, getOptions, getOptionList, setOptionList, getUser } from '../services/storageService'
import { getAreaListFromHierarchy } from "./systemService";
import { FormResultOptionType, FormResultTypeEnum } from "../interfaces/system/formType";
import { ServiceTypeLite } from "../interfaces/system/serviceType";
// import { fetchDistributionList } from "./distribution-list-service";
export const fetchDomainEntities = async (): Promise<IDomainEntities[]> => {

  // if(!isUserAuthenticated())
  //   return []

  const entities = getDomainEntities();

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    let response: HttpResponse<IDomainEntities[]>;
    response = await HttpGet<IDomainEntities[]>(
      `option`
    );
    const entitiesResult = response.result || []
    setDomainEntities(entitiesResult)
    return entitiesResult
  }
}
export const fetchOptions = async (): Promise<OptionsType> => {

  const entities = getOptions();

  if (entities && (entities.regions?.length ?? 0)> 0) {
    return entities
  }
  else {
    const response = await HttpGet<OptionsType>(
      `option`
    );
    const entitiesResult = response.result || createNewOptionsType()
    setOptions(entitiesResult)

    return entitiesResult
  }
}

export const fetchServiceList = async (): Promise<ServiceTypeLite[]> => {
  const entities = getOptionList('service-list') as ServiceTypeLite[];

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<ServiceTypeLite[]>(
      `option/service-list`
    );
    const entitiesResult = response.result || []
    setOptionList('service-list', entitiesResult)
    return entitiesResult
  }
}
export const fetchServiceFeatures = async (): Promise<DomainEntityType[]> => {
  const entities = getOptionList('service-features-list');

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/service-features-list`
    );
    const entitiesResult = response.result || []
    setOptionList('service-features-list', entitiesResult)
    return entitiesResult
  }
}

export const fetchFormList = async (): Promise<DomainEntityType[]> => {
  const entities = getOptionList('form-list');

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/form-list`
    );
    const entitiesResult = response.result || []
    setOptionList('form-list', entitiesResult)
    return entitiesResult
  }
}
export const fetchCapacityGridList = async (): Promise<DomainEntityType[]> => {
  const typeKey = 'capacity-grid-list';
  const entities = getOptionList(typeKey);

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/${typeKey}`
    );
    const entitiesResult = response.result || []
    setOptionList(typeKey, entitiesResult)
    return entitiesResult
  }
}

export const fetchCapacityGridBedAvailabilityTypes = async () =>  {
    const typeKey = 'capacity-grid-bed-availability-list';
    const entities = getOptionList(typeKey);

    if (entities && entities.length > 0) {
        return entities
    }
    else {
        const response = await HttpGet<DomainEntityType[]>(
            `option/${typeKey}`
        );
        const entitiesResult = response.result || []
        setOptionList(typeKey, entitiesResult)
        return entitiesResult
    }
}

export const fetchCapacityGridResourceTypeList = async (): Promise<DomainEntityType[]> => {
  const typeKey = 'capacity-grid-resource-type';
  const entities = getOptionList(typeKey);

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/${typeKey}`
    );
    const entitiesResult = response.result || []
    setOptionList(typeKey, entitiesResult)
    return entitiesResult
  }
}
export const fetchCapacityGridFieldTypeList = async (): Promise<DomainEntityType[]> => {
  const typeKey = 'capacity-grid-field-type';
  const entities = getOptionList(typeKey);

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/${typeKey}`
    );
    const entitiesResult = response.result || []
    setOptionList(typeKey, entitiesResult)
    return entitiesResult
  }
}
export const fetchCapacityGridColumnVisibilityTypeList = async (): Promise<DomainEntityType[]> => {
  const typeKey = 'capacity-grid-column-visibility-type';
  const entities = getOptionList(typeKey);

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/${typeKey}`
    );
    const entitiesResult = response.result || []
    setOptionList(typeKey, entitiesResult)
    return entitiesResult
  }
}
export const fetchCapacityGridColumnReportingTypeList = async (): Promise<DomainEntityType[]> => {
  const typeKey = 'capacity-grid-column-reporting-type';
  const entities = getOptionList(typeKey);

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/${typeKey}`
    );
    const entitiesResult = response.result || []
    setOptionList(typeKey, entitiesResult)
    return entitiesResult
  }
}
export const fetchDistributionListList = async (): Promise<DomainEntityType[]> => {
    const typeKey = 'distribution-list';
  const entities = getOptionList(typeKey);

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/${typeKey}`
    );
    const entitiesResult = response.result || []
    setOptionList(typeKey, entitiesResult)
    return entitiesResult
  }
}
export const fetchOpelList = async (): Promise<DomainEntityType[]> => {
    const typeKey = 'opel-list';
  const entities = getOptionList(typeKey);

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/${typeKey}`
    );
    const entitiesResult = response.result || []
    setOptionList(typeKey, entitiesResult)
    return entitiesResult
  }
}
export const fetchSystemMessageDeliveryTypeList = async (): Promise<DomainEntityType[]> => {
    const typeKey = 'system-message-delivery-type';
  const entities = getOptionList(typeKey);

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/${typeKey}`
    );
    const entitiesResult = response.result || []
    setOptionList(typeKey, entitiesResult)
    return entitiesResult
  }
}
export const fetchOptionListByName = async (optionName: string): Promise<DomainEntityType[]> => {
  const entities = getOptionList(optionName);

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/${optionName}`
    );
    const entitiesResult = response.result || []

    
    setOptionList(optionName, entitiesResult)
    return entitiesResult
  }
}
export const fetchUserPermissionList = async (): Promise<DomainEntityType[]> => {
  const entities = getOptionList('permission-list');

  if (entities && entities.length > 0) {
    return entities
  }
  else {
    const response = await HttpGet<DomainEntityType[]>(
      `option/permission-list`
    );
    const entitiesResult = response.result || []
    setOptionList('permission-list', entitiesResult)
    return entitiesResult
  }
}
export const clearDomainEntities = () => {
  resetDomainEntities()
}
export const getLocalOptions = async (optionName: string): Promise<DomainEntityType[]> => {
  const allEntities = await fetchOptions() as any;
  if (optionName === 'service-list' || optionName==='permitted-service-list') {
    let servliceList = await fetchServiceList();

    if(optionName==='permitted-service-list' && servliceList){
      const u = getUser()
      if(!u.administrator){
        servliceList = servliceList.filter(f=> u.servicePermissions[f.id]  )
      }
    }

    return servliceList as any
  }
  if (optionName === 'areas') {
    const servliceList = await getAreaListFromHierarchy() as any;
    return servliceList
  }
  if (optionName === 'service-features-list') {
    const servliceList = await fetchServiceFeatures() as any;
    return servliceList
  }
  if (optionName === 'forms') {
    const servliceList = await fetchFormList() as any;
    return servliceList
  }
  if (optionName === 'capacity-grid') {
    const servliceList = await fetchCapacityGridList() as any;
    return servliceList
  }
  if (optionName === 'capacity-grid-resource-type') {
    const servliceList = await fetchCapacityGridResourceTypeList() as any;
    return servliceList
  }
  if (optionName === 'capacity-grid-field-visibility-type') {
    const servliceList = await fetchCapacityGridColumnVisibilityTypeList() as any;
    return servliceList
  }
  if (optionName === 'capacity-grid-column-reporting-type') {
    const servliceList = await fetchCapacityGridColumnReportingTypeList() as any;
    return servliceList
  }
  if (optionName === 'capacity-grid-field-type') {
    const servliceList = await fetchCapacityGridFieldTypeList() as any;
    return servliceList
    }
if (optionName === 'capacity-grid-bed-availability') {
    const list = await fetchCapacityGridBedAvailabilityTypes() as any;
    return list
}
  if (optionName === 'permission-list') {
    const servliceList = await fetchUserPermissionList() as any;
    return servliceList
    }
    if (optionName === 'distribution-list') {
        const servliceList = await fetchDistributionListList() as any;
        return servliceList
    }
    if (optionName === 'system-message-type') {
        const servliceList = await fetchSystemMessageDeliveryTypeList() as any;
        return servliceList
    }
    if (optionName === 'opel-list') {
        const servliceList = await fetchOpelList() as any;
        return servliceList
    }
    

  if (allEntities[optionName])
    return allEntities[optionName]

  // if(optionName==='form-type-list' || optionName==='form-action-type-list' || optionName==='form-field-display-type-list'
  //    ||optionName==='form-result-list' || optionName==='form-footer-list' || optionName==='dataset-list') {
  const list = await fetchOptionListByName(optionName) as any
  return list
  // }

  // return allEntities[optionName]
}

export const getLocalDomainEntities = async (domainType: ApplicationDomainType | undefined): Promise<IDomainEntities> => {
  let allEntities = getDomainEntities();
  if (!allEntities || allEntities.length === 0) {
    allEntities = await fetchDomainEntities();
  }

  const selectedEntities = allEntities.find(f => f.domain === domainType)
  return selectedEntities || { entityGroups: [], domain: 1 }
}
export const getServiceWithFormFromIds =async (ids:number[]) => {
  const serviceNames:ServiceTypeLite[] =[]
  const serviceList = await fetchServiceList();

  ids.forEach(id=>{
      const s =serviceList.find(f=> f.id === id && f.formId)
      if(s){
        s.name = s.name.split('|')[0]
        serviceNames.push(s)
      }
  })
  return serviceNames
}


export const getApplicationDomainEntityFromId = (domainType: ApplicationDomainType, id: number): DomainEntityType => {
  const entities = getDomainEntities()
  if (!entities)
    return createNewDomainEntity()
  let entity = entities.find(f => f.domain === domainType);
  let allEntities: DomainEntityType[] = []
  if (entity) {
    entity.entityGroups.forEach(f =>
      allEntities = allEntities.concat(f.entities)
    )
  }

  return allEntities.find(f => f.id === +id) || createNewDomainEntity();
}
export const getFormResultOptionStyleFromId =  (formResultOptionId: number) => {
  const opts =  getOptions().formResultOptions

  
  return opts.find(f=> f.id === formResultOptionId && f.formResultTypeId === FormResultTypeEnum.Standard)?.style
}

export const getFormResultOptionRangeFromId = async (formResultOptionList: FormResultOptionType[], formResultOptionId: number, resultType:FormResultTypeEnum,formId?:number) => {
  
  const isOpel  = (resultType == FormResultTypeEnum.Opel)

  
  if(!isOpel && formId && (!formResultOptionList || formResultOptionList.length<1) ){
    formResultOptionList = await getFormResultOptionsFromFormId(formId)
  }
  
  if(!isOpel &&!formId && formResultOptionId){
    const formResultOption = formResultOptionList.find(f => f.formResultOptionId === formResultOptionId)

    if (!formResultOption) return []
    formId = formResultOption.formId
  }
  const fId = isOpel? null : formId

  const options = formResultOptionList.filter(f => (f.formId === fId) && f.formResultTypeId.toString() === resultType.toString())
  
  return options
} 

export const getFormResultOptionFromId = (formResultOptionList: FormResultOptionType[], formResultOptionId: number) => {
  return formResultOptionList.find(f => f.id === formResultOptionId)
}
export const getOptionsForFormResultId = (formResultOptionList: FormResultOptionType[], formResultd: number) => {

  //remove dupes
  let optionNames = ''
  const result: FormResultOptionType[] = []
  const o = formResultOptionList.filter(f => f.formResultId.toString() === formResultd.toString())

  if(o){
    o.forEach(f=>{
      const optionName = `||${f.shortName}||`
      if(optionNames.indexOf(optionName)<0)
        result.push(f)
        optionNames = optionNames + optionName
    })
  }

  return result
}

export const getFormOptionResultById = async (formResultOptionId: number, formId: number) => {
  const formResultOptionList = await fetchOptions()

  return formResultOptionList.formResultOptions.find(f => f.id === formResultOptionId && f.formId === formId)
}
export const getFormResultOptionsFromFormId = async (formId?: number) => {
  const formResultOptionList = await fetchOptions()

  return formResultOptionList.formResultOptions.filter(f =>  !formId  || (f.formId === formId))
}
export const getFormResultOptionsFromResultId = (formResultOptionList: FormResultOptionType[], formResultId: number, formId?: number) => {
  // if (!formResultId) return []
  let options = formResultOptionList.filter(f => f.formResultId === formResultId && (formId === f.formId))
  

  if (options.length === 0 && formResultOptionList && formResultOptionList.length > 0) {
    const firstFormId = formResultOptionList[0].formId

    
    options = formResultOptionList.filter(f => f.formResultId.toString() === formResultId.toString() && (!firstFormId || firstFormId === f.formId))
  }

  return options
}
export const getFormResultOptionsForResultId = (formResultOptionList: FormResultOptionType[], formResultId: number) => {
  let options = formResultOptionList.filter(f => f.formResultId === formResultId)
  

  if (options.length === 0 && formResultOptionList && formResultOptionList.length > 0) {
    const firstFormId = formResultOptionList[0].formId

    
    options = formResultOptionList.filter(f => f.formResultId.toString() === formResultId.toString() && (!firstFormId || firstFormId === f.formId))
  }

  return options
}

export const getCachedOptions   =  ()  : OptionsType => {
  const o = getOptions()
  if(!o)
    console.log("No cached options")
  return o
}