import React, { useState } from "react";
import { ServiceStatusType } from "../../interfaces/system/serviceType";
import { Grid } from '@mui/material'
import Panel from "../core/panel";
import ServiceOverview from "./service-overview";
import BasicTab from "../core/basicTab";
import FormSubmissionReport from "../reports/form/form-submission";
import { FormSubmissionType } from "../../interfaces/reporting/form-submission";
import FormSubmissionSummary from "./form-submission-summary";
import ServiceFeatureList from "../service/service-feature-list";
import { DomainEntityType } from "../../interfaces/domain/iDomainEntity";
import AddressSummary from "../address/address-summary";
import { AddressType } from "../../interfaces/system/iAddress";
import { CapacityGridReportForDivision } from "./capacity-grid-report-for-division";
import { FormResultTypeEnum } from "../../interfaces/system/formType";
import { ServiceUploadList } from "./service-upload-list";
import { DocumentUploadType } from "../../interfaces/system/documentUploadTypes";
import { ActionCardHomepageOverviewType } from "../../interfaces/system/actionCardType";
import { ServiceActionCardList } from "./service-action-card-list";

interface ServiceFullDetailsProps {
    service?: ServiceStatusType
    onDismiss?: () => void
    onRequestFormSubmission?: (serviceId: number) => void
    onRequestServiceFeatures?: (serviceId: number) => void
    onRequestServiceAddress?: (serviceId: number) => void
    onRequestServiceUploads?: (serviceId: number) => void
    onRequestServiceActionCard?: (serviceId: number) => void
    onCombinedUpdated?: (serviceId: number, formResultOptionId:number,auto:boolean) => void
    hidePanel?: boolean
    dismissable?: boolean
    formSubmission?: FormSubmissionType
    serviceFeatures?: DomainEntityType[]
    serviceAddress?: AddressType
    address?: AddressType
    serviceUploads?: DocumentUploadType[]
    serviceActionCard?: ActionCardHomepageOverviewType
    onCapacityStatusDivisionSelected?: (serviceId: number) => void
}


const ServiceFullDetails: React.FC<ServiceFullDetailsProps> = (props) => {
    const [service, setService] = useState(props.service);
    const [formSubmission, setFormSubmission] = useState(props.formSubmission);
    const [opelFormSubmission, setOpelFormSubmission] = useState<FormSubmissionType>();
    const [serviceFeatures, setServiceFeatures] = useState(props.serviceFeatures);
    const [addressObject, setAddressObject] = useState(props.address)
    const [serviceUploads, setServiceUploads] = useState(props.serviceUploads);
    const [serviceActionCard, setServiceActionCard] = useState(props.serviceActionCard);

    React.useEffect(() => {
        setService(props.service)

        if (service?.id !== props.service?.id) {
            setFormSubmission(undefined)
            setOpelFormSubmission(undefined)
            setServiceFeatures(undefined)
            setAddressObject(undefined)
            setServiceUploads(undefined)
            setServiceActionCard(undefined)
        }
    }, [props.service])

    React.useEffect(() => {
        if (props.formSubmission)
            setFormSubmission({ ...props.formSubmission })
        if (props.formSubmission) {
            const cloneSubmission = JSON.parse(JSON.stringify(props.formSubmission));
            setOpelFormSubmission(cloneSubmission)
        }

    }, [props.formSubmission])
    React.useEffect(() => {
        setServiceFeatures(props.serviceFeatures)
    }, [props.serviceFeatures])
    React.useEffect(() => {
        setAddressObject(props.address)
    }, [props.address])

    React.useEffect(() => {
        setServiceUploads(props.serviceUploads)
    }, [props.serviceUploads])

    React.useEffect(() => {
        setServiceActionCard(props.serviceActionCard)
    }, [props.serviceActionCard])

    const handleTabSelection = (tabIndex: number, key?: string) => {
        const tabKey = key?.toLowerCase() ?? tabIndex.toString();
        switch (tabKey) {
            case "escalation":
            case "opel":
                if (!formSubmission && service && props.onRequestFormSubmission) {
                    props.onRequestFormSubmission(service.id)
                }
                break;
            case "action cards":
                if (!serviceActionCard && service && props.onRequestServiceActionCard) {
                    props.onRequestServiceActionCard(service.id)
                }
                break;
            case "features":
                if (!serviceFeatures && service && props.onRequestServiceFeatures) {
                    props.onRequestServiceFeatures(service.id)
                }
                break;
            case "uploads":
                if (!serviceUploads && service && props.onRequestServiceUploads) {
                    props.onRequestServiceUploads(service.id)
                }
                break;
            case "contact":
                if (!addressObject && service && props.onRequestServiceAddress) {
                    props.onRequestServiceAddress(service.id)
                }
                break;
        }
    }
    const handleDismiss = () => {
        setFormSubmission(undefined)
        if (props.onDismiss) props.onDismiss()
    }
    const handleCapacitySummaryDivisonSelected = (serviceId: number) => {
        if (props.onCapacityStatusDivisionSelected) {
            props.onCapacityStatusDivisionSelected(serviceId);
        }
    }

 const handleUpdateCombined = (serviceId: number, formResultOptionId:number,auto:boolean) =>{
        if (props.onCombinedUpdated) {
            props.onCombinedUpdated(serviceId,formResultOptionId,auto);
        }
    }

    const componentTabs = () => {
        let tabs = [
            { label: 'Overview', component: <><ServiceOverview supressGauge={true} service={service} onCapacityStatusDivisionSelected={handleCapacitySummaryDivisonSelected} hidePanel={true} onCombinedUpdated={handleUpdateCombined} /></> },
            { label: 'Escalation', component: <><FormSubmissionReport service={service} formResultType={FormResultTypeEnum.Standard} formSubmission={formSubmission} /></> },
            { label: 'Opel', hidden: ((service?.opelFormResultOptionId ?? 0) < 1), component: <><FormSubmissionReport service={service} formResultType={FormResultTypeEnum.Opel} formSubmission={opelFormSubmission} /></>, },
            { label: 'Capacity', hidden: ((service?.capacityGridId ?? 0) < 1), component: <><CapacityGridReportForDivision divisionId={service?.id ?? 0} /></> },
            { label: 'Action Cards', component: <Grid container justifyContent="center" spacing={3}><Grid item xs={9}><ServiceActionCardList serviceActionCard={serviceActionCard} service={service} /></Grid></Grid> },
            { label: 'Features', component: <Grid container justifyContent="center" spacing={3}><Grid item xs={9}><ServiceFeatureList serviceFeatures={serviceFeatures} /></Grid></Grid> },
            { label: 'Uploads', component: <Grid container justifyContent="center" spacing={3}><ServiceUploadList serviceUploads={serviceUploads} /></Grid> },
            { label: 'Contact', component: <Grid container justifyContent="center" spacing={3}><Grid item xs={9}><AddressSummary address={addressObject} /></Grid></Grid> },
        ];

        return tabs;
    }

    return <>
        <Grid container spacing={1} justifyContent="center" style={{ minHeight: 250 }}>
            <Grid item xs={11}>
            </Grid>
            <Grid item xs={11}>
                <Panel title={service?.name} dismissable={props.dismissable} onClose={handleDismiss}>
                    <Grid container>
                        <FormSubmissionSummary showFooter={false} serviceStatus={service} padding={10} showLastUpdated={false} />
                        &nbsp;
                        <FormSubmissionSummary showFooter={false} serviceStatus={service} opelScore={service?.opelScore} padding={10} showLastUpdated={false} />
                    </Grid>
                    <Grid container>
                        <BasicTab key='serv_' tabs={componentTabs()} onTabSelect={handleTabSelection}></BasicTab>
                    </Grid>
                </Panel>
            </Grid>
        </Grid>
    </>;
}


export default ServiceFullDetails;

