import React, { useState, useEffect} from "react";
import {Grid} from '@mui/material';
import UserRegistrationSteps from './registrationSteps';
import GroupBox from '../core/groupBox';
import ActionButton from '../core/actionButton';
import Loader from '../core/loading';
import Error from '../core/error';
import {verifyUserByToken} from '../../services/userService';
import {INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE} from '../../containers/authenticationContainer'
import Feedback, { FeedbackType } from '../../components/core/feedback'
import useForm from "../../hooks/useForm"
import { getUser } from '../../services/storageService'
import {createNewUserRegistrationAboutYouFromUser} from '../../interfaces/user/iUser'
import ServicePermissionSelector, { SelectedPermissionType, ServiceGroupingType } from '../core/servicePermissionSelector'
import BasicTab from '../core/basicTab'
import SimpleDialog from "../core/simpleDialog";
import { AreaHierarchyType } from "../../interfaces/system/areaTypes";
import howto_services from '../../assets/images/ems_howto_select_service_permissions.gif'
import howto_regions from '../../assets/images/ems_howto_select_region_permissions.gif'
import UserRegistrationValidator from "../../validators/userRegistrationValidator";
import DataTable from "../core/dataTable";
export interface PropsType {
    token:string
    onError?: (errorMessage:string) => void
    onCancel: () => void
    onComplete: (permissions: SelectedPermissionType[]) => void
    areaHierarchy:AreaHierarchyType
    restrictAreaId?:number
    loading:boolean
    errorMessage?:string
  }

const UserRegistrationAccessRequirements: React.FC<PropsType> = (props) => {
    const  [loading, setLoading] = useState(props.loading);
     const [selectedPermissionsByService, setSelectedPermissionsByService] = useState<SelectedPermissionType[]>();
     const [selectedPermissionsByRegion, setSelectedPermissionsByRegion] = useState<SelectedPermissionType[]>([]);
     const [consolidatedPermissions, setConsolidatedPermissions] = useState<SelectedPermissionType[]>();
     const [areaHierarchy, setAreaHierarchy] = useState(props.areaHierarchy)
     const [restrictAreaId, setRestrictAreaId] = useState(props.restrictAreaId)
     const [validToken, setValidToken] = useState(true);
     const [user] = useState(createNewUserRegistrationAboutYouFromUser(getUser(), props.token))
     const [showConfirmation, setShowConfirmation]=useState(false)
     const {
        feedbackStatus, setFeedbackStatus, errorMessage, setErrorMessage
      }
      = useForm({initialObjectValues: user, validator: new UserRegistrationValidator()})

      
      useEffect(() => {
        userVerification()
      },[props.token])
     
      useEffect(() => {
        setLoading(props.loading)
      },[props.loading])

      useEffect(() => {
        setAreaHierarchy(props.areaHierarchy)
      },[props.areaHierarchy])
      useEffect(() => {
        setRestrictAreaId(props.restrictAreaId)
      },[props.restrictAreaId])

      useEffect(() => {
        if(props.errorMessage && props.errorMessage!==''){
            setFeedbackStatus(FeedbackType.Error)
            setErrorMessage(props.errorMessage)
            return
        }
        setFeedbackStatus(FeedbackType.hide)
      },[props.errorMessage])
       

      const userVerification  = async () => {
        setLoading(true)
        if(!props.token || props.token===''){
            verificationFailure(INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE)
            return
        }
        
        const validToken = await verifyUserByToken(props.token)

        if(validToken.success)
            verificationSuccess()
        else
            verificationFailure(INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE)
      }
      const verificationSuccess = async () =>{
        setValidToken(true)
        setLoading(false)
     }
     const verificationFailure = (error:string) =>{
        setValidToken(false)
        setLoading(false);
    }

    const handleSave = async () =>{
        setErrorMessage('')
        const permissions= consolidatePermissions()


        setConsolidatedPermissions(permissions)
        if(!permissions || permissions.length===0){
            setErrorMessage('You must select at least one region or service to register.')
            setFeedbackStatus(FeedbackType.Error)
            return
        }
        setFeedbackStatus(FeedbackType.hide)
        setShowConfirmation(true)
      } 
      const handleBack = async () =>{
        setFeedbackStatus(FeedbackType.hide)
        setShowConfirmation(false)
        props.onCancel()
      }  

      const consolidatePermissions = () : SelectedPermissionType[] => {
        let permissions = selectedPermissionsByService || []
        permissions = permissions.concat(selectedPermissionsByRegion)

        return permissions
      }

    const handleServiceSelected = (selected:SelectedPermissionType[]) =>{
        setSelectedPermissionsByService(selected)
    }
    const handleRegionSelected = (selected:SelectedPermissionType[]) =>{
        setSelectedPermissionsByRegion(selected)
    }
    const handleConfirmation = () => {
        setShowConfirmation(false)
        setLoading(true)
        if(consolidatedPermissions)
            props.onComplete(consolidatedPermissions)
    }
    const cardActions= <Grid container spacing={1}><Grid item xs={1} ></Grid>
        <Grid item xs={9} justifyContent='flex-start'><ActionButton  icon="pi pi-caret-left" onClick={handleBack} severity="secondary" label="Back"></ActionButton></Grid> 
        <Grid item xs={2} justifyContent='flex-end'><ActionButton  icon="pi pi-caret-right" onClick={handleSave} severity="success" label="Next"></ActionButton></Grid>
                    </Grid>
    if(loading)
        return <Loader loading={loading}></Loader>


        
     if(!validToken)
        return <Grid container spacing={3} justifyContent="center">
                    <Grid item lg={9} xs={12}>
                        <Error message={INVALID_VERIFICATION_TOKEN_ERROR_MESSAGE}></Error>
                    </Grid>
                </Grid>
    

    const help = <div>
        <p>You must request access to at least one service to complete registration.</p>
        <h4>Required Access Type</h4>
        <p>There are two types of access you can request, 'read' <i style={{'fontSize': '1.25em'}} className="pi pi-book"></i> and  'write' <i className="pi pi-pencil" style={{'fontSize': '1.25em'}}></i>. Should you only wish to view information for a given service or area you should select 'read'. 
            Alternatively you should select 'write'.
        </p>
        <p>If you know the name(s) of the service(s) for which you require access you can simply enter the name or post code in the search box within the 'services' tab.</p>
        <img src={howto_services} style={{width:'85%'}} alt=""/>
        <p>If you require access to a region or an area you can select a region or area within the 'Services by region' tab</p>
        <img src={howto_regions} style={{ width: '85%' }} alt="" />
        
    </div>

 
    let consoltidatedServices: any[]= [];
    if(consolidatedPermissions) 
        consoltidatedServices = consolidatedPermissions.filter(f=>f.type===1);

        
    return <>
    <Grid container justifyContent = "center" spacing={0}>
        <SimpleDialog title="Confirm you service access requirements" 
            subTitle={`Please confirm you require access to the following ${consoltidatedServices.length} service(s).`}
            yesno={true} show={showConfirmation} onOk={handleConfirmation} onClose={()=>setShowConfirmation(false)}>
                <div>
                    <p></p>
                    <DataTable dataKey="id" loading={false} columns={[{header:"Name", field:"name"},
                            {header:"Role", field:"userRole"}]}  paginationRowsPerPage={25} value={consoltidatedServices}  />
                </div>
        </SimpleDialog>
                    
    <Grid item xs={10} lg={6}>
    <Feedback type={feedbackStatus} message={errorMessage}></Feedback>   
    <UserRegistrationSteps step={3}/>
    <Grid item xs={12} >&nbsp;
        <GroupBox title="Your service access requirements" iconName={"thumb_up_alt"} actions={cardActions} help={help} showHelpOnInit={true}>
            <Grid container spacing={2} justifyContent = "center">

                <Grid item xs={12}>
                <BasicTab key='ppp' tabs={[{label:'Services', component: <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ServicePermissionSelector onChange={handleServiceSelected} areaHierarchy={areaHierarchy}  selections={selectedPermissionsByService}></ServicePermissionSelector>
                    </Grid> 
                </Grid> },
                  {label:'Services by region', component: <Grid container spacing={1}>
                  <Grid item xs={12}>
                        <ServicePermissionSelector onChange={handleRegionSelected} areaHierarchy={areaHierarchy} selections={selectedPermissionsByRegion} grouping={ServiceGroupingType.Region} restrictAreaId={restrictAreaId}></ServicePermissionSelector>
                  </Grid> 
              </Grid> }]}>
              
              
        </BasicTab>
        </Grid>   
                
            </Grid>
            
        </GroupBox>
        </Grid>
    </Grid>

        </Grid>

        </>          
}


export default UserRegistrationAccessRequirements;
