import { useEffect, useState } from "react"
import { UserSubscriptionReportType } from "../../../interfaces/system/userSubscriptionReportType"
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter"
import { Grid } from "@mui/material"
import { ExcelExportDataRow, ExcelExportHeader, ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service"
import DataTable from "../../core/dataTable"
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes"

export interface UserSubscriptionReportCriteria {
    serviceIds?: number[]
    userIds?: number[]
    levels?: number[]
}
export interface UserSubscriptionReportProps {
    areaHierarchy: AreaHierarchyType,
    list?: UserSubscriptionReportType[],
    onApplyFilter: (criteria: UserSubscriptionReportCriteria) => void
    criteria?: BasicReportFilterCriteria
    defaultFilterCriteria?: BasicReportCriteria
}

export function UserSubscriptionReport(props: UserSubscriptionReportProps) {
    const [data, setData] = useState(props.list);

    useEffect(() => {
        setData(props.list)
    }, [props.list]);

    const handleApplyFilter = (criteria: BasicReportCriteria) => {
        const filter:any = {
            serviceIds: criteria.selectedServiceIds?.map(s => s.id)
        };
        if (criteria.selectedUserId !== undefined && (criteria.selectedUserId ?? 0) > 0) {
            const arr:number[] = [criteria.selectedUserId];
            filter.userIds = arr;
        }
        if ((criteria?.selectedSubscriptionLevels?.length ?? 0) > 0) {
            filter.levels = criteria.selectedSubscriptionLevels;
        }
        props.onApplyFilter(filter);
    }

    const handleExport = () => {
        const title = 'User Subscription Report'
        const exportData: ExcelExportDataRow[] = []
        const sheets: ExcelExportSheet[] = []

        sheets.push({ title: title, elements: [{}], name: title })

        const headers: ExcelExportHeader[] = [
            { title: "Level" },
            { title: "User" },
            { title: "Service" },
        ]

        data?.forEach(d => {
            exportData.push({
                cols: [
                    { value: d.formResultOptionId != null ? d.formResultOptionId.toString() : "Capacity Grid" },
                    { value: d.user.fullName },
                    { value: d.service.name }
                ]
            })
        })

        exportToExcel({
            defaultHeaderWidth: 20,
            headers: headers,
            data: exportData,
            sheets: sheets
        });
    }

    return <>

        <Grid item xs={12}>
            <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
                defaultFilterCriteria={props.defaultFilterCriteria} 
                onExport={handleExport} />
        </Grid>
        <p>&nbsp;</p>
        <Grid item xs={12} >
            <DataTable dataKey="id" loading={false} columns={[{ header: "Level", field: "NA", formatCallBack: (rowData) => { const r = rowData as UserSubscriptionReportType; return r.formResultOptionId != null ? r.formResultOptionId.toString() : "Capacity Grid"; } },
            { header: "User", field: "NA", formatCallBack: (rowData) => { const r = rowData as UserSubscriptionReportType; return r.user.fullName; } },
            { header: "Service", field: "NA", formatCallBack: (rowData) => { const r = rowData as UserSubscriptionReportType; return r.service.name; } }]}
            paginationRowsPerPage = { 25} value = { data } editButton = { false} paginator = {(data?.length ?? 0) > 25} filter={false} allowExport={false} editButtonIcon="pi pi-search"
            />
        </Grid></>
}