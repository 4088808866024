import React, { useState, useEffect } from 'react'
import { AreaHierarchyChild, AreaHierarchyType } from '../../interfaces/system/areaTypes';
import { Tree } from 'primereact/tree';
import { ScrollPanel } from 'primereact/scrollpanel';
import { MultiStateCheckbox } from 'primereact/multistatecheckbox';
import regionIcon from '../../assets/images/icons/globe.svg'
import hospitalIcon from '../../assets/images/icons/hospital.svg'
import TypeAheadTextbox, { InputOptionType } from './typeAheadTextbox';
import { fetchServiceList } from '../../services/domainEntitiesService';
import Panel from './panel';
import TextBox from './textBox';
import Dropdown from './dropdown';
import { ServiceTypeLite } from '../../interfaces/system/serviceType';
import { formatDate } from '../../extensions/formatter';

export interface SelectedPermissionType {
    id: number
    userRoleTypeId: UserRoleType
    status?: number
    userRole: string
    type: PermissionEntityType
    name: string
    areaId?: number
    serviceId?: number
    parentAreaId?: number
    requestedOn?:string
}
export enum UserRoleType {
    Read = 10, Write = 20, Pending = 0
}
export enum PermissionEntityType {
    Region, Service
}
export enum ServiceGroupingType {
    None, Region, Subscription
}
interface PropsType {
    grouping?: ServiceGroupingType
    selections?: SelectedPermissionType[]
    onChange: (selected: SelectedPermissionType[]) => void
    areaHierarchy: AreaHierarchyType
    restrictAreaId?: number
}


const ServicePermissionSelector: React.FC<PropsType> = (props) => {
    const [grouping] = useState(props.grouping || ServiceGroupingType.None)
    const [loading, setLoading] = useState(true);
    const [restrictAreaId, setRestrictAreaId] = useState(props.restrictAreaId)
    const [nodes, setNodes] = useState<any>([]);
    const [serviceNodes, setServiceNodes] = useState<any>([]);
    const [filterText, setFilterText] = useState('');
    const [serviceList, setServiceList] = useState<ServiceTypeLite[]>([]);


    useEffect(() => {
        if (grouping === ServiceGroupingType.None && props.selections && nodes.length > 0) {
            setSelectedServicesFromNodes()
        }
    }, [props.selections, nodes])
    useEffect(() => {
        setRestrictAreaId(props.restrictAreaId)
    }, [props.restrictAreaId])

    useEffect(() => {
        present()
    }, [props.areaHierarchy])

    const setSelectedServicesFromNodes = () => {
        if (!props.selections) return

        props.selections.forEach(f => {
            addSelectedService(-f.id)
        })

    }

    const present = async () => {
        const areas = props.areaHierarchy
        populateByRegion(areas)
        getServices()
        setLoading(false)
    }
    const getServices = () => {
        if (grouping === ServiceGroupingType.None)
            fetchServiceList().then(s => { setServiceList(s); setLoading(false) })
    }

    const getTreePickerSelectionValue = (itemId: number): 'read' | 'write' | undefined => {
        const selectedItem = props.selections?.find(sel => sel.id === itemId);
        const selectedValue = selectedItem ? (selectedItem.userRoleTypeId === UserRoleType.Read ? 'read' : 'write') : undefined;
        return selectedValue;
    }
    const getUserPreviousSelectionData = (itemId: number): [UserRoleType | undefined, string|undefined] => {
        const selectedItem = props.selections?.find(sel => sel.id === itemId);
        if (!selectedItem) {
            return [undefined, undefined];
        }
        const selectedStatus = selectedItem.status === UserRoleType.Pending ? UserRoleType.Pending : (selectedItem.userRoleTypeId === UserRoleType.Read ? UserRoleType.Read : UserRoleType.Write);
        return [selectedStatus, selectedItem.requestedOn];
    }

    const populateByRegion = (areas: AreaHierarchyType) => {
        let treeNodes: any = []

        areas.areas.forEach(area => {
            var key = `${area.id}`
            const [status, requestedOn] = getUserPreviousSelectionData(area.id);
            var node = { key: key, label: area.name, icon1: 'pi pi-globe', iconAA: regionIcon, children: [], status: status, expanded: true, filterValue: `||${area.name}||`, userRole: getTreePickerSelectionValue(area.id), requestedOn: requestedOn }
            populateChildNodes(area, node)
            treeNodes.push(node)
        })

        if (restrictAreaId) {
            const a = getNodeByKey(treeNodes, restrictAreaId.toString())
            if (a) {
                a.expanded = true
                treeNodes = []
                treeNodes.push(a)
            }
        }
        setNodes(treeNodes)
    }

    const userRoleOptions = [
        { value: 'read', icon: 'pi pi-book' },
        { value: 'write', icon: 'pi pi-pencil' }
    ];

    const populateChildNodes = (area: AreaHierarchyChild, parentNode: any) => {

        area.services.forEach(c => {
            const key = `${-c.id}`
            const selectedRoleType = getTreePickerSelectionValue(-c.id);
            let status = undefined;
            let requestedOn = undefined;
            // if a child service is checked expand all parent items also
            if (selectedRoleType !== undefined) {
                [status, requestedOn] = getUserPreviousSelectionData(-c.id);
                let rootNode = parentNode;
                while (rootNode.parentNode !== undefined) {
                    rootNode.expanded = true;
                    rootNode = rootNode.parentNode;
                }
            }
            
            var node = {
                key: key, label: c.name, icon1: 'pi pi-box', iconAA: hospitalIcon, parentNode: parentNode, parentAreaId: area.id, canSelect: true,
                filterValue: `||${c.name}||${c.categoryId}||`, userRole: selectedRoleType, status: status, requestedOn:requestedOn
            }
            parentNode.children.push(node)
        });

        area.children.forEach(c => {
            const state = getTreePickerSelectionValue(c.id);
            let status = undefined;
            let requestedOn = undefined;
            // if a child area is checked expand all parent items also
            if (state !== undefined) {
                [status, requestedOn] = getUserPreviousSelectionData(c.id);
                let rootNode = parentNode;
                while (rootNode.parentNode !== undefined) {
                    rootNode.expanded = true;
                    rootNode = rootNode.parentNode;
                }
            }
            var node = { key: `${c.id}`, label: c.name, icon1: 'pi pi-globe', leaf: false, children: [], iconAA: regionIcon, parentNode: parentNode, canSelect: true, filterValue: `||${c.name}||`, userRole: getTreePickerSelectionValue(c.id), status: status, requestedOn: requestedOn }

            parentNode.children.push(node)

            populateChildNodes(c, node)
        });
    }
    const getServicesFromArea = (area: AreaHierarchyChild): [] => {
        let treeNodes: any = []
        area.services.forEach(c => {
            var node = { key: `${-c.id}`, label: c.name, icon1: 'pi pi-box', iconAA: hospitalIcon }
            treeNodes.push(node)
        });

        area.children.forEach(c => {
            treeNodes = treeNodes.concat(getServicesFromArea(c))
        });

        return treeNodes
    }
    const getNodeByKey: any = (nodeList: any[], key: string) => {
        if (!nodeList) {
            return undefined
        }
        let nodeFound = undefined
        nodeList.forEach(f => {
            if ((f.key as string) === (key as string)) {
                nodeFound = f;
                return f
            }
            var childNode = getNodeByKey(f.children, key)
            if (childNode) {
                nodeFound = childNode;
                return childNode
            }
        })
        return nodeFound
    }


    const setChildNodesPermission = (nodeList: any[], userRole: string) => {
        if (!nodeList) {
            return undefined
        }
        nodeList.forEach(f => {
            f.userRole = userRole
            setChildNodesPermission(f.children, userRole)
        })
    }

    const setNodeUserRole = (node: any, userRole: string) => {
        const newNodes: any = [...nodes]

        const selectedNode = getNodeByKey(newNodes, node.key)
        selectedNode.userRole = userRole

        if (selectedNode.parentNode && (!userRole || userRole === '')) {
            let parentNode = selectedNode.parentNode
            while (parentNode) {
                parentNode.userRole = undefined
                parentNode = parentNode.parentNode
            }

        }

        setChildNodesPermission(selectedNode.children, userRole)

        setNodes(newNodes)
        fireSelectionEvent(newNodes)
    }

    const handleAddService = (e: InputOptionType) => {
        addSelectedService(e.id)
    }
    const addSelectedService = (id: number) => {
        const key = `-${id}`

        const dupe = (serviceNodes as any[]).find(f => f.key === key)
        if (dupe) return

        const a = getNodeByKey(nodes, key)
        if(!a) return
        var newNodes: any = [...serviceNodes]
        a.userRole = 'read'
        newNodes.push(a)
        setServiceNodes(newNodes)
        fireSelectionEvent(newNodes)
    }
    const fireSelectionEvent = (nodeList: any[]) => {
        let selections: SelectedPermissionType[] = []
        getChildSelections(nodeList, selections)
        props.onChange(selections)
    }

    const getChildSelections = (nodeList: any[], selections: SelectedPermissionType[]) => {
        if (!nodeList) return
        nodeList.forEach(f => {
            let addNode = true
            
            if(f?.filterValue && filterText && filterText !== ''){
                addNode  = f.filterValue.toString().toUpperCase().indexOf(filterText.toUpperCase())>-1
            }
            if(addNode)
                addPermisionFromNode(f, selections)

            getChildSelections(f.children, selections)
        })
    }
    const addPermisionFromNode = (node: any, selections: SelectedPermissionType[]) => {
        if (!node.userRole || node.userRole === '') return
        const userRoleType = node.userRole === userRoleOptions[0].value ? UserRoleType.Read : UserRoleType.Write
        var id = parseInt(node.key)
        const entityType = id < 0 ? PermissionEntityType.Service : PermissionEntityType.Region
        const areaId = entityType === PermissionEntityType.Region ? id : undefined
        const serviceId = entityType === PermissionEntityType.Service ? Math.abs(id) : undefined
        selections.push({
            id: id, userRoleTypeId: userRoleType, type: entityType, name: node.label, userRole: node.userRole || '',
            areaId: areaId, serviceId: serviceId, parentAreaId: node.parentAreaId
        })
    }
    const removeNode = (node: any) => {

        setServiceNodes([...(serviceNodes as any[]).filter(f => f.key !== node.key)])
    }
    const handleChangePermission = (node: any, userRole?: string) => {
        let permission = userRole
        if (permission === undefined) {
            permission = node.userRole === userRoleOptions[0].value ? userRoleOptions[1].value :
                node.userRole = userRoleOptions[1].value && grouping === ServiceGroupingType.Region ? undefined : userRoleOptions[0].value
        }
        setNodeUserRole(node, permission || '')


    }
    const inputEl = React.useRef(null);

    const handleTextFilter = (s: string) => {
        (inputEl as any).current.filter(s)
        setFilterText(s)
    }
    const handleServiceCategoryFilter = (s: string) => {
        (inputEl as any).current.filter(s === '' ? s : `||${s}||`)
        setFilterText(s === '' ? s : `||${s}||`)
    }

    const onCollapse = (event:any) => {
        event.node.expanded = false;
    };

    const nodeTemplate = (node: any, options: any) => {
        let label = node.label
        return (<>
            <img src={node.iconAA} style={{ width: 18, height: 18, marginRight: 2 }} alt='' />
            <div style={{ width: grouping === ServiceGroupingType.Region ? '70%' : '60%' }}>

                <span className={options.className}>
                    {(grouping === ServiceGroupingType.Region && (node.canSelect)) && <MultiStateCheckbox  options={userRoleOptions} optionValue="value" value={node.userRole} onChange={(e) => handleChangePermission(node, e.value)} />}
                    {label}
                </span>
            </div>
            <div style={{ width: '30%', cursor: 'pointer' }}>
                <span className={options.className} onClick={() => handleChangePermission(node, undefined)}>
                    {grouping === ServiceGroupingType.None &&
                        <MultiStateCheckbox options={userRoleOptions} optionValue="value" value={node.userRole} />
                    }
                    <strong style={{ position: 'relative', top: -3 }}>{node.userRole}</strong>
                    {node.status !== undefined && 
                        <>
                        <br />
                        <small style={{ position: 'relative', top: -3 }}>{(node.status === UserRoleType.Pending ? 'Pending ' : 'Approved: ')}{formatDate(node.requestedOn)}</small>
                        </>    
                    }
                </span>
            </div>
            {grouping === ServiceGroupingType.None &&
                <div style={{ width: '20%', cursor: 'pointer' }} onClick={() => removeNode(node)}>
                    {/* <ActionButton label="Danger" icon="clear" size='small'>remove</ActionButton> */}
                    <i className="pi pi-times error-text" style={{ 'fontSize': '1.25em', }}></i>&nbsp;&nbsp;
                    <span style={{ position: 'relative', top: -3 }}>remove</span>
                </div>
            }
        </>
        )
    }


    if (grouping === ServiceGroupingType.None) {
        return <>
            <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                <TypeAheadTextbox label="Enter the name or postcode of services you require access" clearAfterSelection={true} options={serviceList as any} onChange={handleAddService}></TypeAheadTextbox>
            </div>
            <div style={{ width: '80%' }}>
                <Panel title='Selected Services'>
                    <ScrollPanel style={{ width: '95%', height: '288px' }}>

                        <Tree value={serviceNodes} loading={loading} nodeTemplate={nodeTemplate}
                            filter={false} />
                    </ScrollPanel>
                </Panel>
            </div>
        </>
    }
    return <>
        <ScrollPanel style={{ width: '100%', height: '450px' }}>
            <Tree ref={inputEl} id="aa" value={nodes} onCollapse={onCollapse} loading={loading} nodeTemplate={nodeTemplate} filterTemplate={<div><div style={{ width: '45%', float: 'left', paddingTop: 3 }}>
                <TextBox onChange={(e: any) => handleTextFilter(e.target.value)} label="Service or Area name filter" />
            </div>
                <div style={{ width: '45%', float: 'left', marginLeft: '5%', paddingTop: 3 }}><Dropdown optionName="serviceCategories" label="Service Type filter" includeBlankOption={true} onSelection={(e: any) => handleServiceCategoryFilter(e.target.value)} /></div>
                <div style={{ clear: 'both' }}></div>
            </div>}
                filter filterPlaceholder="Filter by region or service" filterBy='filterValue' filterMode='lenient'  />
            &nbsp;
        </ScrollPanel>
    </>


}

export default ServicePermissionSelector


