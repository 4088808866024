import React, {useEffect} from "react";
import {UserAccessRequestType, UserAccessRoleRequestType } from "../../../interfaces/user/iUser";
import {PendingUserAccessRequestResponseType} from "../../../interfaces/user/userAdminTypes";
import { ScrollPanel } from 'primereact/scrollpanel';

import Panel from "../../core/panel";
import {Grid} from '@mui/material'
import Dropdown from "../../core/dropdown";
import LabelText from "../../core/labelText";
import OKCancelButtons from "../../core/OKCancelButtons";
import { groupBy } from "../../../extensions/utils";

interface IProps {
    request?:UserAccessRequestType
    onCancel:()=>void
    onSaveAccessRequestApproval:(response:PendingUserAccessRequestResponseType[])=>void
  }

const PendingAccessRequestAction:  React.FC<IProps> = (props) => {
    const [pendingAccessRequest, setPendingAccessRequest] = React.useState(props.request)
    const [groupedAccessRequests, setGroupedAccessRequests] = React.useState<UserAccessRoleRequestType[]>()
    useEffect(() => {

        window.addEventListener('popstate', function() {
            props.onCancel()
        }, false);
   },[])
   useEffect(() => {
    

    // props.request?.permissions.forEach(f => {
    //     f.areaId=f.area.id
    // });
    const uniqueAreas = groupBy(props.request?.permissions, 'areaId')
    setGroupedAccessRequests(uniqueAreas)
    // setPendingAccessRequest(props.request)

   },[props.request])
    
   const handleSetAllApprovalStatus = (approvalStatus:number) => {
        if(!pendingAccessRequest) return
        const permsissions = [...pendingAccessRequest.permissions]
        permsissions.forEach(f => {
            f.approvalStatus = approvalStatus
        });
        const a = pendingAccessRequest
        a.permissions = permsissions
        setPendingAccessRequest({...a})
   }

   
   const handleSetRequestedPermissionForArea = (p:UserAccessRoleRequestType[], requirePermission:number) => {
    if(!pendingAccessRequest) return
    const permsissions = [...pendingAccessRequest.permissions]
    
    p.forEach(e => {
        permsissions[pendingAccessRequest.permissions.findIndex(f=> f.id === e.id)].permission.id = requirePermission
        
    });
    const a = pendingAccessRequest
        a.permissions = permsissions
    setPendingAccessRequest({...a})
}
const handleSetApprovalStatusForArea = (p:UserAccessRoleRequestType[], approvalStatus:number) => {
    if(!pendingAccessRequest) return
    const permsissions = [...pendingAccessRequest.permissions]

        p.forEach(e => {
            permsissions[pendingAccessRequest.permissions.findIndex(f=> f.id === e.id)].approvalStatus = approvalStatus
        });
    const a = pendingAccessRequest
    a.permissions = permsissions
    setPendingAccessRequest({...a})
}
const handleSetApprovalStatus = (p:UserAccessRoleRequestType, approvalStatus:number) => {
    if(!pendingAccessRequest) return
    const permsissions = [...pendingAccessRequest.permissions]
    
    permsissions[pendingAccessRequest.permissions.findIndex(f=> f.id === p.id)].approvalStatus = approvalStatus
    const a = pendingAccessRequest
    a.permissions = permsissions
    setPendingAccessRequest({...a})
}
const handleSetRequestedPermission = (p:UserAccessRoleRequestType, requirePermission:number) => {
    if(!pendingAccessRequest) return
    const permsissions = [...pendingAccessRequest.permissions]
    permsissions[pendingAccessRequest.permissions.findIndex(f=> f.id === p.id)].permission.id = requirePermission
    const a = pendingAccessRequest
    a.permissions = permsissions
    setPendingAccessRequest({...a})

}
 
const handleSetAllRequestedPermission = (requirePermission:number) => {
    if(!pendingAccessRequest) return
    const permsissions = [...pendingAccessRequest.permissions]
    permsissions.forEach(f => {
        f.permission.id = requirePermission
    });
    const a = pendingAccessRequest
    a.permissions = permsissions
    setPendingAccessRequest({...a})
}

const handleSave = () => {
    if(!pendingAccessRequest) return

    const a:PendingUserAccessRequestResponseType[] =[]
    pendingAccessRequest.permissions.filter(f=>f.approvalStatus).forEach(f => {
        a.push({id:f.id, approvalStatusTypeId:f.approvalStatus, userRoleTypeId: f.permission.id})
    });

    props.onSaveAccessRequestApproval(a)
}

    if(!groupedAccessRequests)
        return <></>
        

        
    return <>
    <Panel title={pendingAccessRequest?.fullName} toggleable={true}>
            <Grid container spacing={1} >
                    <Grid item xs={3}> 
                        <LabelText label="Email" text={pendingAccessRequest?.email}></LabelText>
                    </Grid>
                    <Grid item xs={9} >
                        <LabelText label="Intended Use" text={pendingAccessRequest?.applicationUse}></LabelText>
                    </Grid>
                    <Grid item xs={3}>
                        <LabelText label="Job Role" text={pendingAccessRequest?.jobRole}></LabelText>
                    </Grid>
                    <Grid item xs={3}>
                        <LabelText label="Service" text={pendingAccessRequest?.service}></LabelText>
                    </Grid>
            </Grid>
    </Panel>
    <Panel title="Requested permissions" >
        
    <Grid container spacing={1} >
        <Grid item xs={1}> </Grid>
                    <Grid item xs={4}> 
                    </Grid>
                    <Grid item xs={2} >
                        <Dropdown label="Set all Roles"  optionName="userRoles"  includeBlankOption={true} onSelection={(e)=>handleSetAllRequestedPermission(e.target.value as number)}/>
                    </Grid>
                    <Grid item xs={3} >
                        <Dropdown label="Set All Approval Statuses" optionName="approvalStatuses" includeBlankOption={true} onSelection={(e)=>handleSetAllApprovalStatus(e.target.value as number)}/>
                </Grid>
            </Grid>
            <ScrollPanel style={{ width: '100%', height: '250px',marginTop:3 }}>
            {Object.entries(groupedAccessRequests).map((g:any) => {
                return <>
                    <Grid container spacing={1} className="subtleHeader" style={{marginTop:3}}>
                <Grid item xs={1}> </Grid>
                    <Grid item xs={4} className="subtleHeaderCell"> 
                        <div style={{paddingTop:5}}>{g[1][0].area.name}</div>
                    </Grid>
                    <Grid item xs={2}>
                        <Dropdown label="" optionName="userRoles" onSelection={(e)=>handleSetRequestedPermissionForArea(g[1],e.target.value as number)}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Dropdown label="" optionName="approvalStatuses" includeBlankOption={true} value={g.approvalStatus} onSelection={(e)=>handleSetApprovalStatusForArea(g[1],e.target.value as number)}/>
                </Grid>
                </Grid>
                {g[1].map((p:any) => {
            return <Grid container spacing={1} className="highlight" style={{marginTop:3}}>
                <Grid item xs={1}> </Grid>
                    <Grid item xs={4}> 
                        <div style={{paddingTop:5}}>{p?.service?.name}</div>
                    </Grid>
                    <Grid item xs={2}>
                        <Dropdown label="" value={p.permission.id} optionName="userRoles" onSelection={(e)=>handleSetRequestedPermission(p,e.target.value as number)}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Dropdown label="" optionName="approvalStatuses" includeBlankOption={true} value={p.approvalStatus} onSelection={(e)=>handleSetApprovalStatus(p,e.target.value as number)}/>
                </Grid>
            </Grid>
        })} 
            </>
            })}
    {/* {groupedAccessRequests.map((p:any) => {
            return <Grid container spacing={1} className="highlight">
                <Grid item xs={1}> </Grid>
                    <Grid item xs={4}> 
                        <div style={{paddingTop:5}}>{p.service.name}</div>
                    </Grid>
                    <Grid item xs={2}>
                        <Dropdown label="" value={p.permission.id} optionName="userRoles" onSelection={(e)=>handleSetRequestedPermission(p,e.target.value as number)}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Dropdown label="" optionName="approvalStatuses" includeBlankOption={true} value={p.approvalStatus} onSelection={(e)=>handleSetApprovalStatus(p,e.target.value as number)}/>
                </Grid>
            </Grid>
        })} */}
                                </ScrollPanel>

    </Panel>
    
     {/* <ActionButton label="Back" onClick={props.onCancel}/>
     <ActionButton label="Save" onClick={handleSave}/> */}
     <Grid container spacing={1} >
        <OKCancelButtons onOK={handleSave} onCancel={props.onCancel} />
     </Grid>
    </>;
}

export default PendingAccessRequestAction;

