import { HttpPost, HttpResponse, HttpGet } from "./http";
import { getLocalSessionItem, removeSessionStorageItem, setJwt, setSessionStorageItem, setUser} from "./storageService";
import {UserLoginType, IJWTToken, JWTToken, UserType, createNewUser,UserSearchCriteriaType, UserSearchResultType, UserRegistrationAboutYou, UserAccessRequestType, PendingUserRegistrationType, UserRoleType} from '../interfaces/user/iUser';
import {fetchOptions} from './domainEntitiesService'
import { SelectedPermissionType } from "../components/core/servicePermissionSelector";
import { PendingUserAccessRequestResponseType } from "../interfaces/user/userAdminTypes";
import UserRoleValidator from "../validators/userRoleValidator";
import { SubscriptionSelectionOption } from "../components/core/userSubscriptionSelector";
import { createNewDomainEntity } from "../interfaces/domain/iDomainEntity";
import { allowAnnoymousAccess } from "../extensions/utils";

export const login = async (credentials: UserLoginType): Promise<HttpResponse<IJWTToken>> => {
    const response = await HttpPost<IJWTToken>(
        `authentication/authenticate`,
        credentials
      );
      if(response.result){
        setJwt(response.result.token)
        setUser(response.result.user)
        await fetchOptions()
      }
    return response || new JWTToken();
}

export const saveUserRedirect = (url?: string) : void => {
    const anonymous = allowAnnoymousAccess();
    if(!anonymous){
      setSessionStorageItem('login-redirect', url ?? window.location.pathname);
    }
      
}
export const getUserRedirect = (): string => {
    return getLocalSessionItem('login-redirect');
}
export const clearUserRedirect = (): void => {
    removeSessionStorageItem('login-redirect');
}

export const authenticationCanProceed = async (credentials: UserLoginType): Promise<boolean> => {
    const _creds = { ...credentials };
    _creds.password = _creds.passwordConfirmation = '';
    const response = await HttpPost<boolean>(
        `authentication/authentication-can-proceed`,
        _creds
    );
    if (response && response.result) {
        return response.result;
    }
    return false;
}

export const fetchUserById = async (id: number) : Promise<UserType> => {

  if(id ===0)
    return createNewUser()

  let response: HttpResponse<UserType>
    response = await HttpGet<UserType>(
      `user/${id}`
    );
    return response.parsedBody || createNewUser()
} 
export const fetchPendingAccessRequests = async () : Promise<HttpResponse<UserAccessRequestType | undefined>> => {


  let response: HttpResponse<UserAccessRequestType| undefined>
    response = await HttpGet<UserAccessRequestType| undefined>(
      `user/pending-access-requests`
    );
    return response 
} 
export const fetchPendingRegistrations = async () : Promise<HttpResponse<PendingUserRegistrationType | undefined>> => {


  let response: HttpResponse<PendingUserRegistrationType| undefined>
    response = await HttpGet<PendingUserRegistrationType| undefined>(
      `user/pending-user-registrations`
    );
    return response 
} 
export const saveAccessRequestStatus = async (requests: PendingUserAccessRequestResponseType[]) : Promise<HttpResponse<PendingUserAccessRequestResponseType[]>> => {
  let response: HttpResponse<PendingUserAccessRequestResponseType[]>
    response = await HttpPost<PendingUserAccessRequestResponseType[]>(
      `user/save-access-requests`,{requests:requests}
    );
    return response 
} 
export const fetchCurrentUserById = async () : Promise<UserType> => {

  let response: HttpResponse<UserType>
    response = await HttpGet<UserType>(
      `myprofile`
    );
    return response.parsedBody || createNewUser()
}

export const searchUsers = async (searchText: string, deleted: boolean = false): Promise<HttpResponse<UserType[]>> => {
    const response = await HttpGet<UserType[]>(
        `user/search?q=${searchText}&deleted=${deleted}`
    )

    return response
}
export const saveUser = async (user: UserType) : Promise<boolean> => {
  
  let response: HttpResponse<boolean>;
  try{
    response = await HttpPost<boolean>(
      `user`,
      user
    )
    
  }
  catch  {return false}
    return response.parsedBody || false
}
export const userForgotPassword = async (username: string) : Promise<HttpResponse<boolean>> => {
  const response = await HttpPost<boolean>(
      `registration/forgot-password`,
      { email: username}
    )
  return response
}
  
export const forcePasswordReset = async (userId:number, pwd:string) : Promise<HttpResponse<boolean>> => {
  const response = await HttpPost<boolean>(
      `user/foce-password-reset`,
      { userId:userId, password:pwd}
    )
  return response
}

export const isUserTokenValid = async (token:string) : Promise<boolean> => {
  let response = true;
    try{
    const httpResponse = await HttpPost<boolean>(
      `validateusertoken`,
      {token:token}
    )
    response = httpResponse.parsedBody || false
    }
    catch{response=false}

    return response
}
export const verifyUserByToken = async (token:string) : Promise<HttpResponse<boolean>> => {
    const response = await HttpPost<boolean>(
      `registration/verify-user`,
      {token:token}
    )
    return response
}
export const userEmailExists = async (id:number, email:string) : Promise<boolean> => {
  let response = true;

  if(!email || email === '')
    return true


    try{
    const httpResponse = await HttpPost<boolean>(
      `uservalidation`,
      {id:id, email:email}
    )
    response = httpResponse.parsedBody || false
    }
    catch{response=false}

    return !response
}
export const resetPassword = async (userLogin: UserLoginType) : Promise<HttpResponse<string>> => {
  let response: HttpResponse<string>;
    response = await HttpPost<string>(
      `registration/reset-password`,
      userLogin
    )
    return response
}
export const userSearch = async (searchCriteria: UserSearchCriteriaType) : Promise<UserSearchResultType[]> => {
  const response = await HttpPost<UserSearchResultType[]>(
      `userSearch`,
      searchCriteria
    );
  
  return response.parsedBody || []
}
export const generateUserVerificationEmail = async (userId:string) : Promise<HttpResponse<boolean>> => {
  const response = await HttpPost<boolean>(
      `user/generate-user-verification-email`,
      {userId:userId}
    );
  
  return response
}
export const registerUser = async (user: UserLoginType) : Promise<HttpResponse<string>> => {
  
  const response = await HttpPost<string>(
      `registration/register`,
      user
    )
   
    return response
}
export const saveRegisterAboutYou = async (user: UserRegistrationAboutYou) : Promise<HttpResponse<UserRegistrationAboutYou>> => {
  
    const response = await HttpPost<UserRegistrationAboutYou>(
      `registration/register-about-you`,
      user
    )
   
    return response
}
export const saveRegistrationPermissions = async (token:string,permissions:SelectedPermissionType[]) : Promise<HttpResponse<UserRegistrationAboutYou>> => {
  
  const response = await HttpPost<UserRegistrationAboutYou>(
    `registration/registration-permissions`,
    {permissions:permissions,token:token}
  )
 
  return response
}
export const saveUserPermissions = async (userId: number, permissions: SelectedPermissionType[]): Promise<HttpResponse<boolean>> => {
  
  const response = await HttpPost<boolean>(
    `user/update-access-requests`,
      { PermissionRequests:permissions,userId:userId}
  )
 
    return response;
}
export const saveUserSubscriptions = async (userId: number, subscriptions: SubscriptionSelectionOption[] | null,includeAutoGeneratedSubmissions:boolean): Promise<HttpResponse<boolean>> => {

    const _subscriptions = subscriptions?.map(sub => {
        return { ...sub, id: 0, userId: userId, deleted: false, requestedOn: new Date(), notificationType: createNewDomainEntity(), subscriptionResultType: createNewDomainEntity() };
    }) ?? [];

  const response = await HttpPost<boolean>(
    `user/update-user-subscriptions`,
      { Subscriptions: _subscriptions, UserId:userId, includeAutoGeneratedSubmissions:includeAutoGeneratedSubmissions}
  )
 
    return response;
}
export const fetchUserRoleList = async () : Promise<HttpResponse<UserRoleType[]>> => {
  const response = await HttpGet<UserRoleType[]>(
      `user/role-list`
    );
  return response
}
export const fetchUserPermissionsList = async (userId: number, includeDeleted: boolean = false): Promise<HttpResponse<SelectedPermissionType[]>> => {
    const response = await HttpGet<SelectedPermissionType[]>(
      `user/access-permissions/${userId}?includeDeleted=${includeDeleted ? true:false}`
    );
  return response
}
export const fetchUserByIdForEdit = async (userId: number): Promise<HttpResponse<UserType>> => {
    const response = await HttpGet<UserType>(
      `user/${userId}`
    );
  return response
}
export const fetchUserSubscriptionList = async (userId: number, includeDeleted: boolean = false): Promise<HttpResponse<SubscriptionSelectionOption[]>> => {
    const response = await HttpGet<SubscriptionSelectionOption[]>(
      `user/subscriptions/${userId}?includeDeleted=${includeDeleted ? true:false}`
    );

  return response
}
export const fetchUserRole  = async (id?:number) : Promise<HttpResponse<UserRoleType>> => {
  const response = await HttpGet<UserRoleType>(
      `user/role/${id}`
    );

    if(response.result){
      response.result.validator = new UserRoleValidator()
      response.result.permissionTypeIds = response.result?.permissions.map((m) => {return m.permissionTypeId})
    }

  return response
}

export const saveUserRole = async (role:UserRoleType) : Promise<HttpResponse<UserRoleType>> => {
  const s = {...role}
  s.validator=undefined;
  
  // if(s.permissions){
  //   s.permissionTypeIds = role.permissions.map((m) => {return m.permissionTypeId})
  // }
  s.permissions = [];
  const response = await HttpPost<UserRoleType>(
    `user/role`,s
  )
 
  return response
}

export const saveUserProfile = async (user: UserType): Promise<HttpResponse<UserType>> => {
    const postData = {
        id: user.id,
        administrator: user.administrator,
        deleted:user.deleted,
        email: user.email,
        username: user.email,
        fullName: user.fullName,
        jobRole: user.jobRole,
        serviceName: user.serviceName,
        serviceCategoryId: user.serviceCategoryId ?? 0,
        applicationUse: user.applicationUse,
        areaId:user.areaId
    }

    const response = await HttpPost<UserType>(
        `User/save-user-profile`, postData
    )

    return response
}
export const updateUserPassword = async (userId: number, newPassword:string, currentPassword?:string): Promise<HttpResponse<boolean>> => {
    const postData = {
        userId:userId,
        currentPassword: currentPassword,
        password: newPassword,
    }

    const response = await HttpPost<boolean>(
        `User/update-password`, postData
    )

    return response
}

export const saveServiceFilter = async (filter:string) : Promise<HttpResponse<boolean>> => {
  
  const response = await HttpPost<boolean>(
    `user/save-service-filter`,{filter:filter}
  )
 
  return response
}