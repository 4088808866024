import React, { useEffect, useState}  from 'react';
import { DataTable as PDataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate, formatDateTime } from '../../extensions/formatter';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ActionButton from './actionButton';
import { CircularProgress } from '@mui/material';
import { getFormResultOptionStyleFromId } from '../../services/domainEntitiesService';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { dateDiffFromNowInMinutes } from '../../services/utils';
 
export interface DataTableColumnProps {
    field:string
    header:string
    key?:string
    formatType?:'date'|'datetime'|'currency'|'yesno'|'yesno-inverse'|'form-result'|undefined
    style?: string | ((rowData: any) => string)
    formatCallBack?: (rowData: any) => boolean|string
}
interface DataTableProps {
    loading: boolean
    value?:any[]
    rowGroupingField?:string
    rowGroupMode?:'subheader'|undefined
    dataKey:string
    columns:DataTableColumnProps[]
    editButton?:boolean
    editButtonToggleField?:string
    editButtonToggleFieldInverse?:boolean
    editButtonLabel?:string
    editButtonIcon?:string
    deleteButton?:boolean
    onEdit?:(row:any) => void
    onDelete?:(row:any) => void
    onSelect?:(row:any) => void
    onNew?:() => void
    paginator?:boolean
    paginationRowsPerPage?:number
    filter?:boolean
    allowExport?: boolean,
    showGridlines?:boolean
    footer?:ReactJSXElement
  }

const DataTable:  React.FC<DataTableProps> = props => {
    const [loading, setLoading] = useState(props.loading);
    const [rows, setRows] = useState(props.value);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const dt = React.useRef<any>(null);

    useEffect(() => {setLoading(props.loading)},[props.loading]);
    useEffect(() => {setRows(props.value)},[props.value]);


    const headerTemplate = (data:any) => {
        return (
            <React.Fragment>
                <div style={{width:'30%',float:'left'}}>{data.fullName}</div>
                <div style={{width:'30%',float:'left'}}>{data.email}</div>
            </React.Fragment>
        );
    }
    const formatField = (formatType:'date'|'datetime'|'currency'|'yesno'|'yesno-inverse'|'form-result'|undefined, fieldName:string, rowData:any) => {
        return formatValue(formatType,rowData[fieldName], fieldName);
    }
    const formatValue = (formatType:'date'|'datetime'|'currency'|'yesno'|'yesno-inverse'|'form-result'|undefined, value:any, fieldName?:string) => {
        if(formatType=== 'date')
            return <span>{formatDate(new Date(value))}</span>
        if(formatType=== 'datetime')
        return <span>{formatDateTime(new Date(value))}</span>
        
        if(formatType=== 'yesno')
            return ((value as Boolean) === true) ? <span><i className="success-indicator pi pi-check"></i></span>:<span><i className="error-indicator pi pi-times"></i></span>;
        if(formatType=== 'yesno-inverse')
            return ((value as Boolean) === false) ? <span><i className="success-indicator pi pi-check"></i></span>:<span><i className="error-indicator pi pi-times"></i></span>;

        return value;
    }
    const handleEdit = (rowData:any) =>{
        if(props.onEdit)
            props.onEdit(rowData)
    }
    const handleDelete = (rowData:any) =>{
        if(props.onDelete)
            props.onDelete(rowData)
    }
    const handleSelection = (rowData:any) =>{
        if(props.onSelect)
        props.onSelect(rowData)
    }
    // const clearFilter1 = () => {
    //     setGlobalFilterValue1('');
    // }
    const onGlobalFilterChange1 = (e:any) => {
        const value = e.target.value;
        
        setGlobalFilterValue1(value);
    }
    const exportCSV = () => {
        if(!dt || !dt.current || !dt.current.exportCSV) return
        dt.current.exportCSV();
    }
    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                {/* <div style={{float:'left',width:'20%'}}>
                <Button disabled={globalFilterValue1===''} type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} />
                </div> */}
                <div style={{float:'left',width:'20%'}}>
                    {props.onNew && <ActionButton label="New" severity="success" onClick={()=>{if(props.onNew) props.onNew()}}/>}
                </div>
                <div style={{float:'left',width:'20%'}}>
                    {props.allowExport && <ActionButton label="Export" severity="help" onClick={exportCSV} />}
                </div>
                <div style={{justifyContent:'right',width:'60%',display:'flex'}}>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" />
                </span>
                </div>
            </div>
        )
    }
    const actionBodyTemplate = (rowData:any) => {

        if(props.editButtonToggleField && ((rowData[props.editButtonToggleField] && !props.editButtonToggleFieldInverse) || (rowData[props.editButtonToggleField] && props.editButtonToggleFieldInverse)))
            return <></>

        return (
            <React.Fragment>
                {props.editButton && <Button icon={props.editButtonIcon ? props.editButtonIcon :"pi pi-pencil"} className="p-button-rounded p-button-success mr-2" label={props.editButtonLabel} onClick={()=>handleEdit(rowData)} />}
                {props.deleteButton && <><Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={()=>handleDelete(rowData)} title="Delete" /></>}
            </React.Fragment>
        );
    }

    const styledColumnTemplate = (rowData:any,col:DataTableColumnProps) => {
        const fieldNames = col.field.split('.')
        const fieldValue = fieldNames.length<2 ? rowData[col.field] : rowData[fieldNames[0]] ? rowData[fieldNames[0]][fieldNames[1]] : undefined
        
      
        if(col.formatType=== 'form-result' && rowData.form && rowData.reportingResultOption) {

            let minsSinceLastUpdate =0;
            if(rowData?.lastUpdatedOn){
                minsSinceLastUpdate=  dateDiffFromNowInMinutes(rowData.lastUpdatedOn)
            }


            const style = getFormResultOptionStyleFromId(rowData.reportingResultOption.id)
            if(minsSinceLastUpdate>120 && style)
                style.className = "form-result-expired"
            return  <div className={style?.className} style={{color:style?.fontColour, fontFamily:style?.fontName, 
                            fontSize:style?.fontSize, fontWeight:style?.fontWeight, background:style?.background}}>
                        {fieldValue}
                    </div>
        }
        
        if (!col.style) return <>{fieldValue}</>
        let _style = '';
        if (typeof (col.style) === 'function') {
            _style = col.style(rowData);
        } else {
            if(rowData[col.style])
                _style = rowData[col.style].className;
        }

        return (
            <div className={_style}>
                {fieldValue}
            </div>
        );  

    }

    if(loading)
      return <CircularProgress color="secondary" />
    
    return <PDataTable showGridlines={props.showGridlines ?? undefined } ref={dt} header={props.filter? renderHeader1 : undefined} filters={{global: { value: globalFilterValue1, matchMode: 'contains' }}}
    footer={props.footer} 
       paginator={props.paginator} rows={props.paginationRowsPerPage} rowsPerPageOptions={[10,20,50]} stripedRows={true} value={rows} groupRowsBy={props.rowGroupingField} rowGroupMode={props.rowGroupMode} selectionMode="single" onSelectionChange={handleSelection} dataKey={props.dataKey} responsiveLayout="scroll" rowGroupHeaderTemplate={headerTemplate} >
                    {props.columns.map((m) => {
                        let key = m.key || null;
                        if (m.formatCallBack !== undefined) {
                            return <Column key={key} header={m.header}  body={(rowdata: any) => {return (m.formatType !== undefined) ? formatValue(m.formatType, (m.formatCallBack?.(rowdata) ||"")) : (m.formatCallBack?.(rowdata) || "")}} ></Column>
                        }
                        if(m.style || m.formatType=== 'form-result')
                            return <Column body={(rowData:any)=> styledColumnTemplate(rowData, m)} header={m.header}  field={m.field} ></Column>
                        if(!m.formatType)
                            return <Column sortable  key={key} header={m.header} field={m.field} ></Column>
                        return <Column key={key} header={m.header} field={m.field} body={(rowdata) => formatField(m.formatType, m.field, rowdata)}/>
                    })}
                    {(props.editButton || props.deleteButton) && <Column body={actionBodyTemplate}  exportable={false} style={{ minWidth: '5rem' }}></Column>
                    }
                    
                </PDataTable>
}

export default DataTable;