import React, {useEffect} from "react"
// import DataGrid, {DataGridColumnFormatType, IDataGridColumn} from '../core/dataGrid'
import DataTable from '../../core/dataTable'
import { ServiceSearchResultType } from "../../../interfaces/system/serviceType"


interface ServiceListProps {
    list?:ServiceSearchResultType[]
    onSelection?: (id: number) => void 
    onNew: () => void 
  }

const ServiceList:  React.FC<ServiceListProps> = (params) => { 
useEffect(() => {
  setList(params.list)
},[params.list])

const [list,setList] = React.useState(params.list)
   

  const handleRowSelection =(id:number) => {
    
    if(params.onSelection)
        params.onSelection(id)
  }
  
  return <DataTable dataKey="id" loading={false} columns={[{header:"Id", field:"id"},
  {header:"Name", field:"name"},
  {header:"Provider Type", field:"serviceCategoryName"},
  {header:"Code", field:"code"},
  {header:"Active", field:"deleted", formatType:"yesno-inverse"},
  {header:"Display Order", field:"sortOrder"}]}  paginationRowsPerPage={20} value={list} onEdit={(row)=>handleRowSelection(row['id'])} 
  editButton={true} paginator={true} filter={true} onNew={params.onNew} allowExport={true}
  />

}

export default ServiceList;