import React, { useState, useEffect } from "react";
import Panel from "../core/panel";
import { createNewFormSubmission, FormFieldActionTypeEnum, FormFieldDisplayTypeEnum, FormFooterSubmissionFieldType, FormResultOptionType, FormSubmissionFieldType, FormSubmissionType, FormType, FormTypeEnum, primeFormForSubmission } from "../../interfaces/system/formType"
import FormSubmissionField from "./form-submission-field";
import FormSubmissionFooter from "./form-submission-footer";
import Loading from "../core/loading";
import OKCancelButtons from "../core/OKCancelButtons";
import { DemandAndCapacityMetrics } from "../../interfaces/reporting/demandAndCapacity";
import FormSubmissionSummary from "../home/form-submission-summary";
import { Grid } from "@mui/material";
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';

interface FormSubmissionProps {
    form?: FormType
    demandAndCapacityMetrics?: DemandAndCapacityMetrics
    showFooter?: boolean
    disabled?:boolean
    gaugeWidth?: string
    opelAutomation?:boolean
    align?: 'left' | 'right'
    onSave: (FormSubmissionType: FormSubmissionType, valid: boolean) => void
    onCancel: () => void
}


const FormSubmission: React.FC<FormSubmissionProps> = (props) => {
    const [hideScrollIndicator, setHideScrollIndicator] = useState(true);
    const [disabled, setDisabled] = useState(props.disabled);
    const [showSummary, setShowSummary] = useState(true);

    const hideScrollIndicatorAfterQuestionCount = 4;
    const hideScrollIndicatorIfBelowQuestionCout = 3;

    useEffect(() => {
        if (props.form) {
            const formSubmission = createNewFormSubmission(props.form);
            setForm({ ...props.form })
            setFormSubmission({ ...formSubmission })
            setHideScrollIndicator((formSubmission?.fields?.length ?? 0) < hideScrollIndicatorIfBelowQuestionCout);

            setShowSummary(props.form?.formType?.id === FormTypeEnum.Weighted && !props.opelAutomation)
        }
    }
        , [props.form])

        useEffect(() => {
            setDisabled(props.disabled)
        }, [props.disabled])
        
        useEffect(() => {
            setShowSummary(props.opelAutomation !== undefined && !props.opelAutomation)
        }, [props.opelAutomation])

    useEffect(() => {
        window.addEventListener('popstate', function () {
            handleCancel()
        }, false);
    }
        , [])
    const [form, setForm] = useState<FormType>()
    const [formSubmission, setFormSubmission] = useState<FormSubmissionType>()
    const [score, setScore] = useState(0)
    const [opelScore, setOpelScore] = useState(0)
    const [submittedCount, setSubmittedCount] = useState(0)
    const [footerIsValid, setFooterIsValid] = useState(false)
    const [footerFields, setFooterFields] = useState<FormFooterSubmissionFieldType[]>([])
    const [formResultOption, setFormResultOption] = useState<FormResultOptionType>()
    const [dynamicFieldsToShow, setDynamicFieldsToShow] = useState<number[]>([])
    const [itemsComplete, setItemsComplete] = useState<number[]>([]);
    


    const handleFieldUpdate = (index: number, submissionField: FormSubmissionFieldType) => {
        if (!formSubmission?.fields) return
        if (!itemsComplete?.find(x => x === submissionField.fieldId)) {
            let _itemsComplete = itemsComplete;
            _itemsComplete.push(submissionField.fieldId);
            setItemsComplete(_itemsComplete);
            if ((formSubmission?.fields?.length ?? 0) < hideScrollIndicatorIfBelowQuestionCout || _itemsComplete.length > hideScrollIndicatorAfterQuestionCount) {
                setHideScrollIndicator(true);
            }
        }
        formSubmission.fields[index] = submissionField
        setFormSubmission({ ...formSubmission, fields: formSubmission.fields })
        let score = 0
        let opelScore = 0
        formSubmission.fields.forEach((f) => {
            score += f.score || 0
            opelScore += f.opelScore||0
        })
        setScore(score)
        setOpelScore(opelScore)

        if (!submissionField.fieldOptionId) return
        const field = form?.fields.find(f => f.id === submissionField.fieldId)
        if (!field) return

        //first reset what should display
        const optionsWithActions = field?.options?.map(f => f.formFieldActionFieldId || -99)
        let fieldsToShow = dynamicFieldsToShow

        if (optionsWithActions && optionsWithActions.length > 0) {
            optionsWithActions.forEach(f => {
                const indx = fieldsToShow.findIndex(ff => ff === f)
                if (indx > -1)
                    fieldsToShow = fieldsToShow.splice(indx, 1)
            })
        }

        const fieldOption = field.options.find(f => f.id === submissionField.fieldOptionId)
        if (!fieldOption || !fieldOption.formFieldActionType) return


        if (fieldOption.formFieldActionFieldId && fieldOption?.formFieldActionType.id === FormFieldActionTypeEnum.ShowDynamicField) {
            fieldsToShow.push(fieldOption.formFieldActionFieldId)
        }
        setDynamicFieldsToShow([...fieldsToShow])
    }
    const handleCancel = () => {
        props.onCancel()
    }
    const handleSaveSubmission = () => {
        setSubmittedCount(submittedCount + 1)
        let isValid = true
        formSubmission?.fields?.filter(f => f.field?.mandatory).forEach((f) => {
            f.isValid = (f.value !== undefined && f.value) || (f.fieldOptionId !== undefined && f.fieldOptionId)
            isValid = isValid && (f.isValid || false)
        })

        const f = primeFormForSubmission(formSubmission)
        f.score = score
        f.opelScore = opelScore
        f.footerFields = footerFields
        f.formResultOptionId = formResultOption?.formResultOptionId
        f.formId = form?.id || 0

        const validFooter = footerIsValid || form?.formType?.id !== FormTypeEnum.Weighted

        isValid = isValid && validFooter

        if (!isValid) {
            const firstInvalid = formSubmission?.fields?.findIndex(f => !f.isValid)
            if (firstInvalid && firstInvalid > -1) {

                const a = document.getElementById(`field_${firstInvalid}`)
                if (a)
                    a.scrollIntoView({
                        behavior: 'smooth'
                    });
            }
        }

        props.onSave(f, isValid)
    }
    const handleFooterUpdated = (fields: FormFooterSubmissionFieldType[], valid: boolean) => {
        setFooterIsValid(valid)
        setFooterFields(fields)
    }
    const handleFormResultOptionSet = (f?: FormResultOptionType) => {
        setFormResultOption(f)
    }
    
    const progressBarTemplate = (value: number) => {
        const totalItemsCount = fieldCount()
        const itemsCompleteCount = itemsComplete?.length ?? 0;
        return (
            <React.Fragment>
                {itemsCompleteCount} {itemsCompleteCount > 2 ? 'of' : '/'} {totalItemsCount} {itemsCompleteCount > 2 ? 'fields complete' : ''}
            </React.Fragment>
        );
    };
    const fieldCount = ()=>{
        return formSubmission?.fields?.filter(f => f?.field?.displayType?.id?.toString()!== FormFieldDisplayTypeEnum.Dynamic.valueOf().toString()).length ?? 0;
    }
    const progressBarGetPercentComplete = (): number => {
        const totalItemsCount = fieldCount()
        const itemsCompleteCount = itemsComplete?.length ?? 0;
        return Math.ceil((itemsCompleteCount / totalItemsCount) * 100);
    }
    const scrollPanel = document.querySelector('.p-scrollpanel-content');
    if (scrollPanel) {
        scrollPanel.addEventListener("scrollend", () => {
            if (scrollPanel.scrollTop > (scrollPanel.scrollHeight/2)) {
                setHideScrollIndicator(true);
            }
        });
    }
    const scrollToNextQuestion = () => {
        const itemsCompleteCount = itemsComplete?.length ?? 0;
        const nextQuestionIndex = itemsCompleteCount <= 3 ? 3 : itemsCompleteCount;
        const questionContainers = document.querySelectorAll('.form-field-container');
        const nextQuestionContainer = questionContainers[nextQuestionIndex];
        if (nextQuestionContainer) {
            nextQuestionContainer.scrollIntoView({ behavior: "smooth" })
        }
    }

    if (!form)
        return <Loading useBackdrop={false} loading={true} />

    let fieldNumber = 0
    return <>

        <Panel title="" scrollHeight="65%" noContentPadding={true} className="scrollbar-always">
            <div className="form-entry-progress">
                <ProgressBar value={progressBarGetPercentComplete()} displayValueTemplate={progressBarTemplate} color={progressBarGetPercentComplete() > 99 ? '#009688' : '#4074cc'}></ProgressBar>
            </div>
            <Grid container columnSpacing={1} spacing={2} style={{ padding: '0 20px' }}>
                {formSubmission?.fields?.map((f: FormSubmissionFieldType, index) => {
                    if (f?.field?.displayType?.id !== FormFieldDisplayTypeEnum.Dynamic)
                        fieldNumber = fieldNumber + 1
                    return <Grid xs={12} style={{ cursor: 'pointer' }}>
                        <FormSubmissionField field={f} demandAndCapacityMetrics={props.demandAndCapacityMetrics} showDynamicField={dynamicFieldsToShow.indexOf(f.fieldId) > -1} index={index} fieldNumber={fieldNumber} hasError={(f.isValid === undefined ? undefined : !f.isValid)} onUpdated={handleFieldUpdate} />
                    </Grid>

                })}
                <Grid xs={12}>
                    {form?.footer?.id && <hr />}
                </Grid>
                <Grid xs={12}>
                    {form?.footer?.id && <FormSubmissionFooter submitted={submittedCount} form={formSubmission} onUpdate={handleFooterUpdated} />}
                </Grid>
                <Grid xs={12}>
                    <hr />
                </Grid>
                <Grid xs={6}>
                    {(showSummary) 
                    && <FormSubmissionSummary score={score} formResult={form?.result} formId={form?.id} showFooter={false} gaugeWidth="225px" className="large" padding={10} onFormResultOptionUpdated={handleFormResultOptionSet} />}
                </Grid>
                <Grid xs={6}>
                    {(showSummary)
                     && <FormSubmissionSummary opelScore={opelScore} formResult={form?.opelResult} formId={form?.id} showFooter={false} gaugeWidth="225px" className="large" padding={10} onFormResultOptionUpdated={()=>{}} />}
                </Grid>
            </Grid>
        </Panel>
        {!hideScrollIndicator &&
            <Grid xs={12}>
                <div className="indicate-more-scroll">
                    <Tooltip target=".pi-angle-double-down" />
                    <i className="pi pi-angle-double-down" data-pr-position="top" onClick={() => { scrollToNextQuestion() }} data-pr-tooltip="Don't forget to complete all form fields by scrolling using the scroll bar to the right."></i>
                </div>
            </Grid>
        }

        <Grid container spacing={1} justifyContent="center" style={{ padding: '15px 5px' }}>
            <Grid item xs={12}>
                <OKCancelButtons okDisabled={disabled} onOK={handleSaveSubmission} onCancel={handleCancel} okLabel="Save" />
            </Grid>
        </Grid>

    </>;
}


export default FormSubmission;

