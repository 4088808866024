import React, { useEffect } from 'react'
import { Route, Routes as AA, useLocation } from "react-router-dom";
import ErrorContainer from '../../components/system/errorContainer'
import HomeContainer from '../../containers/homeContainer'
import UserAdminContainer from '../../containers/admin/userAdminContainer'
import ServiceAdminContainer from '../../containers/admin/serviceAdminContainer'
import { isUserAuthenticated, userRoleIsAdmin,userRoleIsAdminFromUserType } from "../../services/storageService";
import AuthenticationContainer from '../../containers/authenticationContainer'

import { allowAnnoymousAccess } from '../../extensions/utils'
import FormBuilderContainer from '../../containers/admin/form-builder-container';
import FormContainer from '../../containers/form-container';
import TaskContainer from '../../containers/admin/taskContainer';
import DemandAndCapacityReport from '../reports/demand-and-capacity/demand-and-capacity-report';
import CapacityGridBuilderContainer from '../../containers/admin/capacity-grid-builder-container';
import CapacityGridContainer from '../../containers/capacity-grid-container';
import { updatePageTitle } from '../../services/utils';
import ReportingHomeContainer from '../../containers/reporting/reporting-home';
import AccountContainer from '../../containers/accountContainer';
import { CapacityGridSharedFieldsBuilderContainer } from '../../containers/admin/capacity-grid-shared-fields-builder-container';
import FormReportContainer from '../../containers/reporting/form/form-report-container';
import Login from '../user-registration/login';
import CapacityGridReportContainer from '../../containers/reporting/capacity-grid/capacity-grid-report-container';
import { DistributionListContainer } from '../../containers/admin/distribution-list-container';
import { DistributionListMembersContainer } from '../../containers/admin/distribution-list-members-container';
import { FormResultBuilderContainer } from '../../containers/admin/form-result-builder-container';
import { AdminContainer } from '../../containers/admin/admin-container';
import { SystemMessageAdminContainer } from '../../containers/admin/system-message-admin-container';
import OpelRportContainer from '../../containers/reporting/opel/opel-report-container';
import { AccessDeniedContainer } from '../../containers/admin/access-denied-container';
import SystemReportContainer from '../../containers/reporting/system/system-report-container';
import { UserType } from '../../interfaces/user/iUser';
import { NotificationBroadcastContainer } from '../../containers/admin/notification-broadcast-container';
import { DocumentLibraryContainer } from '../../containers/admin/doc-library-container';
import { ActionCardBuilderConatiner } from '../../containers/admin/action-card-builder-container';
import { HelpContainer } from '../../containers/helpContainer';

interface RouteProps {
    onSuccessfulLogin: (user:UserType) => void
}

const Routes: React.FC<RouteProps> = (props) => {
    const location = useLocation();
    const [isAdmin, setIsAdmin] = React.useState(userRoleIsAdmin())

    useEffect(() => {
        // clear page title on every route change
        updatePageTitle("")
    }, [location]);
    

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', function (event){
    //    window.history.pushState(null, document.title,  window.location.href);
    // });

    const handleSuccessFulLogin = (u:UserType)=>{
        setIsAdmin(userRoleIsAdminFromUserType(u))
        props.onSuccessfulLogin(u)
    }
    const isAnnoymous = allowAnnoymousAccess();
    
    return <AA>
        <Route path="/login" element={<Login onSuccessfulLogin={handleSuccessFulLogin} />}>

        </Route>
        <Route path="/home" element={<HomeContainer />}>                  </Route>


        <Route path="/admin" element={ isAdmin ? <AdminContainer/> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/user-pending-registration" element={isAdmin ? <UserAdminContainer mode='pending-registrations' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/user-pending-access" element={isAdmin ? <UserAdminContainer mode='pending-access' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/user-pending-action" element={isAdmin ? <UserAdminContainer mode='pending-access-action' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/user-role" element={isAdmin ? <UserAdminContainer mode='role-list' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/user-role/:userRoleId" element={isAdmin ? <UserAdminContainer mode='role-edit' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/user" element={isAdmin ? <UserAdminContainer mode='list' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/user/:userId" element={isAdmin ? <UserAdminContainer mode='edit' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/distribution-lists" element={isAdmin ? <DistributionListContainer mode="list" /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/distribution-lists/:id" element={isAdmin ? <DistributionListContainer mode="edit" /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/distribution-list-members" element={isAdmin ? <DistributionListMembersContainer mode="list" /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/system-messages" element={isAdmin ? <SystemMessageAdminContainer mode="list" /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/system-messages/:id" element={isAdmin ? <SystemMessageAdminContainer mode="edit" /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/notification-broadcasts/:id" element={isAdmin ? <NotificationBroadcastContainer mode="edit" /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/notification-broadcasts" element={isAdmin ? <NotificationBroadcastContainer mode="list" /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/document-library" element={isAdmin ? <DocumentLibraryContainer /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/action-cards" element={isAdmin ? <ActionCardBuilderConatiner mode="list" /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/action-cards/:id" element={isAdmin ? <ActionCardBuilderConatiner mode="edit" /> : <AccessDeniedContainer />}></Route>

        <Route path="/admin/form" element={isAdmin ? <FormBuilderContainer mode='list' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/form/:formId" element={isAdmin ? <FormBuilderContainer mode='form-builder' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/form-reporting-category" element={isAdmin ? <FormResultBuilderContainer mode='list' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/form-reporting-category/:id" element={isAdmin ? <FormResultBuilderContainer mode='edit' /> : <AccessDeniedContainer />}></Route>

        <Route path="/admin/capacity-grid" element={isAdmin ? <CapacityGridBuilderContainer mode='list' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/capacity-grid/:id" element={isAdmin ? <CapacityGridBuilderContainer mode='list' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/capacity-grid-shared-fields" element={isAdmin ? <CapacityGridSharedFieldsBuilderContainer mode='list' /> : <AccessDeniedContainer />}></Route>

        <Route path="/admin/service" element={isAdmin ? <ServiceAdminContainer mode='list' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/service/:serviceId" element={isAdmin ? <ServiceAdminContainer mode='edit' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/areas" element={isAdmin ? <ServiceAdminContainer mode='areas' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/service-categories" element={isAdmin ? <ServiceAdminContainer mode='categories' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/automated-reports" element={isAdmin ? <ServiceAdminContainer mode='automated-report-list' /> : <AccessDeniedContainer />}></Route>
        <Route path="/admin/automated-reports/:automatedReportId" element={isAdmin ? <ServiceAdminContainer mode='automated-report-list' /> : <AccessDeniedContainer />}></Route>


        <Route path="/admin/service-category/:serviceCategoryId" element={isAdmin ?<ServiceAdminContainer mode='edit-category' /> : <AccessDeniedContainer />}></Route>
        

        <Route path="/forgot-password" element={<AuthenticationContainer />}></Route>
        <Route path="/register" element={<AuthenticationContainer mode='register' />}></Route>
        <Route path="/verify-user" element={<AuthenticationContainer mode='verify' />}></Route>
        <Route path="/verify-user/:token" element={<AuthenticationContainer mode='verify' />}></Route>
        <Route path="/registration-access-reqirements/:token" element={<AuthenticationContainer mode='access-requirements' />}></Route>
        <Route path="/registration-about-you/:token" element={<AuthenticationContainer mode='about-you' />}></Route>

        <Route path="/form-submission/:serviceId/:formIdParam?" element={<FormContainer />}></Route>

        <Route path="/capacity-grid-submission/:serviceId" element={<CapacityGridContainer />}></Route>
        <Route path="/capacity-grid-submission" element={<CapacityGridContainer />}></Route>

        {/* reporting */}
        <Route path="/error/:id" element={<ErrorContainer />}></Route>
        <Route path="/reporting" element={<ReportingHomeContainer />}></Route>
        <Route path="/reporting/form/compliance" element={<FormReportContainer mode='compliance' />}></Route>
        <Route path="/reporting/form/triggers" element={<FormReportContainer mode='triggers' />}></Route>
        <Route path="/reporting/form/trend" element={<FormReportContainer mode='trend' />}></Route>
        <Route path="/reporting/form/level" element={<FormReportContainer mode='level' />}></Route>
        <Route path="/reporting/form/service-status" element={<FormReportContainer mode='status' />}></Route>
        <Route path="/reporting/form/data-capture" element={<FormReportContainer mode='data-capture' />}></Route>
        <Route path="/reporting/form/sitrep" element={<FormReportContainer mode='sitrep' />}></Route>

        <Route path="/reporting/capacity-grid/compliance" element={<CapacityGridReportContainer mode='compliance' />}></Route>
        <Route path="/reporting/capacity-grid/summary" element={<CapacityGridReportContainer mode='summary' />}></Route>
        <Route path="/reporting/capacity-grid/:serviceId" element={<CapacityGridReportContainer mode='daily' />}></Route>
        <Route path="/reporting/capacity-grid" element={<CapacityGridReportContainer mode='daily' />}></Route>
        <Route path="/reporting/capacity-grid-history/:serviceId" element={<CapacityGridReportContainer mode='historic' />}></Route>
        <Route path="/reporting/capacity-grid-history" element={<CapacityGridReportContainer mode='historic' />}></Route>

        <Route path="/reporting/opel/submission" element={<OpelRportContainer mode='submission' />}></Route>
        
        <Route path="/reporting/system/audit" element={<SystemReportContainer mode='audit' />}></Route>
        <Route path="/reporting/system/email" element={<SystemReportContainer mode='email-audit'/>}></Route>
        <Route path="/reporting/system/user-subscription-report" element={<SystemReportContainer mode='user-subscription'/>}></Route>
        <Route path="/reporting/system/capacity-grid-import-audit" element={<SystemReportContainer mode='necs-audit'/>}></Route>

        <Route path="/reporting/system/automated-report/:identifier/:generatedOn" element={<SystemReportContainer mode='automated-report'/>}></Route>

        <Route path="/demandandcapacity/:siteCode" element={<DemandAndCapacityReport />}></Route>
        <Route path="/demandandcapacity/emergency/:siteCode" element={<DemandAndCapacityReport mode='emergency' />}></Route>
        <Route path="/demandandcapacity/ambulance/:siteCode" element={<DemandAndCapacityReport mode='ambulance' />}></Route>

        <Route path="/reset-password/:token" element={<AuthenticationContainer mode='reset' />}></Route>
        <Route path="/account" element={<AccountContainer />}></Route>


        <Route path="/help/list" element={<HelpContainer />}></Route>


        <Route path="/admin/tasks" element={isAdmin ? <TaskContainer /> : <AccessDeniedContainer />}></Route>

        {(!isUserAuthenticated() && !isAnnoymous) ? <Route path="*" element={<Login onSuccessfulLogin={props.onSuccessfulLogin} />}></Route> :
            <Route path="/" element={<HomeContainer />}> </Route>}
    </AA>

}

export default Routes;