import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { CapacityGridDailyReportFilterOptions, CapacityGridSubmissionOverviewType, CapacityGridType } from "../../../interfaces/system/capacityGridType";
import { ServiceType } from "../../../interfaces/system/serviceType";
import { fetchCapacityGridSubmissionList, fetchDailyReport } from "../../../services/reporting/capacity-grid-service";
import { ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import {  filterByCapacityGridOptions } from "../../../services/systemService";
import BasicReportFilter, { BasicReportCriteria } from "../basic-report-filter";
import { CapacityGridDailyReportTable } from "./capacity-grid-daily-report-table";
import { CapacityGridHistoricReport } from "./capacity-grid-historic-report";

export interface CapacityGridDailyReportProps {
    onLoading?: () => void
    onLoaded?: () => void
    areaHierarchy: AreaHierarchyType
    onError?: (message: string) => void
}
export function CapacityGridDailyReport(props: CapacityGridDailyReportProps) {
    // state vars
    const [capacityGrids, setCapacityGrids] = useState<CapacityGridType[]>();
    const [services, setServices] = useState<ServiceType[]>();
    const [submissionData, setSubmissionData] = useState<CapacityGridSubmissionOverviewType[]>();
    const [areaHierarchy, setAreaHierarchy] = useState(props.areaHierarchy);
    const [hideServiceSelector, setHideServiceSelector] = useState(false);
    const [filterOptions, setFilterOptions] = useState<CapacityGridDailyReportFilterOptions>({});
    const [selectedParentId, setSelectedParentId] = useState<number | undefined>();

    // query params
    let { serviceId } = useParams() || 0;
    let areaId = 0;
    const { search } = useLocation();

    useEffect(() => {
        // check for service/area id in url
        const queryParams = new URLSearchParams(search);
        if (queryParams.has('areaId')) {
            areaId = parseInt(queryParams.get('areaId') ?? '');
        }
        // if so setup a default search
        let svcId = parseInt(serviceId ?? '0');
        let defaultFilterOptions: CapacityGridDailyReportFilterOptions = {};
        if (areaId > 0 || svcId > 0) {

            setHideServiceSelector(true);
            if (areaId > 0) {
                defaultFilterOptions = { areaId: [areaId] };
            }
            if (svcId > 0) {
                defaultFilterOptions = { serviceId: [svcId] };
            }
        }
        // otherwise create an empty filter object to trigger get data
        setFilterOptions(defaultFilterOptions);
    }, []);

    useEffect(() => {
        setAreaHierarchy(props.areaHierarchy)
    }, [props.areaHierarchy]);
    useEffect(() => {
        if (filterOptions && Object.keys(filterOptions).length) {
            loadServices();
        }
    }, [filterOptions]);

    useEffect(() => {
        if (services) {
            loadCapacityGrids();
        }
    }, [services]);


    // extract only the capacity grids that have a service from the returned list of services
    const filterCapacityGrids = (grids: CapacityGridType[]): CapacityGridType[] => {
        if (services && grids) {
            let capacityGridIds: number[] = [];
            services.forEach(service => {
                const gridId = service.capacityGridId ?? 0;
                if (gridId > 0 && !capacityGridIds.includes(gridId)) {
                    capacityGridIds.push(gridId);
                }
            });
            let newList: CapacityGridType[] = [];
            grids.forEach(grid => {
                if (capacityGridIds.includes(grid.id)) {
                    newList.push(grid);
                }
            });
            return newList;
        }
        return [];
    }

    const loadCapacityGrids = async () => {
        handleLoading();
        // load all capacity grids this user has access to, this gives us the required structure/columns the grid will need to present/submit
        const response = await fetchCapacityGridSubmissionList();
        handleLoaded();
        if (response && response.success && response.result) {
            const grids = filterCapacityGrids(response.result);
            setCapacityGrids(grids);
            if (services) {
                loadData();
            }

            return
        }
    }

    const loadServices = async () => {
        handleLoading();
        const response = await filterByCapacityGridOptions(filterOptions);
        handleLoaded();
        if (response && response.success && response.result) {
            setServices(response.result.slice(0,500));
            return
        }
        handleError(response.error ?? 'Unkown error');
    }

    const loadData = async () => {
        handleLoading();
        const serviceIds = services?.map(s => {
            return s.id;
        });
        if (serviceIds) {
            const response = await fetchDailyReport(serviceIds);
            handleLoaded();
            // now we have grids for each division now get the last submitted data
            if (response && response.success && response.result) {
                setSubmissionData(response.result);
                return
            }
            handleError(response.error ?? 'Unkown error');
        }

    }
 

    const handleFilterSelectionsChanged = (selections: BasicReportCriteria) => {
        
        let serviceIds = filterOptions?.serviceId ?? [];
        let serviceCategoryIds: number[] = [];
        if (selections && selections.selectedServiceIds && (selections.selectedServiceIds?.length ?? 0) > 0) {
            serviceIds = selections.selectedServiceIds.map(svc => {
                return svc.id;
            });
        }
        if (selections && selections.selectedServiceCategoryIds && (selections.selectedServiceCategoryIds?.length ?? 0) > 0) {
            serviceCategoryIds = selections.selectedServiceCategoryIds.map(svc => {
                return parseInt(svc.toString());
            });
        }
        setFilterOptions({
            areaId: filterOptions?.areaId,
            serviceId: serviceIds,
            bedTypeId: (selections && selections.selectedBedTypeId && selections?.selectedBedTypeId) ? [selections.selectedBedTypeId] : undefined,
            capacityGridId: selections.selectedGridId ? [selections.selectedGridId] : undefined,
            categoryId: serviceCategoryIds
        });
    }

    const handleExport = () => {

        const title = 'Daily Capacity Grid Report';
        const reportTables = document.querySelectorAll("table");
        if ((reportTables?.length ?? 0) > 0) {
            let sheets: ExcelExportSheet[] = [];
            reportTables.forEach((table, index) => {
                sheets.push({
                    title: table.dataset.capGridName ?? `Capacity grid ${index}`,
                    name: table.dataset.capGridName ?? `Capacity grid ${index}`,
                    elements: [
                        { table: table },
                    ]
                });
            });
            exportToExcel({
                fileName: title,
                defaultHeaderWidth: 20,
                sheets: sheets,
                verticalHeaders:true
            });
        }
    }

    const handleLoading = () => {
        if (props.onLoading) {
            props.onLoading();
        }
    }
    const handleLoaded = () => {
        if (props.onLoaded) {
            props.onLoaded();
        }
    }
    const handleError = (message: string) => {
        if (props.onError) {
            props.onError(message);
        }
    }
    const handleRowClick = (serviceId: number) => {
        if (serviceId > 0 && serviceId !== selectedParentId) {
            setSelectedParentId(serviceId);
        }
    }
    const handleSubReportClose = () => {
        setSelectedParentId(undefined);
    }

    if ((selectedParentId ?? 0) > 0) {
        return <>
            <CapacityGridHistoricReport areaHierarchy={areaHierarchy} parentServiceId={selectedParentId} onError={handleError} onLoading={handleLoading} onLoaded={handleLoaded} onBackButton={handleSubReportClose} />
        </>
    }

    return <>
        <Grid container>
            <Grid item xs={12}>
                <BasicReportFilter areaHierarchy={areaHierarchy} onApplyFilter={handleFilterSelectionsChanged} hideServiceControl={hideServiceSelector} validateServiceSelections={false} criteria={{ showOnlyServicesWithGridAssociated: false, showServiceCategory: true, showBedAvailabilityList: true, showBedTypeList: true }} defaultFilterCriteria={undefined} onExport={handleExport} />
            </Grid>
        </Grid>
        <p>&nbsp;</p>
        {(filterOptions && Object.keys(filterOptions).length > 0 && capacityGrids && services && submissionData) &&
            <CapacityGridDailyReportTable CapacityGrids={capacityGrids} Services={services} Data={submissionData} onRowClick={handleRowClick}></CapacityGridDailyReportTable>
        }

    </>
}