import Page from "../../../components/core/page";
import {useState, useEffect} from "react";
import useForm from "../../../hooks/useForm";
import { FeedbackType } from "../../../components/core/feedback";
import { AreaHierarchyType, createNewAreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { fetchAllAreaHierarchyWithServices } from "../../../services/systemService";
import { OpelFormSubmissionIdsSubmissionType, OpelFormSubmissionType } from "../../../interfaces/reporting/form-submission";
import { createNewServiceLiteFromParams, ServiceCategoryEnum, ServiceTypeLite } from "../../../interfaces/system/serviceType";
import OpelSubmissionReport, { OpelSubmissionCriteriaProps } from "../../../components/reports/opel/opel-submission";
import { fetchOpelFormSubmission, fetchFormSubmissionForServiceByDateRangeLite } from "../../../services/reporting/form-service";


interface OpelRportContainerProps {
    mode:'submission'
}
export default function OpelRportContainer(props:OpelRportContainerProps) {

    const [title, setTitle] = useState('Opel Report')
    const [opelSubmissionsData, setOpelSubmissionsData] = useState<OpelFormSubmissionIdsSubmissionType[]>()
    const [opelSubmissionReportData, setOpelSubmissionReportData] = useState<OpelFormSubmissionType>()
    const [serviceList, setServiceList] = useState<ServiceTypeLite[]>()
    const [areaHierarchy,setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType())
    const {
        feedbackStatus, setFeedbackStatus, errorMessage,setErrorMessage
      }
       = useForm({initialObjectValues: {}})

       useEffect(() => {
        loadAreaHierarchy()
        presentTitle()
        }, [])
       
 
        const presentTitle =()=>{
          switch(props.mode){
            case "submission": setTitle('Opel Report'); break;
          }
        }

      const loadAreaHierarchy = async ()=>{
        const a= await fetchAllAreaHierarchyWithServices()
        if(a.success && a.result)
            setAreaHierarchy(a.result)
        else{
            setErrorMessage(a.error as string)
        }
        
      }
     
      const presentSubmission = async (criteria?:OpelSubmissionCriteriaProps) =>{
        
        if(!criteria || !criteria.submissionId)
          return

        setFeedbackStatus(FeedbackType.Loading)  
        
        const response = await fetchOpelFormSubmission(criteria.selectedServiceId?? criteria.serviceIds[0],criteria.submissionId)
         setFeedbackStatus(FeedbackType.hide)
         
        if(response && response.success){
          
            setOpelSubmissionReportData(response.result)
          return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
      }
      const applySubmissionFilter = async (criteria?:OpelSubmissionCriteriaProps) =>{
        
        
        if(!criteria)
          return

          setFeedbackStatus(FeedbackType.Loading)  
        setOpelSubmissionReportData(undefined)

        const response = await fetchFormSubmissionForServiceByDateRangeLite(criteria.serviceIds, criteria.fromDate, criteria.toDate)
         setFeedbackStatus(FeedbackType.hide)
        if(response && response.success){

            if(!response.result?.services || response.result.services.length<1){
              setServiceList(undefined)
              setOpelSubmissionsData(undefined)
              return
            }

            const s = response.result?.services.map(m=> createNewServiceLiteFromParams(m.service.id, m.service.name))
            setServiceList(s)
            
            const submissions = criteria.selectedServiceId ? response.result?.services.find(f=>f.service.id === criteria.selectedServiceId)?.submissions:
            response.result?.services[0]?.submissions

            setOpelSubmissionsData(JSON.parse(JSON.stringify(submissions)))
            
            if(s && s.length>0){

              const submissionId = criteria.submissionId ?? criteria.selectedServiceId ? 
              response.result?.services.find(f=>f.service.id === criteria.selectedServiceId)?.submissions[0]?.id??0
                : response.result?.services[0].submissions[0]?.id??0

                
              await presentSubmission({submissionId:submissionId,serviceIds:s.map(m=>m.id),
              fromDate:new Date(), toDate:new Date(),selectedServiceId:criteria.selectedServiceId})
            }
          return
        }
        setErrorMessage(response.error)
        setFeedbackStatus(FeedbackType.Error)
      }
      const handleOpelSubmissionSelected = async (criteria?:OpelSubmissionCriteriaProps) =>{
        await presentSubmission(criteria)
      }
      const handleServiceSelected = async (criteria?:OpelSubmissionCriteriaProps) =>{
        await applySubmissionFilter(criteria)
      }
      
 

    return <Page title={title} feedbackStatus={feedbackStatus} errorMessage={errorMessage}>
         
        {props.mode  === "submission" && <OpelSubmissionReport areaHierarchy={areaHierarchy} data={opelSubmissionReportData} 
            criteria={{showOnlyServicesWithFormAssociated:true, showFromDate:true, showToDate:true}}
            onFetchData={presentSubmission} onApplyFilter={applySubmissionFilter} onServiceSelected={handleServiceSelected}
            defaultFilterCriteria={{selectedServiceIds:[],fromDate:new Date(new Date().setHours(0,0,0,0)),
            toDate:new Date(new Date().setHours(23,59,59,0)),
            selectedServiceCategoryIds:[ServiceCategoryEnum.Opel]}}
            serviceList={serviceList}
            submissions={opelSubmissionsData}
            onSubmissionSelected={handleOpelSubmissionSelected}
            />
        }
    </Page>
}