import { useEffect, useState } from "react";
import { ActionCardEventType, ActionCardEventTypes, ActionCardHomepageOverviewType } from "../../interfaces/system/actionCardType"
import React from "react";
import { ServiceStatusType } from "../../interfaces/system/serviceType";
import { formatDateTime } from "../../extensions/formatter";
import { FormSubmissionFieldType } from "../../interfaces/system/formType";

export interface ServiceActionCardListProps {
    serviceActionCard?: ActionCardHomepageOverviewType
    service?: ServiceStatusType
}
export function ServiceActionCardList(props: ServiceActionCardListProps) {
    const [serviceActionCard, setServiceActionCard] = useState(props.serviceActionCard);
    const [service, setService] = useState(props.service);

    useEffect(() => {
        setServiceActionCard(props.serviceActionCard)
    }, [props.serviceActionCard])

    useEffect(() => {
        setService(props.service)
    }, [props.service])

    if (!serviceActionCard || !service) {
        return <><p>No action card</p></>
    }


    const getEscalationEvent = (eventList?: ActionCardEventType[]): ActionCardEventType | undefined => {
        if (!eventList) return undefined;
        const matchedEvent = eventList.find(e => e.resultTypeId === ActionCardEventTypes.Escalation && e.reportingLevelOptionId === service.formResultOptionId);
        return matchedEvent;
    }

    const getFormEvents = (eventList?: ActionCardEventType[]): ActionCardEventType[] | undefined => {
        if (!eventList) return undefined;
        const matchedEvent = eventList.filter(e => e.resultTypeId === ActionCardEventTypes.FormField);
        return matchedEvent;
    }

    const getFormField = (event: ActionCardEventType): FormSubmissionFieldType | undefined => {
        const matchedField = serviceActionCard.submissionData.fields.find(e => e.fieldId === event.formFieldId);
        console.clear(); console.log(matchedField, "Matched field");
        return matchedField;
    }

    return <>
        <h2>Action card for submission date: {formatDateTime(serviceActionCard.submissionData.createdOn)}</h2>
        <hr />
        {React.Children.toArray(serviceActionCard.actionCard.recipients?.sort((a, b) => a.sortOrder - b.sortOrder).map(recipient => {
            const escalationEvent = getEscalationEvent(recipient.events);
            const formFieldEvents = getFormEvents(recipient.events);

            return <>
                {recipient.events && (recipient.events?.length ?? 0) > 0 &&
                    <>
                        <h3>{recipient.name}</h3>

                        {escalationEvent &&
                            <>
                                <p className="subtleHeader">Escalation level actions:</p>
                                <table className="condensed bordered" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '300px' }}>Level</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className={`form-result-${service.formResultOptionId}`} style={{ verticalAlign: 'top' }}>
                                                <p style={{ fontWeight: 'bold' }}>Escalation Level {service.formResultOptionId}:</p>
                                            </td>
                                            <td>
                                                <div className="action-card-action-text" dangerouslySetInnerHTML={{ __html: escalationEvent?.actionText ?? '' }}></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        }

                        {formFieldEvents && (formFieldEvents?.length ?? 0) > 0 &&
                            <>
                                <p className="subtleHeader">Reporting Trigger Actions:</p>
                                <table className={"condensed bordered table-striped"}>
                                    <thead>
                                        <tr>
                                            <th>Trigger</th>
                                            <th>Response</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {React.Children.toArray(formFieldEvents.map(formFieldEvent => {
                                            const field = getFormField(formFieldEvent);
                                            if (!field || !field.formField || !field.formFieldOption) {
                                                return <></>
                                            }
                                            return <tr>
                                                <td>{field.formField.name}</td>
                                                <td>{field.formFieldOption.name}</td>
                                                <td dangerouslySetInnerHTML={{ __html: formFieldEvent.actionText ?? '' }}></td>
                                            </tr>
                                        }))}
                                    </tbody>
                                </table>
                            </>
                        }
                        <hr style={{ marginTop: '2rem' }} />
                    </>
                }
            </>
        }))}
    </>
}