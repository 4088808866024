import React, {useState,useRef } from 'react'
import './App.scss';
import './assets/themes/prime/prime.css';
import './assets/themes/prime/primereact.css';
import Routes from './components/app/routes';
import AppMenu from './components/app/appMenu';
import {BrowserRouter as Router} from "react-router-dom";
import {fetchOptions} from './services/domainEntitiesService'
import {AppToastContext} from './extensions/context/appHook'
import {createNewOptionsType, OptionsType} from './interfaces/domain/iDomainEntity'
import FeedBackText from './components/core/feedbackText';
import 'primeicons/primeicons.css';
import { Snackbar } from '@mui/material';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { getJwt, isUserAuthenticated } from './services/storageService';
import { UserType } from './interfaces/user/iUser';
import { Messages } from 'primereact/messages';
import { handleNoticationMessageReceived } from './services/notificationService';
import { getUserRedirect, saveUserRedirect } from './services/userService';

const App:  React.FC = () => {
  const [options, setOptions] = useState<OptionsType>(createNewOptionsType());
  const [notifications, setNotifications] = React.useState<string[]>()
  const [showNotificationSnack, setShowNotificationSnack] = React.useState(false)
  const [notificationMessage, setNotificationMessage] = React.useState('')
  const [messageSeverity, setMessageSeverity] = React.useState<'success' | 'info' | 'warn' | 'error'>('success')
  // const [loadedSessionData, setLoadedSessionData] = React.useState(false)
  const [isAuthenticated, setIsAuthenticated] = React.useState(isUserAuthenticated())
  // let intervalTimeStamp = new Date()
  // const toast = React.useRef<any>(null);
  const msgs = useRef<Messages>(null);

  React.useEffect(() => {
    if(isAuthenticated){
    signalRInit()
    getLocalData()
    }
   },[isAuthenticated])

  const handleSuccessfulLogin = (user:UserType) =>{
    setIsAuthenticated(true)
    if (msgs.current && user && user?.systemMessages) {
      msgs.current.clear();
        const a: any[] = user?.systemMessages?.map((m) => { return { sticky: true, severity: 'info', summary: 'System Message: ', content: <><span dangerouslySetInnerHTML={{ __html: m.messageContent }} /></>, closable: true }})
      msgs.current.show(a);
      
      }
  }
  const getLocalData = () =>{
    fetchOptions().then(d=>{setOptions(d)})
    }

  React.useEffect(() => {
    
    if(!isAuthenticated) {
      const redirect = getUserRedirect()
      if(!redirect)
        saveUserRedirect()
      return
    }
    else{
      if(!options || options?.serviceCategories?.length<1)
        getLocalData()
    }
    signalRInit()
      
  },[]);

  
 
  
  const showToast = (message:string, severity:'success' | 'info' | 'warn' | 'error') =>{
    setMessageSeverity(severity)
    setNotificationMessage(message)
    setShowNotificationSnack(true)
    }

    const signalRInit = () => {
        let signalRUrl = process.env.REACT_APP_SIGNALR_ENDPOINT
        
        if (signalRUrl) {
            let hubConnectionBuilder = new HubConnectionBuilder().withUrl(signalRUrl, { accessTokenFactory: () => getJwt() }).configureLogging(LogLevel.Information).build();
            hubConnectionBuilder.start().then(() => console.log('Connection started.......!')).catch(err => console.log('Error while connecting to server'));
            hubConnectionBuilder.on('MessagesReceived', (result: any) => {
                if (result) {
                  const m = handleNoticationMessageReceived(result)
                  if(!m) return

                  const n = notifications ?? []
                  n.push(result.messageText)
                    showToast(result.messageText, 'info')
                    setNotifications([...n])
                }
                
            });
        } else {
            console.error("SignarlR URL not vallid");
        }
    }
    
  
  return (
    <React.Fragment> 
      
      <Router>
      <AppMenu notifications={notifications} authenticated={isAuthenticated}/>
      
      <Messages ref={msgs} />

      

      {/* <div className="placeHolder"> */}
  
        {/* <DomainEntitiesContext.Provider value={options}> */}
        <AppToastContext.Provider value={{successMessage: (a:string)=>showToast(a,'success'), errorMessage:(a?:string)=>showToast(a||'General Error','error')}}>
            <Routes onSuccessfulLogin={handleSuccessfulLogin}/>
            
        </AppToastContext.Provider>
        {/* </DomainEntitiesContext.Provider> */}
      {/* </div> */}
    </Router>
    
        <Snackbar style={{top:65,width:'50%'}}
            open={showNotificationSnack}
            onClose={()=>setShowNotificationSnack(false)}
            autoHideDuration={10000} 
            anchorOrigin={{vertical:'top', horizontal:'center'}}
          >
            <div>
              <FeedBackText severity={messageSeverity} show={true}  message={notificationMessage} />
            </div>
            </Snackbar>
      {/* <br></br>
      <footer>
      <div style={{marginTop:-50}}>
        <MlcsuLogo />
        </div>
        </footer> */}
    </React.Fragment>
  );
}

export default App;