import React, {useEffect,useState} from "react"
import {Grid} from '@mui/material';
import Loader from "../../core/loading";
import {FormFieldType,FormFieldTypeEnum, getIconNameFromFieldType } from "../../../interfaces/system/formType";
import ActionButton from "../../core/actionButton";

interface FormEditFieldProps {
    field:FormFieldType
    onDelete: (field:FormFieldType)=> void
    onSelect: (field:FormFieldType)=> void
    onDuplicate: (field:FormFieldType)=> void
  }

const FormEditFieldListPreview:  React.FC<FormEditFieldProps> = (props) => {
  const [field,setField] = useState(props.field)

useEffect(() => {
  setField(props.field)
},[props.field])


const FieldHeaderElem = () => {
  
  let icon = getIconNameFromFieldType(field.fieldType.id)

  return <><div><i className={icon}></i>&nbsp;&nbsp;{field.fieldNumber} - {field.name}</div></>
}
const FieldFooterElem = () => {
  return <div>
    <ActionButton label="edit" severity="success" variant="icon-only" icon="pi pi-pencil" onClick={props.onSelect(field)}/>
    <ActionButton label="delete" severity="danger" variant="icon-only" icon="pi pi-times" onClick={props.onDelete(field)}/>
    <ActionButton label="duplicate" severity="info" variant="icon-only" icon="pi pi-copy" onClick={props.onDuplicate(field)}/>
  </div>
}
const FieldMultiChoiceElem = () => {
  return <div>
            options will gohere
          </div>
}
const FieldElem = ():JSX.Element => {
  switch(field.fieldType.id){
    case FormFieldTypeEnum.MultipleChoice:
      return FieldMultiChoiceElem();
    default:
        return <>not supported</>
  }
}
 
 
if(!field)
        return <Loader loading={true} />

  return <div >
  <FieldHeaderElem />
   <FieldElem  />
   <FieldFooterElem />
  </div>

}

export default FormEditFieldListPreview;




