import React from "react";
import {Grid} from '@mui/material'
import GaugeChart from 'react-gauge-chart'
import LabelText from "../core/labelText";
import { FormResultOptionType, FormResultType, FormResultTypeEnum } from "../../interfaces/system/formType";
import { ServiceStatusType } from "../../interfaces/system/serviceType";
import { getCachedOptions, getFormResultOptionRangeFromId } from "../../services/domainEntitiesService";
import { formatDateTime } from "../../extensions/formatter";
import { getFormIdForService } from "../../services/form-service";
import './css/formSubmissionSummary.scss';

interface FormSubmissionSummaryProps {
    formResultOption?:FormResultOptionType
    formResultOptionId?:number
    formId?:number
    formResult?:FormResultType
    serviceStatus?:ServiceStatusType
    showFooter?:boolean
    showLastUpdated?:boolean
    gaugeWidth?: string
    displayType?:'stacked'|'default'
    padding?:number
    score?:number
    opelScore?:number
    supressGauge?:boolean
    onFormResultOptionUpdated?: (f?: FormResultOptionType) => void
    className?: string
  }

  
const FormSubmissionSummary:  React.FC<FormSubmissionSummaryProps> = (props) => {
    const [formResultOption,setFormResultOption]  = React.useState<FormResultOptionType>()
    const [serviceOverview,setServiceOverview]  = React.useState(props.serviceStatus)
    const [percent,setPercent]  = React.useState(0)
    const [ranges,setRanges]  = React.useState([0.25,0.25,0.25,0.25])
    const [isOpel,setIsOpel]  = React.useState(props.opelScore!== undefined)
    const options = getCachedOptions()
    const [score,setScore]  = React.useState(0)

    React.useEffect(() => {
        presentFormResult(props.score??props.opelScore??0,props.formResult?.options)
        setIsOpel(props.opelScore!== undefined)

      }
      ,[props.score,props.opelScore])

      React.useEffect(() => {
        if(props.serviceStatus)
            presentServiceStatus()
     }
     ,[props.serviceStatus])

     const presentServiceStatus = async ()=> {
        
        if(!props.serviceStatus) return
        const resultOptionId =  !props.opelScore ?  props.serviceStatus?.formResultOptionId : props.serviceStatus?.opelFormResultOptionId
        
        
        if(!resultOptionId) return

        setServiceOverview(props.serviceStatus)
        const formId = await  getFormIdForService(props.serviceStatus.id)
        
        let opts = await getFormResultOptionRangeFromId(options.formResultOptions,resultOptionId, props.opelScore? FormResultTypeEnum.Opel :FormResultTypeEnum.Standard, formId )
        if(opts && opts.length>4 && props.serviceStatus && props.serviceStatus.formResultId)
            opts = opts.filter(f=> f.formResultId === props.serviceStatus?.formResultId)
            
        const score = !props.opelScore?  props.serviceStatus?.score : props.serviceStatus?.opelScore
        
        
        presentFormResult(score||0,opts,formId)
     }

     const presentFormResult = async (score:number, options?:FormResultOptionType[],formId?:number) => {
        if(!options) return

        score=score??0

        setScore(score)
        
        if(props.serviceStatus?.formResultOptionId && !isOpel)    {
            let o = options.find(f=> f.formResultOptionId=== (props.serviceStatus?.formResultOptionId??0))
            setFormResultOption(o)

            // const maxScore = Math.max(...options.map(o => o.weightingTo))

            
            let weightingIndex = options.findIndex(f=>f.weightingFrom<=score && score <= f.weightingTo)

            console.log(score,options)
            if(weightingIndex<0){
                setPercent(0)
                return
            }
            
             const range = options[weightingIndex].weightingTo - options[weightingIndex].weightingFrom
             let  p = (weightingIndex*.25) + (0.25 * (score-options[weightingIndex].weightingFrom) /range)
             
            //  let  p = score/maxScore
            if(p>1) p =1
            else if(p<0) p =0
            setPercent(p)
            
            return
        }
        
        
        if(!options) return
        let fId = formId?? props.formId
        
        
        if(!isOpel && !fId && props.serviceStatus)
            fId = await  getFormIdForService(props?.serviceStatus?.id)
        

        const maxScore = Math.max(...options.map(o => o.weightingTo))
        
        let ro = options.find(f=> f.formId=== (isOpel?null:fId)  && f.weightingFrom<=score && score<= f.weightingTo  && f.formResultTypeId === (isOpel? FormResultTypeEnum.Opel :FormResultTypeEnum.Standard))

        if(score>maxScore)
            ro=options.find(f=> f.formId === (isOpel?null:fId) && f.weightingTo == maxScore  && f.formResultTypeId === (isOpel? FormResultTypeEnum.Opel :FormResultTypeEnum.Standard))
        
        
         if(ro){
            let r = options.filter(f=> f.formId === (isOpel?null:fId)  && f.formResultTypeId === (isOpel? FormResultTypeEnum.Opel :FormResultTypeEnum.Standard)).map((m)=> (parseFloat(((m.weightingTo-m.weightingFrom)/maxScore).toPrecision(2))))
            setRanges(r)
            if(score>maxScore){
                score=maxScore
                
            }
            let  p = score/maxScore
            if(p>1) p =1
            else if(p<0) p =0
            setPercent(p)
         }
         
         setFormResultOption(ro)
         
         if(props.onFormResultOptionUpdated)
            props.onFormResultOptionUpdated(ro)
     }
      

     if(!formResultOption)
        return <></>

    const width = props.gaugeWidth ? props.gaugeWidth : '150px'
    const showLabel = (props.opelScore !== undefined || props.score !== undefined) && ((props.opelScore ?? -1) > 0 || (props.score ?? -1) > 0);
    
    return <div className={`form-submission-summary-guage ${props.displayType ?? ''} ${props.className ?? ''} ${showLabel ? 'with-label':''}`}>
        {!props.supressGauge && <div>
            {showLabel && props.displayType === 'stacked' &&
                <div style={{ display: 'none' }} className="guage-label-text">{score}</div>
            }
            <div className="guage" style={{width:width}}>
                                                <GaugeChart id="gauge-chart1" 
                            arcsLength={ranges}
                            arcWidth={0.3}
                            arcPadding={0} 
                            textColor={'#e61c1c'}
                            colors={['#4eb329', '#f08e1d', '#cf5d5d','#000']}
                            cornerRadius={0.1} 
                            fontSize={'1rem'}
                            formatTextValue={(value) => ''}
                            
                            percent={percent}  />
            </div>
            {showLabel && props.displayType !== 'stacked' &&
                <div style={{display:'none'}} className="guage-label-text">{score}</div>
            }
            <div className={`guage-status-panel ${formResultOption?.style?.className}`} style={{
                    padding:props.padding,fontWeight: formResultOption?.style?.fontWeight|| 700,
                                                color:formResultOption?.style?.fontColour, fontSize:formResultOption?.style?.fontSize || '',
                                                font:formResultOption?.style?.fontName,marginBottom:props.padding,
                                                background:formResultOption?.style?.background}}
                                        >
                                        <span>{(formResultOption?.name)}</span>
                                        {showLabel && ((props.opelScore ?? -1) > 0) &&
                                            <small className="guage-score"> score: {score}</small>
                                        }
                                        
                                        
                                        
                                    </div>
                                    
                    </div>}            
                    {props.showLastUpdated &&
                                            <div style={{marginTop:-20,width:'100%,',fontWeight:'normal'}}>
                                            <span style={{fontSize:'0.7rem'}}>{formatDateTime(serviceOverview?.lastUpdatedOn)} - {serviceOverview?.lastUpdatedBy?.fullName}</span>

                                        </div>
                                        
                                        }
                    {props.showFooter && <Grid container spacing={2}>
                        {props.supressGauge &&  <><Grid item xs={6}>
                <LabelText label="Last Updated" text={formatDateTime(serviceOverview?.lastUpdatedOn)} className="form-summary-guage-label-text" />
                        </Grid>
                        <Grid item xs={6}>
                    <LabelText label="Last Updated By" text={serviceOverview?.lastUpdatedBy?.fullName} className="form-summary-guage-label-text" />
                    </Grid></>}

                        <Grid item xs={6}>
                            <LabelText label="On Call Manager" text={serviceOverview?.contactName} className="form-summary-guage-label-text" />
                        </Grid>
                        <Grid item xs={6}>
                <LabelText label="Contact Number" text={serviceOverview?.contactDetails} className="form-summary-guage-label-text" />
                        </Grid>
                        <Grid item xs={12}>
                            <LabelText label="Comments" text={serviceOverview?.comments} />
                        </Grid>
                        {/*<Grid item xs={12}>*/}
                        {/*    <hr></hr>*/}
                        {/*</Grid>*/}
                        
                    </Grid>      }
             
    </div>;
}


export default FormSubmissionSummary;

