import {FormValidation, FormValidationField} from '../extensions/formValidation';
import {ValidatorType} from './ValidatorType'

export default class DomainEntityFormValidator implements ValidatorType
{
    formValidator: FormValidation= new FormValidation([])
    id: FormValidationField= new FormValidationField('','')
    name: FormValidationField= new FormValidationField('','')

     initialiseValidationFields = (objectValues: any) => {
        this.formValidator = new FormValidation([])
        this.name = this.formValidator.createTextField(objectValues.name)
        this.id = this.formValidator.createPostiveNumericField(objectValues.id)
        this.id.minValue=1
     }
    
 validate = (validatorObjectValues: any): boolean => {
    
     this.initialiseValidationFields(validatorObjectValues)
     const isValid = this.formValidator.isValid()
    return isValid
 }
}

