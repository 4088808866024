import { Grid } from "@mui/material";
import { useState, useEffect} from "react";
import { EscalationTrendReportContainerType } from "../../../interfaces/reporting/form-submission";
import { AreaHierarchyType } from "../../../interfaces/system/areaTypes";
import { ExcelExportSheet, exportToExcel } from "../../../services/reporting/excel-export-service";
import { PagedTab, PagedTabs } from "../../core/pagedTabs";
import BasicReportFilter, { BasicReportCriteria, BasicReportFilterCriteria } from "../basic-report-filter";
import FormEscalationTrendSubmissionReport from "./form-escalation-trend-submission";

export interface FormEscalationTrendCriteriaProps {
  serviceIds:number[]
  serviceCategoryIds?:number[]
  fromDate?:Date
  toDate?:Date

}
interface FormEscalationTrendReportProps {
  areaHierarchy:AreaHierarchyType
  onFetchData: (criteria:FormEscalationTrendCriteriaProps)=> void
  criteria?:BasicReportFilterCriteria
  data?:EscalationTrendReportContainerType
  defaultFilterCriteria?:BasicReportCriteria
  }

  
export default function FormEscalationTrendReport(props:FormEscalationTrendReportProps) {
  const  [data, setData] = useState(props.data); 
    
  useEffect(() => {
    
    setData(props.data)
},[props.data])
  
  const handleApplyFilter = (criteria:BasicReportCriteria)=> {
    
    props.onFetchData({serviceIds: criteria.selectedServiceIds.map(m=>m.id), serviceCategoryIds:[], 
      fromDate:criteria.fromDate, toDate:criteria.toDate})
}


const handleExport = () =>{

    const title= 'Escalation Trend Report'
    
   const sheets:ExcelExportSheet[] = []

     data?.formSubmissionResultByMonthYearData?.services?.forEach(f=>{

        const serviceContainer = document.querySelector(`#service-${f.id}`)

        if(!serviceContainer) return
        sheets.push({name:f.name, title:title, elements:[{table:serviceContainer.querySelectorAll("table")[0], title:'Esclation Triggers by Date'},
        {table:serviceContainer.querySelectorAll("table")[1], title:'Escalation Trigger Summary'},
        {canvas:serviceContainer.querySelectorAll("canvas")[0], title:' '},
        {canvas:serviceContainer.querySelectorAll("canvas")[1], title:'  '}]})
    })


    exportToExcel({
    defaultHeaderWidth:20,
    sheets:sheets
    })
}

    const tabs: PagedTab[] = [];
      data?.formSubmissionResultByMonthYearData?.services.forEach(s => {
            tabs.push({
                title: s.name,
                children:<FormEscalationTrendSubmissionReport  id={s.id}
                data={data?.formSubmissionResultByMonthYearData?.submissions.filter(f=> f.serviceId===s.id)}
                summaryData={data?.formSubmissionResultByMonthYearData?.summaries.filter(f=> f.serviceId===s.id)}
                 />
            })
        });


        const noData = !data || !data.formSubmissionResultByMonthYearData?.services || data.formSubmissionResultByMonthYearData?.services.length<1
        return  <Grid container spacing={2}>
            <Grid item xs={10}>
                <BasicReportFilter areaHierarchy={props.areaHierarchy} onApplyFilter={handleApplyFilter} criteria={props.criteria}
                defaultFilterCriteria={props.defaultFilterCriteria}
                validateServiceSelections={true}
                onExport={handleExport}/>
            </Grid>
            {noData && <Grid item xs={12}>No data to display</Grid>}
              <Grid item xs={12} >
              {/* <Grid item xs={12} id={`service-${f.service.id}`} className={`service-container`}> */}
                <PagedTabs tabs={tabs}></PagedTabs>                
              </Grid>
        </Grid>
}



