import { createNewDomainEntity, DomainEntityType } from "../domain/iDomainEntity"
import { FormSubmissionType } from "../reporting/form-submission"
import { CapacityGridType } from "./capacityGridType"
import { FormSubmissionFieldType, FormType } from "./formType"

export enum AutomatedReportTypeEnum {
   SitRep = 10,
   CapacityGrid = 20,
   CelSitRep=30
}

export interface AutomatedReportType {
    id:number
    name:string
    body:string
    subject:string
    deleted?:boolean
    includeDataCapture?:boolean
    submissionPerService?:boolean
    reportType?: DomainEntityType
    reportTypeId?:number
    distributionListId?:number
    schedules?:AutomatedReportScheduleType[]
    services?:AutomatedReportServiceType[]
 }
 export interface AutomatedReportServiceType{
   id: number,
   serviceId: number,
}
 export interface AutomatedReportScheduleType {
   id:number
   nextOccurrsOn?:Date
   monday:boolean
   tuesday:boolean
   wednesday:boolean
   thursday:boolean
   friday:boolean
   saturday:boolean
   sunday:boolean
}

export interface AutomatedReportDataType {
   dataCaptureSubmission:AutomatedReportDataCaptureType
   automatedReport:AutomatedReportType
   serviceStatus:AutomatedReportDataSubmissionType[]
   formSubmissions:FormSubmissionType[]
   gridSubmission:AutomatedReportDataCapacityGridType
}
export interface AutomatedReportDataCaptureType {
   submissions:[AutomatedReportDataSubmissionType]
   forms:FormType[]
}
export interface AutomatedReportDataCapacityGridType {
   submissions:[AutomatedReportDataGridSubmissionType]
   grids:CapacityGridType[]
}
export interface AutomatedReportDataGridSubmissionType {
   region:DomainEntityType
   serviceCategory:DomainEntityType
   createdOn:Date
   comments:string
   columns:FormSubmissionFieldType[]
   capacityGridId:number
   formResultId: number
   formResultOptionId: number
   id:number
   resultLabel: string
   service: DomainEntityType
}
export interface AutomatedReportDataSubmissionType {
   region:DomainEntityType
   serviceCategory:DomainEntityType
   createdOn:Date
   comments:string
   fields:FormSubmissionFieldType[]
   formId:number
   formResultId: number
   formResultOptionId: number
   id:number
   resultLabel: string
   service: DomainEntityType
}

 export const createNewAutomatedReport = (): AutomatedReportType =>{
   
    return {id:0, name:'',body:'',reportType:createNewDomainEntity(),subject:''}
 }
 export const createNewAutomatedReportSchedule = (): AutomatedReportScheduleType =>{
   return {id:-Date.now(),monday:false,tuesday:false,wednesday:false,
   thursday:false, friday:false,saturday:false,sunday:false}
}