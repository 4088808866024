import { useState, useEffect} from "react";
import { formatDateTimeWithDay } from "../../../extensions/formatter";
import { FormSubmissionLevelByDateReportType } from "../../../interfaces/reporting/form-submission";
import Panel from "../../core/panel";

export interface FormEscalationLevelTableCriteriaProps {
  serviceIds:number[]
  fromDate?:Date
  toDate?:Date
}
interface FormEscalationLevelTableReportProps {
  data?:FormSubmissionLevelByDateReportType
  }

  
export default function FormEscalationLevelTableReport(props:FormEscalationLevelTableReportProps) {
  const  [data, setData] = useState(props.data); 
    
  useEffect(() => {
    setData(props.data)

},[props.data])


        const noData = !data || !data.submissions || data.submissions.length<1
        return <Panel scrollHeight="50%"> <div id={'ddd'} className='service-container'>
                  {noData && <span>No data to display</span>}
                   {!noData && <table className="reporting-table" style={{padding:2,position:'relative'}}>
                        <thead>
                            <tr>
                                <th style={{position:'sticky',top:0}} colSpan={2}>Submitted</th>
                                {data?.submissions?.map((f) => (
                                    <th style={{position:'sticky',top:0}} colSpan={2}>{formatDateTimeWithDay(f.createdOn)}</th>
                                ))}
                               
                            </tr>
                        </thead>
                        <tbody>
                        
                        {data?.form?.fields.map((ff) => (
                                    <tr>
                                        <th style={{whiteSpace:'nowrap',position:'sticky',left:0}}>{ff.name}</th>
                                        {data?.submissions?.map((s) => (
                                          <>
                                            {s.fields?.filter(f=>f.fieldId === ff.id).map((field) => (
                                              <>
                                                <td  className={`form-result-${field.formFieldOption.reportingLabel}`}  ></td>
                                                <td style={{borderBottom:'1px solid #ccc'}}>
                                                  
                                                {(!ff.denominatorLabel) && <td>{field.formFieldOption.name}</td>}
                                                {(ff.denominatorLabel ) && <td >
                                                  <table className="reporting-table" >
                                                  <tr ><td colSpan={2}>{field.formFieldOption.name}</td></tr>
                                                  <tr>
                                                      <th>{ff.numeratorLabel}</th>
                                                      <th>{ff.denominatorLabel}</th>
                                                  </tr>
                                                  <tr>
                                                      <td align="center" >{field.numerator}{!field.numerator && <>&nbsp;</>}</td>
                                                      <td align="center">{field.denominator}</td>
                                                  </tr>
                                                  </table></td>}
                                                </td>
                                                </>
                                            ))}
                                            </>
                                        ))}
                                    </tr>
                                ))}
                                  <tr>
                                    <th style={{borderTop:'1px dotted black'}}>Overall</th>
                                    {data?.submissions?.map((s) => (
                                          <>
                                            <td></td>
                                            <td className={`form-result-${s.resultLabel}`}>{s.resultLabel}</td>
                                          </>
                                        ))}
                                    
                                  </tr>
                                  <tr>
                                      <th>Submitted By</th>
                                              
                                        {data?.submissions?.map((s) => (
                                          <>
                                            <td></td>
                                            <td>{s.createdBy?.fullName}</td>
                                          </>
                                        ))}
                                  </tr>
                                  <tr>
                                      <th>On Call Manager	</th>
                                      {data?.submissions?.map((s) => (
                                          <>
                                            <td></td>
                                            <td>{s.contactName}</td>
                                          </>
                                        ))}
                                  </tr>
                                  <tr>
                                      <th>Contact Number	</th>
                                      {data?.submissions?.map((s) => (
                                          <>
                                            <td></td>
                                            <td>{s.contactNumber}</td>
                                          </>
                                        ))}
                                  </tr>
                                  <tr>
                                      <th>Comments</th>
                                      {data?.submissions?.map((s) => (
                                          <>
                                            <td></td>
                                            <td>{s.comments}</td>
                                          </>
                                        ))}
                                  </tr>
                                  
                        </tbody>
                    </table>
                  }
</div>
</Panel>
}




