import React, { useState, useEffect} from "react";
import useForm from "../../hooks/useForm"
import { FeedbackType } from '../../components/core/feedback'
import {useParams } from "react-router-dom"
import { createNewUser} from "../../interfaces/user/iUser"
import {AppToastContext} from '../../extensions/context/appHook'
import { fetchService, fetchServiceList,saveService, fetchAreaList, saveAreaHierarchy,fetchServiceCategoryList, fetchServiceCategory, saveServiceCategory, fetchAutomatedReportList, fetchAutomatedReport, saveAutomatedReport, fetchAllAreaHierarchyWithServices} from "../../services/systemService";
import { ServiceSearchResultType,  ServiceType,createNewService } from "../../interfaces/system/serviceType";
import ServiceList from "../../components/admin/services/serviceList";
import ServiceCategoryList from "../../components/admin/services/serviceCategoryList";
import ServiceEdit from "../../components/admin/services/serviceEdit";
import { AreaHierarchyType, AreaType, createNewAreaHierarchyType, createNewServiceCategoryType, ServiceCategoryType } from "../../interfaces/system/areaTypes";
import AreaEdit from "../../components/admin/services/areaEdit";
import ServiceCategoryEdit from "../../components/admin/services/serviceCategoryEdit";
import { clearDomainEntities } from "../../services/domainEntitiesService";
import { resetDomainEntities } from "../../services/storageService";
import AutomatedReportList from "../../components/admin/system/automated-reports/automated-report-list";
import { AutomatedReportType, createNewAutomatedReport } from "../../interfaces/system/systemTypes";
import AutomatedReportEdit from "../../components/admin/system/automated-reports/automated-report-edit";
import Page from "../../components/core/page";
import { AutomatedReportValidator } from "../../validators/automated-report-validator";

interface IProps {
  mode?:'list'|'edit'|'areas'|'categories'|'edit-category'|'automated-report-list'|'automated-report-edit'
}
 
const ServiceAdminContainer:  React.FC<IProps> = (props) => {
  const DEFAULT_TITLE = 'Service List'
  let { serviceId,serviceCategoryId,automatedReportId } = useParams();
  
    const {
        feedbackStatus, setFeedbackStatus, errorMessage, setErrorMessage,
      }
       = useForm({initialObjectValues: createNewUser()})
      
       const [areaHierarchy,setAreaHierarchy] = useState<AreaHierarchyType>(createNewAreaHierarchyType())
       const [selectedServiceId, setSelectedServiceId] = useState(serviceId)
      const [selectedServiceCategoryId, setSelectedServiceCategoryId] = useState(serviceCategoryId)
      const [mode, setMode] = useState(props.mode)
      const [serviceList, setServiceList] = useState<ServiceSearchResultType[]>()
      const [automatedReportsList, setAutomatedReportsList] = useState<AutomatedReportType[]>()
      const [serviceCategoryList, setServiceCategoryList] = useState<ServiceCategoryType[]>()
      const [areaList, setAreaList] = useState<AreaType[]>()
      const [title, setTitle] = useState(DEFAULT_TITLE)
      const [selectedService, setSelectedService] = useState<ServiceType>()
      const [selectedServiceCategory, setSelectedServiceCategory] = useState<ServiceCategoryType>()
      const [selectedAutomatedReportId, setSelectedAutomatedReportId] = useState(automatedReportId)
      const [selectedAutomatedReport, setSelectedAutomatedReport] = useState<AutomatedReportType>()
      const [automatedReportValidator,setAutomatedReportValidator] = useState(new AutomatedReportValidator())
 
  useEffect(() => {
    setMode(props.mode)
  }
  ,[props.mode])
  useEffect(() => {
    present()
  }
  ,[mode])

  useEffect(() => {
    if(!selectedServiceId || (selectedService && selectedService.id.toString() === selectedServiceId)) return
    presentEdit()
  }
  ,[selectedServiceId])
  useEffect(() => {
    if(!selectedServiceCategoryId || (selectedServiceCategory && selectedServiceCategory.id.toString() === selectedServiceCategoryId)) return
    presentEditServiceCategory()
  }
  ,[selectedServiceCategoryId])

  useEffect(() => {
    if(!selectedAutomatedReportId || (selectedAutomatedReport && selectedAutomatedReport.id.toString() ===selectedAutomatedReportId)) return
    presentEditAutomatedReport()
  }
  ,[selectedAutomatedReportId])

  const present = async () =>{

    if(mode==='edit' || mode=== "edit-category" || mode=== "automated-report-edit") {
      setFeedbackStatus(FeedbackType.hide)
      return
    }

    setFeedbackStatus(FeedbackType.Loading)
     presentMapper[mode||'list']()
  }
  const presentList = async () =>{
    const backGroundUpdate = mode!=='list'

    if(!backGroundUpdate)
      setTitle(DEFAULT_TITLE)

    if(serviceList && !backGroundUpdate) {
      setFeedbackStatus(FeedbackType.hide)
      return
    }

      const response = await fetchServiceList();
      setFeedbackStatus(FeedbackType.hide)
      if(!response || response.success){
        setServiceList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
  const loadAreaHierarchy = async ()=>{

    if(areaHierarchy && areaHierarchy.areas.length>0) return

    const a= await fetchAllAreaHierarchyWithServices()
    if(a.success && a.result)
        setAreaHierarchy(a.result)
    else{
        setErrorMessage(a.error as string)
    }
    
  }
  const presentCategoryList = async () =>{
    setTitle('Service Categories')

        const response = await fetchServiceCategoryList();
       setFeedbackStatus(FeedbackType.hide)
      if(!response || response.success){
        setServiceCategoryList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }

  const presentAutomatedReportList = async () =>{
    setTitle('Automated Reports')

        const response = await fetchAutomatedReportList();
       setFeedbackStatus(FeedbackType.hide)
        
      if(!response || response.success){
        setAutomatedReportsList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
  
  const presentAreaList = async () =>{
    setTitle('Areas')

        const response = await fetchAreaList();
       setFeedbackStatus(FeedbackType.hide)
      if(!response || response.success){
        setAreaList(response.result)
        return
      }
      setErrorMessage(response.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)
  }
  const presentEdit = async () =>{
    if(!selectedServiceId) {
      setFeedbackStatus(FeedbackType.hide)
      return
    }
    setFeedbackStatus(FeedbackType.Loading)
    setMode('edit')
    
    if(selectedServiceId==='new'){
            setTitle('Create New Service')
      setSelectedService(createNewService())
      setFeedbackStatus(FeedbackType.hide)
      return 
    }

    const response = await fetchService(parseInt(selectedServiceId));
    
    if(!response || response.success){
      setTitle('Edit Service - ' + response.result?.name)
      
      setSelectedService(response.result)
      setFeedbackStatus(FeedbackType.hide)
      return
    }
    
    toastHandler.errorMessage(response.error)
    // setMode('list')
}

const presentEditAutomatedReport= async () =>{
  if(!selectedAutomatedReportId) {
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  setFeedbackStatus(FeedbackType.Loading)
  setMode('automated-report-edit')
  
  if(selectedAutomatedReportId==='new'){  
    setTitle('Create New Automated Report')
    const sc = createNewAutomatedReport()
    setSelectedAutomatedReport(sc)
    setFeedbackStatus(FeedbackType.hide)
    return 
  }

  const response = await fetchAutomatedReport(selectedAutomatedReportId);
  setFeedbackStatus(FeedbackType.hide)
  if(response && response.success){
    setTitle('Edit Automated Report - ' + response.result?.name)
    
    setSelectedAutomatedReport(response.result)
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  
  toastHandler.errorMessage(response.error)
  setMode("automated-report-list")

}
const presentEditServiceCategory= async () =>{
  if(!selectedServiceCategoryId) {
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  setFeedbackStatus(FeedbackType.Loading)
  setMode('edit-category')
  if(selectedServiceCategoryId==='new'){
    
    setTitle('Create New Service Category')
    const sc = createNewServiceCategoryType()
    setSelectedServiceCategory(sc)
    setFeedbackStatus(FeedbackType.hide)
    return 
  }

  const response = await fetchServiceCategory(parseInt(selectedServiceCategoryId));
  setFeedbackStatus(FeedbackType.hide)
  if(response && response.success){
    setTitle('Edit Service Category - ' + response.result?.name)
    
    setSelectedServiceCategory(response.result)
    setFeedbackStatus(FeedbackType.hide)
    return
  }
  
  toastHandler.errorMessage(response.error)
  setMode('categories')
}
   
  const presentMapper: { [K: string]: Function } = {
    'list': presentList,
    'edit': presentEdit,
    'areas': presentAreaList,
    'categories': presentCategoryList,
    'edit-category': presentCategoryList,
    'automated-report-list':presentAutomatedReportList
 };
    const handleServiceSelection =async (id:number) =>{
      setSelectedServiceId(id.toString())
    }

    const handleAutomatedReportSelection =async (id:number) =>{
      setSelectedAutomatedReportId(id.toString())
    }


    const handleServiceCategorySelection =async (id:number) =>{
      setSelectedServiceCategoryId(id.toString())
    }
    const handleSaveService = async (service:ServiceType,valid:boolean) =>{
      setSelectedService(service)
      setErrorMessage('')
      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveService(service)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Save Service - " + service.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newService = {...(service as ServiceType)}
          newService.id=saveResponse.result?.id
          setSelectedService(newService)
          setServiceList(undefined)
          presentList()
          clearDomainEntities()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleSaveServiceCategory = async (serviceCat:ServiceCategoryType,valid:boolean) =>{
      setSelectedServiceCategory(serviceCat)
      setErrorMessage('')

      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveServiceCategory(serviceCat)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Service Category - " + serviceCat.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newServiceCat = {...(serviceCat as ServiceCategoryType)}
          serviceCat.id=saveResponse.result?.id
          setSelectedServiceCategory(newServiceCat)
          setServiceList(undefined)
          presentList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }

    const handleSaveAutomatedReport = async (ar:AutomatedReportType) =>{
      
      const v = automatedReportValidator
      
      let valid = v.validate(ar)
      const servicesSelected = (ar?.services && ar?.services?.length>0) ?? false
      
    
        if(valid && !servicesSelected) {v.tabIndex = 1} else v.tabIndex = 0

        let errorMessage = !servicesSelected && valid ?'At least one service must be selected': undefined

      setErrorMessage(errorMessage)
      valid = valid && servicesSelected
      
      setSelectedAutomatedReport(ar)
      if(!valid){
        if(!errorMessage)
          setFeedbackStatus(FeedbackType.MandatoryFields)
        else
          setFeedbackStatus(FeedbackType.Error)
        return
      }
      

      setFeedbackStatus(FeedbackType.Loading)
      
      const saveResponse = await saveAutomatedReport(ar)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Automated Report - " + ar.name)  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          const newServiceCat = {...(ar as AutomatedReportType)}
          ar.id=saveResponse.result?.id
          setSelectedAutomatedReport(newServiceCat)
          setAutomatedReportsList(undefined)
          presentList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    
    const handleSaveArea = async (areaHierarchy: AreaHierarchyType[], areas:AreaType[],valid:boolean) =>{
      setErrorMessage('')
      if(!valid){
        setFeedbackStatus(FeedbackType.MandatoryFields)
        return
      }
      
      setFeedbackStatus(FeedbackType.Loading)
      setAreaList(areas)
      
       const saveResponse = await saveAreaHierarchy(areaHierarchy)
       if(saveResponse.success){
         toastHandler.successMessage("Successfuly Saved Areas")  
         setFeedbackStatus(FeedbackType.hide)
         if(saveResponse.result){
          resetDomainEntities()
          // const newService = {...(service as ServiceType)}
          // newService.id=saveResponse.result?.id
          // setSelectedService(newService)
          // setServiceList(undefined)
          // presentList()
         }
         return
       }
      
      setErrorMessage(saveResponse.error||'Unspecified Error')
      setFeedbackStatus(FeedbackType.Error)

    }
    const handleNewService = () =>{
      setSelectedServiceId('new')
    }
    const handleNewAutomatedReport= () =>{
      setSelectedAutomatedReportId('new')
    }
    const handleNewServiceCategory = () =>{
      setSelectedServiceCategoryId('new')
    }
    const handleCancelEdit = () =>{
      setSelectedServiceId(undefined)
      setSelectedService(undefined)
      setMode('list')
    }
    const handleCancelEditCategory = () =>{
      setSelectedServiceCategoryId(undefined)
      setSelectedServiceCategory(undefined)
      setMode('categories')
    }
    const handleCancelEditAutomatedReport = () =>{
      setSelectedAutomatedReportId(undefined)
      setSelectedAutomatedReport(undefined)
      setMode('automated-report-list')
    }
    const toastHandler = React.useContext(AppToastContext)
    
    const ServiceListElem = () => {
      if(mode!=='list') return <></>
        return <><ServiceList list={serviceList} onNew={handleNewService}  onSelection={handleServiceSelection}/></>
    }
    const ServiceEditElem = () => {
      if(mode!=='edit') return <></>
        return <ServiceEdit service={selectedService} onSave={handleSaveService} onCancel={handleCancelEdit}/>
    }
    const AreaEditElem = () => {
      if(mode!=='areas') return <></>
        return <AreaEdit areas={areaList} onSave={handleSaveArea} onCancel={handleCancelEdit}/>
    }
    const ServiceCategoryListElem = () => {
      if(mode!=='categories') return <></>
        return <><ServiceCategoryList list={serviceCategoryList} onNew={handleNewServiceCategory}  onSelection={handleServiceCategorySelection}/></>
    }
    const ServiceCategoryEditElem = () => {
      if(mode!=='edit-category') return <></>
        return <ServiceCategoryEdit serviceCategory={selectedServiceCategory} onSave={handleSaveServiceCategory} onCancel={handleCancelEditCategory}/>
    }
    const AutomatedReportListElem = () => {
      if(mode!=='automated-report-list') return <></>
        return <><AutomatedReportList list={automatedReportsList} onNew={handleNewAutomatedReport}  onSelection={handleAutomatedReportSelection}/></>
    }
    const AutomatedReportEditElem = () => {
      if(mode!=='automated-report-edit') return <></>
        loadAreaHierarchy()
        return <AutomatedReportEdit automatedReport={selectedAutomatedReport} onSave={handleSaveAutomatedReport} 
        areaHierarchy={areaHierarchy} validator={automatedReportValidator}
        onCancel={handleCancelEditAutomatedReport}/>
    }
 
    return <><Page title={title} feedbackStatus={feedbackStatus} errorMessage={errorMessage}>
            <ServiceListElem />
            <ServiceEditElem />
            <AreaEditElem />
            <ServiceCategoryListElem />
            <ServiceCategoryEditElem />
            <AutomatedReportListElem />
            <AutomatedReportEditElem />
          </Page>
      </>
}

export default ServiceAdminContainer
