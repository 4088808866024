import React, { useEffect } from "react";
import Loader from '../core/loading';
import * as L from 'leaflet'
import { ServiceCategoryEnum, ServiceStatusType } from "../../interfaces/system/serviceType";
import { dateDiffFromNowInHours } from "../../services/utils";

const assetsUri = process.env.REACT_APP_ASSETS_URL
export interface MapMarkerType {
    service: ServiceStatusType
}
export interface PolygonLayerType {
    name: string
    geoCodes: any[][]
}

interface MapProps {

    markers?: MapMarkerType[]
    id: string
    polygonLayer?: PolygonLayerType[]
    onServiceSelected: (service: ServiceStatusType) => void
}

const Map: React.FC<MapProps> = (props) => {
    const [map, setMap] = React.useState<any>();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {

        present()

    }, [])

    useEffect(() => {
        if (map)
            present()

    }, [props.markers])

    const present = () => {
        setLoading(true)

        if (map)
            map.remove()

        let defaultLat = 53.2714063
        let defaultLng = -2.6473872
        if(props.markers && props.markers.length>0){
            defaultLat = props.markers[0].service.address.latitude
            defaultLng = props.markers[0].service.address.longitude
        }

        const polyLayer: PolygonLayerType = props?.polygonLayer ? props?.polygonLayer[0] : { name: '', geoCodes: [] }
        const centreLat = !polyLayer ? defaultLat : polyLayer?.geoCodes[Math.floor(polyLayer?.geoCodes.length / 2)][0] - 0.05
        const centreLng = !polyLayer ? defaultLng : polyLayer?.geoCodes[Math.floor(polyLayer?.geoCodes.length / 2)][1] - 0.425
        // const centreLat =defaultLat //!polyLayer ? defaultLat : polyLayer?.geoCodes[Math.floor(polyLayer?.geoCodes.length / 2)][0] - 0.05
        // const centreLng =defaultLng //!polyLayer ? defaultLng : polyLayer?.geoCodes[Math.floor(polyLayer?.geoCodes.length / 2)][1] - 0.425

        

        const zoomLevel = 7//polyLayer ? 9 : 7
        const urlTemplate = assetsUri + "/assets/maptiles/{z}/{x}/{y}.png";
        
        const m = L.map(props.id, { zoom: zoomLevel, center: [centreLat, centreLng], maxZoom: 12, minZoom: 6 });
        L.tileLayer(urlTemplate, {attribution: '...',}).addTo(m);
        setMap(m)

        //   polyLayers.forEach(p => {
        //   L.polygon(p.geoCodes, {color:'#609af8', fill:true,stroke:true,weight:1}).addTo(m).bindPopup('hello');
        // });
        

        const iconCache: any = {}
        let markers = props.markers?.filter(f => f.service?.category.id !== ServiceCategoryEnum.IntegratedCareSystem)
        markers?.slice(0, 250).forEach(f => {
            const lastUpdated = f?.service?.lastUpdatedOn

            let iconVariant = '0'
            if (lastUpdated) {
                const hoursSinceUpdate = dateDiffFromNowInHours(f?.service?.lastUpdatedOn)
                // const hoursSinceCapacityGridUpdate = dateDiffFromNowInHours(f?.service?.capacityGridOverview?.capacityGridSubmittedOn)



                if (hoursSinceUpdate < 4 && f?.service?.formResultShortName)
                    iconVariant = f?.service?.formResultShortName

                // if (!f?.service?.formId && f?.service.capacityGridId && hoursSinceCapacityGridUpdate <= 168) {
                //     iconVariant = "1";
                // }

            }
            const serviceCategoryId = f?.service?.category?.id
            const hasForm = f?.service?.formId??0 >0
            const hasDnCData = f?.service?.demandAndCapacityProviderCode
            const capGridHasAvail = parseInt(f?.service?.capacityGridOverview?.capacityGridKeyFieldValue ??'0')>0

            const iconKey = `${capGridHasAvail?'1':'0'}${hasForm?'0':'1'}${serviceCategoryId}_${iconVariant}${serviceCategoryId === 10 && hasDnCData ? 'p' : ''}`
            let icon = iconCache[iconKey]

            
            if (!icon) {

                let iconUrl = `${assetsUri}/assets/icons/markers/orgtypes/org_${iconKey.substring(2)}.png`
                
                 if ((f?.service?.capacityGridId ?? 0) > 0 && (f?.service?.formId ?? 0)<=0) {
                    iconUrl = `${assetsUri}/assets/icons/markers/map_carehome_marker_${capGridHasAvail?'1':'0'}.png`;
                 }

                icon = L.icon({
                    iconUrl: iconUrl,
                    iconSize: [32, 37],
                    iconAnchor: [32, 37],
                    popupAnchor: [-3, -33],
                })
                iconCache[iconKey] = icon
            }
            L.marker([f.service.address.latitude || 0, f.service.address.longitude || 0], { icon: icon }).addTo(m).on('click', () => props.onServiceSelected(f.service))
        })

        const polyLayers = props?.polygonLayer ? props?.polygonLayer : []
        markers = props.markers?.filter(f => f.service?.category.id === ServiceCategoryEnum.IntegratedCareSystem)
        markers?.forEach(marker => {
            const polyLayer = polyLayers.find(f => f.name === marker.service?.area.name)

            const lastUpdated = marker?.service?.lastUpdatedOn

            let colour = 'gray'

            if (lastUpdated) {
                const hoursSinceUpdate = dateDiffFromNowInHours(marker?.service?.lastUpdatedOn)
                if (hoursSinceUpdate < 4 && marker?.service?.formResultShortName) {
                    switch (marker.service?.formResultShortName) {
                        case '1': colour = '#4eb329'; break;
                        case '2': colour = '#e6d25f'; break;
                        case '3': colour = '#f08e1d'; break;
                        case '4': colour = '#e61c1c'; break;
                    }
                }
            }



            if (polyLayer) {
                L.polygon(polyLayer.geoCodes, { color: colour, fill: true, stroke: true, weight: 1 }).addTo(m).on('click', () => props.onServiceSelected(marker.service));
            }

        })

        setTimeout(() => {
            setLoading(false)
        }, (400));

    }

    return <div>
        <Loader loading={loading} useBackdrop={false}></Loader>
        <div style={{ height: 700, width: '100%' }} id={props.id}>

        </div>



    </div>;
}

export default Map;

