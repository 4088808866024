import React, {useEffect,useState} from "react"
import {ServiceType } from "../../../interfaces/system/serviceType"
import useForm from "../../../hooks/useForm";
import {Grid} from '@mui/material';
import TextBox from "../../core/textBox";
import Dropdown from "../../core/dropdown";
import BasicTab from "../../core/basicTab";
import AddressEditDetails from "../../address/addressDetails";
import { AddressType } from "../../../interfaces/system/iAddress";
import TickBox from "../../core/tickBox";

interface ServiceEditProps {
    service?:ServiceType
    parentService?:ServiceType
    onChange?: (service: ServiceType) => void
  }

const ServiceDivisionEdit:  React.FC<ServiceEditProps> = (props) => {
  const [validator,setValidator] = useState(props.service?.validator)
  const {
    objectValues, setObjectValues
  }
   = useForm({initialObjectValues: props.service, validator: validator})
 
   useEffect(() => {
    setObjectValues(props.service)
    setValidator(props.service?.validator)
   },[props.service]) 
 
   const updateForm = (e: any) => {
    setObjectValues({
       ...objectValues,
       [e.target.id]: e.target.value
    })
    
    objectValues[e.target.id] = e.target.value
    if(props.onChange)
      props.onChange(objectValues);
 }
   const updateAddressDetails = (addressDetails: AddressType) =>{
    setObjectValues({
      ...objectValues,
      address: addressDetails
   })

   if(props.onChange){
    objectValues.address = addressDetails
      props.onChange(objectValues);
   }
  }

if(!objectValues)
        return <p>No division selected</p>
  
        
        const serviceDetail =  <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
                <Grid item xs={2}><TextBox id="id"  label="Id" hidden={objectValues.id<0} value={objectValues.id} disabled={true}/></Grid>
                
                <Grid item xs={2}><TextBox id="code"  label="Code"  maxLength={6}  value={objectValues.code} onChange={(e) => {updateForm(e)}} /></Grid>
                <Grid item xs={2}><TextBox id="sortOrder" type="number" label="Display Order" value={objectValues.sortOrder} onChange={(e) => {updateForm(e)}} /></Grid>
                <Grid item xs={2}><TickBox id="deleted"  label="Deleted" checked={objectValues.deleted} onChange={(e:any) => {updateForm({target:{id:'deleted',value:e.target.checked}})}}/></Grid>
                <Grid container></Grid>
                <Grid item xs={8}><TextBox id="name" focus={true} label="Name" validationField={validator?.name} value={objectValues.name} onChange={(e) => {updateForm(e)}} /></Grid>
                <Grid container></Grid>
                <Grid item xs={4}><TextBox id="costPerWeek"  label="Cost Per Week" type="number" validationField={validator?.name} value={objectValues.costPerWeek} onChange={(e) => {updateForm(e)}} /></Grid>
                <Grid item xs={4} ><Dropdown id="fundingTypeId" label="Funding" optionName="funding-list" includeBlankOption={true}  value={objectValues.fundingTypeId}   onChange={(e) => {updateForm(e)}} /></Grid>
                <Grid container></Grid>
                
                <Grid item xs={4} ><Dropdown id="genderId" label="Gender" optionName="gender-list" includeBlankOption={true}  value={objectValues.genderId}   onChange={(e) => {updateForm(e)}} /></Grid>
                <Grid item xs={4} ><Dropdown id="trustId" label="Trust" optionName="trusts" includeBlankOption={true}  value={objectValues.trustId}   onChange={(e) => {updateForm(e)}} /></Grid>
                <Grid item xs={8} ><Dropdown id="bedTypeId" label="Bed Type" optionName="bed-type-list" includeBlankOption={true}  value={objectValues.bedTypeId}   onChange={(e) => {updateForm(e)}} /></Grid>
                  <Grid item xs={8} ><Dropdown id="formId" label="Form" optionName="forms" includeBlankOption={true}  value={objectValues.formId}   onChange={(e) => {updateForm(e)}} /></Grid>
                  <Grid item xs={8} ><Dropdown id="capacityGridId" label="Grid" optionName="capacity-grid" includeBlankOption={true}  value={objectValues.capacityGridId}   onChange={(e) => {updateForm(e)}} /></Grid>
    </Grid>;

    const addressDetail = <Grid container spacing={2} justifyContent = "center" style={{padding:'15px 5px'}}>
      <Grid item xs={8}>
            <AddressEditDetails formValidator={validator?.addressValidator} addressToCopy={props.parentService?.address}
             address={objectValues.address} onChange={updateAddressDetails}/>
            </Grid>
      </Grid>

  return <> 
          <Grid item xs={12}>
                  <BasicTab key='serv_' tabs={[{label:'Division Details', component: <>{serviceDetail}</> },
                            {label:'Contact Details', component: <>{addressDetail}</>}
                            ]}>
                </BasicTab>    
              </Grid>
  </>

}

export default ServiceDivisionEdit;