import { useEffect, useState } from "react";
import { UserEditorProps } from "./edit-user";
import { SubscriptionSelectionOption, UserSubscrptionSelector } from "../../core/userSubscriptionSelector";
import { fetchUserSubscriptionList, saveUserSubscriptions } from "../../../services/userService";
import ActionButton from "../../core/actionButton";
import TickBox from "../../core/tickBox";



export function EditUserSubscriptions(props: UserEditorProps) {
    const [userToEdit, setUserToEdit] = useState(props.User);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState<SubscriptionSelectionOption[]>();
    const [includeAutoGeneratedSubmissions, setIncludeAutoGeneratedSubmissions] = useState(props.User.includeAutoGeneratedSubmissions)
    useEffect(() => {
        setUserToEdit(props.User);
        loadUserSubscriptions();
        setIncludeAutoGeneratedSubmissions(props.User.includeAutoGeneratedSubmissions)
    }, [props.User]);

    const loadUserSubscriptions = async () => {
        if (userToEdit.id > 0) {
            handleLoading();
            const response = await fetchUserSubscriptionList(userToEdit.id, false);
            if (response && response.success && response.result) {

                setSelectedSubscriptions(response.result);
            } else {
                handleError(response.error ?? 'An unknown error occurred');
            }
            // handleLoaded();
        }
    }

    const handleLoading = () => {
        // if (props.OnLoading) {
        //     //props.OnLoading();
        // }
    }

    const handleLoaded = () => {
        // if (props.OnLoaded) {
        //     //props.OnLoaded();
        // }
    }

    const handleError = (errorMessage: string) => {
        if (props.OnError) {
            props.OnError(errorMessage);
        }
    }
    const handleUpdated = (items: SubscriptionSelectionOption[]) => {
        setSelectedSubscriptions(items);
    }

    const handleSave = async () => {
        handleLoading();
        const response = await saveUserSubscriptions(userToEdit.id, selectedSubscriptions ?? null,includeAutoGeneratedSubmissions??false);
        handleLoaded();
        if (response && response.success && response.result) {
            const user = props.User
            if (props.OnSaved) {
                props.OnSaved(user, "Selected subscriptions have been saved");
            }
            return;
        }
        handleError(response?.error ?? 'An unknown error was encountered');
    }

    if (!selectedSubscriptions) {
        return <></>
    }

    return <>
        <h3>Subscriptions</h3>
        <div>
            <TickBox id="includeAutoGeneratedSubmissions" label="Include Auto Generated Submissions" onChange={(e:any)=>{setIncludeAutoGeneratedSubmissions(e.target.checked)}} checked={includeAutoGeneratedSubmissions}/>
        </div>
        <UserSubscrptionSelector user={userToEdit} userSelections={selectedSubscriptions} OnError={handleError} OnLoaded={handleLoaded} OnLoading={handleLoading} OnSelectionsUpdated={handleUpdated}></UserSubscrptionSelector>
        <p>&nbsp;</p>
        <>
            <div style={{ float: 'right' }}>
                <ActionButton onClick={handleSave} label="Save" severity={'success'}></ActionButton>
            </div>
            <p>&nbsp;</p>
        </>
    </>
}