import { getUser } from "./storageService"

export interface NoticationType {
  messageTypeId:number
  messageText:string
  serviceIds?:number[]
}
export const handleNoticationMessageReceived =  (m:NoticationType)  => {

  let hasPermission = !m.serviceIds || m?.serviceIds.length<1
  if(!hasPermission){
    const u = getUser()
    hasPermission = u.administrator??false

    if(!hasPermission){
      m.serviceIds?.forEach(f=>{
        if(u.servicePermissions[f]){
          hasPermission = true;
          return;
        }
      })
    }
  }
    if(hasPermission) return m
    return undefined
  }
